import { Box } from '@mui/material'
import { DashboardProps } from '.'
import theme from '../../../theme'
import { FC, PropsWithChildren } from 'react'
import {
  About,
  BuildingTrust,
  Definitions,
  Intensity,
  Introduction,
  MetricsFirstPage,
  MetricsSecondPage,
  Norms,
  Profile,
  Services,
  Trust,
  TrustBase,
  TrustBaseInRelationship,
} from '../Report'
import { AboutProps } from '../Report/About'
import { TrustProps } from '../Report/Trust'
import { TrustBaseInRelationshipProps } from '../Report/TrustBaseInRelationship'
import DashboardView from '../Report/DashboardView'
import { FrictionProps } from '../Report/MetricsFirstPage'
import { RelationalIndexProps, HarmonyProps } from '../Report/MetricsSecondPage'

export type HiddenReportProps = DashboardProps &
  AboutProps &
  TrustProps &
  TrustBaseInRelationshipProps &
  FrictionProps &
  RelationalIndexProps &
  HarmonyProps

const HiddenReport = (props: HiddenReportProps) => {
  return (
    <Box display={'none'} width="100%" height="100%" id="print">
      <Page>
        <Introduction />
      </Page>
      <Page>
        <About {...props} />
      </Page>
      <Page>
        <Norms />
      </Page>
      <Page>
        <Profile />
      </Page>
      <Page>
        <DashboardView {...props} />
      </Page>
      <Page>
        <Intensity />
      </Page>
      <Page>
        <Trust {...props} />
      </Page>
      <Page>
        <TrustBaseInRelationship {...props} />
      </Page>
      <Page>
        <TrustBase />
      </Page>
      <Page>
        <BuildingTrust />
      </Page>
      <Page>
        <MetricsFirstPage {...props} />
      </Page>
      <Page>
        <MetricsSecondPage {...props} />
      </Page>
      <Page>
        <Definitions />
      </Page>
      <Page>
        <Services />
      </Page>
    </Box>
  )
}

const Page: FC<PropsWithChildren> = (props: PropsWithChildren) => {
  const pageStyles = {
    width: 1635,
    height: 1150,
    bgcolor: theme.palette.common.white,
  }

  return (
    <Box sx={pageStyles} className="page">
      {props.children}
    </Box>
  )
}

export default HiddenReport
