import { Typography } from '@mui/material'
import { HEADING_SPACING, LINEBREAK_SPACING } from '../../constants/spacingConstants'

const SurveyInformation: React.FC = () => {
  return (
    <>
      <Typography variant="h1" mb={HEADING_SPACING}>
        Relational Assessment
      </Typography>
      <Typography variant="body1" mb={LINEBREAK_SPACING}>
        This assessment will help your organisation better understand the relational qualities of
        this project or commercial arrangement. Your responses may differ according to different
        agreements, so it is critical to complete the assessment with this specific commercial
        agreement in mind.
      </Typography>
      <Typography variant="body1" mb={LINEBREAK_SPACING}>
        The assessment will take approximately{' '}
        <Typography component="span" fontWeight="bold">
          8 minutes
        </Typography>{' '}
        to complete. There are no right or wrong answers, so please complete all multiple-choice
        questions by selecting the most relevant responses from your perspective.
      </Typography>
      <Typography variant="body1">
        <b>
          Your responses are confidential and protected by our Privacy policy. Individual response
          data is encrypted, securely stored, and used for aggregative trend analysis.
        </b>
      </Typography>
    </>
  )
}

export default SurveyInformation
