import { Box, Grid, Typography } from '@mui/material'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

const Intensity = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>

      <Typography mt={'200px'} fontSize={42} lineHeight={1.2} fontWeight={900}>
        RELATIONAL
        <br />
        NORM INTENSITY LEVELS
      </Typography>
      <Typography mt={'30px'} fontSize={24}>
        There are four levels of relational norm intensities.
      </Typography>
      <RainbowBar />
      <Grid container spacing={5} mt={'0px'}>
        {levelConfig.map((x) => (
          <Grid item xs={3} key={x.heading}>
            <Level {...x} />
          </Grid>
        ))}
      </Grid>
      <Copyright pageNumber={6} />
    </Box>
  )
}

export type LevelProps = {
  heading: string
  description: string
}

const levelConfig: LevelProps[] = [
  {
    heading: 'Low (0-50%)',
    description: 'Inferior relational norm. Improve or risk significant relationship compromise.',
  },
  {
    heading: 'Average (51-75%)',
    description:
      'Generally normalised and tolerated. Seek to improve sooner rather than later or risk further degradation.',
  },
  {
    heading: 'High (76-90%)',
    description: 'Healthy with manageable negative effects. Look for opportunities to improve.',
  },
  {
    heading: 'Elite (91%+ )',
    description:
      'Relational norm at its best. Has positive effect on supporting other norms as well as overall relationship performance. Monitor and maintain.',
  },
]

const Level = ({ heading, description }: LevelProps) => {
  return (
    <>
      <Typography fontSize={25} fontWeight={900}>
        {heading}
      </Typography>
      <Typography mt={'10px'} fontSize={16}>
        {description}
      </Typography>
    </>
  )
}

const RainbowBar = () => {
  return (
    <Box
      mt={'60px'}
      sx={{
        borderRadius: '20px',
        width: '100%',
        height: '25px',
        background:
          'linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 16%, rgba(208, 222, 33, 1) 32%, rgba(79, 220, 74, 1) 48%, rgba(63, 218, 216, 1) 64%, rgba(47, 201, 226, 1) 80%, rgba(28, 127, 238, 1) 100%)',
      }}
    />
  )
}

export default Intensity
