import AuthenticatedRoute from '../../../routes/AuthenticatedRoute'
import FullscreenContainer from '../../common/containers/FullscreenContainer'
import { EditContract } from './EditContract'

const EditContractRoute: React.FunctionComponent = () => {
  return (
    <AuthenticatedRoute>
      <FullscreenContainer showHeader>
        <EditContract />
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default EditContractRoute
