import { Autocomplete, Box, InputLabel, TextField } from '@mui/material'

interface AutocompleteInputProps {
  fullWidth?: boolean
  options: string[]
  label?: string
  errorMessage?: string
  onChange?: (value: string | null) => void
  placeholder?: string
  value?: string
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  options,
  label,
  onChange,
  errorMessage,
  fullWidth = true,
  placeholder,
  value,
  ...rest
}) => {
  return (
    <Box my={2}>
      <Box my={0.5}>
        <InputLabel>{label}</InputLabel>
      </Box>
      <Autocomplete
        options={options}
        disableClearable
        size="small"
        value={value}
        placeholder={placeholder}
        fullWidth={fullWidth}
        renderInput={(params) => (
          <TextField
            error={!!errorMessage}
            helperText={errorMessage}
            sx={{ backgroundColor: 'common.white' }}
            {...params}
          />
        )}
        onChange={(_, data) => onChange && onChange(data)}
        {...rest}
      />
    </Box>
  )
}

export default AutocompleteInput
