import { Box, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material'
import { PropsWithChildren } from 'react'

interface SelectInputProps
  extends Pick<
    SelectProps,
    'fullWidth' | 'name' | 'defaultValue' | 'placeholder' | 'inputProps' | 'onChange' | 'value'
  > {
  errorMessage?: string
  label?: string
  defaultValue?: string
  extraSmall?: boolean
}

const SelectInput: React.FC<PropsWithChildren<SelectInputProps>> = ({
  defaultValue,
  label,
  errorMessage,
  name,
  extraSmall = false,
  fullWidth = true,
  ...rest
}) => (
  <Box my={2}>
    <Box my={0.5}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
    </Box>
    <Select
      {...rest}
      id={name}
      size="small"
      defaultValue={defaultValue}
      style={{ height: extraSmall ? '25px' : undefined }}
      error={!!errorMessage}
      fullWidth={fullWidth}
      sx={{ backgroundColor: 'common.white' }}
    />
    <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
  </Box>
)

export default SelectInput
