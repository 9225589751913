import { Box, Typography } from '@mui/material'
import DualCircularIndicator from '../../common/outputs/DualCircularIndicator'
import FilledLabelChip from '../../common/outputs/FilledLabelChip'

interface TrustIndicatorProps {
  coreTrust: number
  dynamicTrust: number
  trustLabel: string
  trustBaseLabel: string
}

const TrustIndicators: React.FC<TrustIndicatorProps> = ({
  coreTrust,
  dynamicTrust,
  trustLabel,
  trustBaseLabel,
}) => {
  return (
    <Box display="inline-grid" justifyItems="center" alignItems="center" columnGap={2}>
      <Box gridRow="1 / 2" gridColumn="1/2">
        <DualCircularIndicator
          outerValue={coreTrust}
          innerValue={dynamicTrust}
          label={trustLabel}
          outerColor="secondary.main"
          innerColor="secondary.dark"
        />
      </Box>
      <Box gridRow="1 / 2" gridColumn="2 / 3">
        <FilledLabelChip label={trustBaseLabel.toLocaleUpperCase()} />
      </Box>
      <Box>
        <Typography variant="body1">
          <b>Trust</b>
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          <b>Trust Base</b>
        </Typography>
      </Box>
    </Box>
  )
}

export default TrustIndicators
