import { yupResolver } from '@hookform/resolvers/yup'
import { CountriesWithCities } from '../data/CountriesWithCitiesArray'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import React from 'react'
import Grid2 from '@mui/material/Unstable_Grid2'
import FormHeader from '../FormHeader'
import HoverMessage from '../../../common/text/HoverMessage'
import { SingleUserContractFormState } from '.'
import TextInput from '../../../common/inputs/TextInput'
import SelectInput from '../../../common/inputs/SelectInput'
import { standProductsList } from '../data/StandardProductsList'
import { MenuItem } from '@mui/material'
import AutocompleteInput from '../../../common/inputs/AutocompleteInput'
import { ContractValueRanges } from '../data/ContractValueRanges'
import LoadingButton from '../../../common/inputs/LoadingButton'

interface ContractDetailsProps {
  onSubmit: (values: ContractDetailsValues) => void
  defaultValues: ContractDetailsValues
  isLoading: boolean
  unregistered?: boolean
}

export type ContractDetailsValues = Omit<SingleUserContractFormState, 'role'>

const ContractDetailsForm: React.FC<ContractDetailsProps> = ({
  onSubmit,
  defaultValues,
  isLoading,
  unregistered,
}) => {
  const ContractDetailsSchema = yup.object().shape({
    contractName: yup.string().required().label('Contract Name'),
    email: unregistered
      ? yup.string().required().label('Email').email('Invalid email address')
      : yup.string().label('Email').email('Invalid email address'),
    country: yup.string().required().label('Country'),
    city: yup.string().required().label('City'),
    goodsAndServicesCategory: yup.string().required().label('Goods and Services category'),
    contractValueRangePerYearId: yup
      .number()
      .typeError('Contract value is required')
      .integer('Contract value is required')
      .required()
      .label('Contract value'),
  })

  const {
    control,
    handleSubmit: useFormHandleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ContractDetailsValues>({
    resolver: yupResolver(ContractDetailsSchema),
    defaultValues,
  })

  React.useEffect(() => {
    watch((value, { name }) => {
      if (name === 'country') {
        const newCountry = value.country as string
        setValue('city', CountriesWithCities[newCountry as keyof typeof CountriesWithCities][0])
      }
    })
  }, [watch, setValue])

  return (
    <form onSubmit={useFormHandleSubmit(onSubmit)}>
      <Grid2 container spacing={4}>
        <Grid2 xs={12} lg={2}>
          <FormHeader
            title="Contract Details"
            mobileTitle="Add Contract"
            mobileSubTitle="Contract Details"
            details="Please provide a few details about the commercial contract"
          />
        </Grid2>
        <Grid2 xs={12} lg={5}>
          <HoverMessage message="Official name of the Contract as given by the Buyer organisation.">
            <Controller
              control={control}
              name="contractName"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.contractName?.message}
                  placeholder="Contract Name"
                  label="Contract Name"
                  fullWidth
                />
              )}
            />
          </HoverMessage>
          {unregistered && (
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.email?.message}
                  placeholder="your@email.com"
                  label="Email"
                  fullWidth
                />
              )}
            />
          )}
        </Grid2>
        <Grid2 xs={12} lg={5}>
          <Controller
            control={control}
            name="goodsAndServicesCategory"
            render={({ field }) => (
              <SelectInput
                label="Goods and Services Category"
                {...field}
                inputProps={{
                  name: 'name',
                  id: 'name',
                }}
                fullWidth
                defaultValue="Other Services"
              >
                {standProductsList
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((value) => {
                    return (
                      <MenuItem key={value.name} value={value.name}>
                        {value.name}
                      </MenuItem>
                    )
                  })}
              </SelectInput>
            )}
          />
          <Controller
            render={({ ...props }) => (
              <AutocompleteInput
                options={Object.getOwnPropertyNames(CountriesWithCities)}
                onChange={props.field.onChange}
                label="Country"
                value={props.field.value}
                errorMessage={errors.country?.message}
                {...props}
              />
            )}
            name="country"
            control={control}
          />
          <Controller
            render={({ ...props }) => (
              <AutocompleteInput
                options={getValues().country && CountriesWithCities[getValues().country]}
                onChange={props.field.onChange}
                value={props.field.value}
                label="City"
                {...props}
              />
            )}
            name="city"
            control={control}
          />
          <HoverMessage message="Amount of consideration ($) that will be paid by the buyer to the Supplier as agreed to by both parties, excluding variations and penalties.">
            <Controller
              control={control}
              name="contractValueRangePerYearId"
              render={({ field }) => (
                <SelectInput
                  label="Annual Contract Value"
                  {...field}
                  inputProps={{
                    name: 'contractValueRangePerYearId',
                    id: 'contractValueRangePerYearId',
                  }}
                  fullWidth
                  errorMessage={errors.contractValueRangePerYearId?.message}
                >
                  {ContractValueRanges.map(({ name, value }) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />
          </HoverMessage>
        </Grid2>
        <Grid2 xs={12} justifyContent="right" display="flex">
          <LoadingButton type="submit" fullWidth={false} loading={isLoading}>
            Continue
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  )
}

export default ContractDetailsForm
