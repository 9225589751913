import { Box, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import AccordionWrapper from '../../common/containers/AccordionWrapper'
import CircularIndicator from '../../common/outputs/CircularIndicator'
import DualCircularIndicator from '../../common/outputs/DualCircularIndicator'
import FilledLabelChip from '../../common/outputs/FilledLabelChip'

interface MobileContractMetricsProps {
  metrics: {
    label: string
    value: number
  }[]
  coreTrust: number
  dynamicTrust: number
  trustLabel: string
  trustBaseLabel: string
  contractName: string
  goodsAndServicesCategory: string
}

const MobileContractMetrics: React.FC<MobileContractMetricsProps> = ({
  metrics,
  coreTrust,
  dynamicTrust,
  trustLabel,
  trustBaseLabel,
  contractName,
  goodsAndServicesCategory,
}) => {
  const theme = useTheme()

  const isSmall = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  return (
    <Box bgcolor={theme.palette.common.white} pt={3} borderRadius="0px 0px 5px 5px" px={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="column" overflow="auto">
          <Typography variant="h3">{contractName}</Typography>
          <Typography variant="subtitle1" color="GrayText" noWrap>
            {goodsAndServicesCategory}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Trust</Typography>
          <DualCircularIndicator
            outerValue={coreTrust}
            innerValue={dynamicTrust}
            label={trustLabel}
            outerColor="secondary.main"
            innerColor="secondary.dark"
          />
        </Stack>
      </Stack>
      <AccordionWrapper showAsAccordion={isSmall}>
        <Grid2 container pb={1}>
          {metrics.map((m, i) => (
            <Grid2 key={m.label} xs={4} md={2}>
              <Box display="flex" justifyContent="center" my={1}>
                <CircularIndicator
                  value={m.value}
                  label={`${m.value}%`}
                  color={i < 3 ? '#ffd33a' : 'secondary.main'}
                />
              </Box>
              <Typography textAlign={'center'}>{m.label}</Typography>
            </Grid2>
          ))}
          <Grid2 xs={4} md={2} alignItems="flex-end" justifyContent="center" display="flex">
            <Box>
              <FilledLabelChip label={trustBaseLabel.toLocaleUpperCase()} />
              <Typography textAlign={'center'} pt={4}>
                Trust Base
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </AccordionWrapper>
    </Box>
  )
}

export default MobileContractMetrics
