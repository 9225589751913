import { Box, IconButton, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import theme from '../../../theme'

interface PersonnelListProps {
  isMobile: boolean
  personnel: { name: string; email: string; role: string }[]
}

const PersonnelList: React.FC<PersonnelListProps> = ({ isMobile, personnel }) => (
  <Box>
    {personnel.map((p, idx) => {
      return (
        <>
          {!isMobile && idx !== 0 && <Divider />}
          <Box
            display="flex"
            justifyContent="space-between"
            key={p.email}
            px={isMobile ? 2 : 4}
            sx={{
              background: isMobile || idx % 2 === 0 ? '' : 'rgba(255, 255, 255, 0.40)',
            }}
          >
            <Box my={2} width={'55%'}>
              <Typography variant="body1" fontWeight={550} noWrap>
                {p.name}
              </Typography>
              <Typography variant="subtitle1" color="GrayText" noWrap>
                {p.email}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="subtitle1" fontWeight={550} noWrap>
                <u>{p.role}</u>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <IconButton aria-label="more">
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )
    })}
  </Box>
)

const Divider = () => {
  return (
    <Box
      width={'100%'}
      height={'1px'}
      flexShrink={0}
      bgcolor={theme.palette.common.black}
      sx={{
        opacity: '0.04',
      }}
    ></Box>
  )
}

export default PersonnelList
