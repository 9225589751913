import { Box, Button, ButtonProps } from '@mui/material'
import { ReactNode } from 'react'

interface LargeButtonProps
  extends Pick<
    ButtonProps,
    'onClick' | 'disabled' | 'variant' | 'color' | 'fullWidth' | 'startIcon'
  > {
  label: string
  icon?: ReactNode
}

const LargeButton: React.FC<LargeButtonProps> = ({ label, icon, ...rest }) => {
  return (
    <Button disableElevation {...rest} sx={{ paddingY: 3, borderRadius: 3 }}>
      <Box width={'150px'} display={'flex'} gap={'24px'} fontSize={21}>
        {icon}
        {label}
      </Box>
    </Button>
  )
}

export default LargeButton
