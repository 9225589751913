import React, { PropsWithChildren, useState } from 'react'
import { SingleUserContractFormState } from '../components/contract/AddContract/AddSingleUserContract'
import { AnswerDto } from '../data/api/models'

interface AnonymousSoloAssessmentContextState {
  correlationId?: number
  setCorrelationId: (id: number) => void
  contractDetails?: SingleUserContractFormState
  setContractDetails: (form: SingleUserContractFormState) => void
  answers?: AnswerDto[]
  setAnswers: (answers: AnswerDto[]) => void
}

export const AnonymousSoloAssessmentContext =
  React.createContext<AnonymousSoloAssessmentContextState>({
    correlationId: undefined,
    setCorrelationId: (number) => {},
    contractDetails: undefined,
    setContractDetails: (form: SingleUserContractFormState) => {},
    answers: undefined,
    setAnswers: (answers) => {},
  })

export const AnonymousSoloAssessmentContextProvider = ({ children }: PropsWithChildren) => {
  const [contractDetails, setContractDetails] = useState<SingleUserContractFormState | undefined>(
    undefined
  )
  const [answerDto, setAnswers] = useState<AnswerDto[] | undefined>(undefined)
  const [correlationId, setCorrelationId] = useState<number | undefined>(undefined)

  return (
    <AnonymousSoloAssessmentContext.Provider
      value={{
        correlationId: correlationId,
        setCorrelationId: (id: number) => setCorrelationId(id),
        contractDetails: contractDetails,
        setContractDetails: (form: SingleUserContractFormState) => setContractDetails(form),
        answers: answerDto,
        setAnswers: (answers: AnswerDto[]) => setAnswers(answers),
      }}
    >
      {children}
    </AnonymousSoloAssessmentContext.Provider>
  )
}
