import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import MetricIcons from './MetricIcons'
import PersonnelList from './PersonnelList'
import AccordionWrapper from '../../common/containers/AccordionWrapper'

interface GroupProfileProps {
  groupProfileType: 'Buyer' | 'Supplier'
  personnel: { name: string; email: string; role: string }[]
  isMobile?: boolean
  groupName: string
  isReportView: boolean
  metrics: {
    metric: 'Integrity' | 'Solidarity' | 'Equality' | 'Commitment' | 'Agility' | 'Harmony'
    percentage: number
  }[]
}

const GroupProfile: React.FC<GroupProfileProps> = ({
  groupProfileType,
  personnel,
  isMobile = false,
  groupName,
  metrics,
  isReportView,
}) => {
  const theme = useTheme()
  let isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('lg')) && !isReportView

  return (
    <Box width={1} height={1} display="flex" flexDirection="column">
      <Box
        bgcolor={isMobileView ? theme.palette.common.white : '#F5F5F5'}
        mb={isMobile ? 0 : 0.5}
        pt={isMobile ? 3 : 4}
        pb={isMobile ? 0 : 4}
        px={isMobile ? 2 : 4}
        borderRadius="5px 5px 0px 0px"
      >
        {/*
        fontWeight={900} here doesn't do anything because <b></b> overrides it,
        but is required as hack for hidden report generation (font used in the report (Lato: Fontweight 900) need to be on screen, LOLWTF)
        -----------------------DO NOT REMOVE-----------------------
        */}
        <Typography variant="h3" mb={1} fontWeight={900}>
          <b>{groupProfileType} Group Profile</b>
        </Typography>
        {isMobile && (
          <Typography variant="subtitle1" color="GrayText">
            {groupName}
          </Typography>
        )}
        <Box sx={{ overflowX: 'auto' }}>
          <MetricIcons
            metrics={metrics}
            color={groupProfileType === 'Supplier' ? 'primary' : 'dataBlue'}
          />
        </Box>
      </Box>
      <Box
        bgcolor={isMobileView ? theme.palette.common.white : '#F5F5F5'}
        pt={isMobile ? 3 : 4}
        pb={isMobile ? 0 : 2}
        borderRadius="0px 0px 5px 5px"
        flexGrow={1}
      >
        <AccordionWrapper showAsAccordion={isMobile}>
          <Typography variant="h3" px={isMobile ? 2 : 4}>
            {groupProfileType} Personnel
          </Typography>
          <Box>
            <PersonnelList personnel={personnel} isMobile={isMobileView} />
          </Box>
        </AccordionWrapper>
      </Box>
    </Box>
  )
}

export default GroupProfile
