import { Box, Grid, Typography, useTheme } from '@mui/material'
import FilledLabelChip from '../../common/outputs/FilledLabelChip'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

const TrustBase = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>
      <Typography fontSize={45} fontWeight={900} mt={15} lineHeight={1.2}>
        INTRODUCING THE
        <br />
        FOUR TRUST BASES
      </Typography>
      <Grid container spacing={7} mt={6}>
        {TrustLevelConfig.map((x, idx) => (
          <Grid item xs={3} key={x.level}>
            <TrustLevel {...x} light={idx % 2 === 0} />
          </Grid>
        ))}
      </Grid>
      <Copyright pageNumber={9} />
    </Box>
  )
}

const TrustLevelConfig: TrustLevelProps[] = [
  {
    level: 'COLLABORATIVE',
    heading: 'COLLABORATIVE TRUST',
    subHeading: 'High performance and High commitment',
    description:
      'The holy grail of long-term strategic partnerships. It is where performance is high, as is the strength of the commercial relationship. Fundamentally, performance and commitment support each other to maintain high levels of trust. This reduces contract friction that in turn improves efficiency and boost performance. Collaborative trust base is hard to achieve and even harder to maintain. However, for achieving success in high value, complex, strategic long term commercial relationships, it’s crucial.',
  },
  {
    level: 'COMMITMENT',
    heading: 'COMMITMENT BASED TRUST',
    subHeading: 'Low performance and High commitment',
    description:
      'The parties focus predominantly on having strong strategic relationships and are there for the long haul. High performance is implied, but not necessarily focused upon. Unfortunately, commitment can only support the relationship for so long before lack of performance starts undermining trust. Commitment based trust is a great starting point for a commercial relationship, however it’s important to quickly start delivering on performance commitments before the relationships end up in the Limited trust quadrant.',
  },
  {
    level: 'PERFORMANCE',
    heading: 'PERFORMANCE BASED TRUST',
    subHeading: 'High performance and Low commitment',
    description:
      'The commercial relationship is one where the parties are achieving strong transactional performance in the commercial agreement but are reluctant to commit attention or resources to a long-term partnership. Strong performance paves the way to higher trust, but ascension does not occur. This is an appropriate and cost-effective quadrant for highly transactional and commoditised commercial agreements, where the investment to achieve quality trust and relationships far out way the benefits.',
  },
  {
    level: 'LIMITED',
    heading: 'LIMITED TRUST',
    subHeading: 'Low performance and Low commitment',
    description:
      'When a commercial relationship has low commitment and performance, its trust base is low and limited. The parties are perceived to not deliver on their promises, are viewed with suspicion, and are seen as opportunistic in their business dealings. For simple transactional agreements this may be satisfactory, however for more complex, higher value, longer term, and strategic relationships, it’s imperative to transform the relationship out of this',
  },
]

type TrustLevelProps = {
  level: string
  heading: string
  subHeading: string
  description: string
  light?: boolean
}

const TrustLevel = ({ level, heading, subHeading, description, light }: TrustLevelProps) => {
  const theme = useTheme()

  return (
    <Box>
      <FilledLabelChip
        bgColor={!!light ? theme.palette.dataBlue.light : theme.palette.dataBlue.main}
        mx={0}
        fontSize="20px"
        label={level.toLocaleUpperCase()}
        color={theme.palette.common.black}
        justifyContent="left"
      />
      <Typography fontSize={30} fontWeight={900} mt={'35px'} lineHeight={1.2}>
        {heading}
      </Typography>
      <Typography fontSize={18} fontWeight="bold" mt={'15px'}>
        {subHeading}
      </Typography>
      <Typography mt={'15px'} fontSize={16}>
        {description}
      </Typography>
    </Box>
  )
}

export default TrustBase
