import * as yup from 'yup'

const surveyDetailsSchema = yup.object().shape({
  firstName: yup.string().required().label('First name'),
  lastName: yup.string().required().label('Last name'),
  role: yup.string().required().label('Role'),
  yearsInRole: yup
    .number()
    .typeError('Role is required')
    .required()
    .moreThan(0)
    .integer('Value must be a whole number')
    .label('Years'),
})

export default surveyDetailsSchema
