import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { UserContractDto } from '../../../data/api/models'
import PersonnelTableRow from './PersonnelTableRow'

interface PersonnelTableProps {
  personnel: UserContractDto[]
  buyerOrganisationName: string
  supplierOrganisationName: string
  refreshContracts: () => void
  contractId: number
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#9D9D9C',
    border: 0,
    fontSize: 13,
  },
}))

const PersonnelTable: React.FC<PersonnelTableProps> = ({
  personnel,
  buyerOrganisationName,
  supplierOrganisationName,
  refreshContracts,
  contractId,
}) => {
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const withNonMobileWidth = (width: string) => (isMobileView ? {} : { sx: { width: `${width}` } })

  return (
    <TableContainer>
      <Table size="small" sx={{ tableLayout: 'fixed', minWidth: isMobileView ? 300 : 600 }}>
        <TableHead>
          <TableRow
            sx={{
              'th:first-child': {
                paddingLeft: '24px',
              },
              'th:last-child': {
                paddingLast: '24px',
              },
            }}
          >
            {!isMobileView && (
              <StyledTableCell {...withNonMobileWidth('22%')}>Personnel</StyledTableCell>
            )}
            <StyledTableCell {...withNonMobileWidth('25.7%')}>Email</StyledTableCell>
            {!isMobileView && (
              <>
                <StyledTableCell {...withNonMobileWidth('28%')}>Organisation</StyledTableCell>
                <StyledTableCell {...withNonMobileWidth('10%')}>Role</StyledTableCell>
              </>
            )}
            <StyledTableCell>Assessment</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {personnel.map((p, idx) => (
            <PersonnelTableRow
              isEvenRow={idx % 2 !== 0}
              personnel={p}
              buyerOrganisationName={buyerOrganisationName}
              supplierOrganisationName={supplierOrganisationName}
              key={p.email}
              refreshContracts={refreshContracts}
              allowRemovePersonnel={
                !!personnel?.filter((p) => !p.completedSurvey).length && personnel?.length > 1
              }
              contractId={contractId}
              isMinimalView={isMobileView}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PersonnelTable
