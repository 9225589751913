import { Box, Button, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { HEADING_SPACING } from '../../../constants/spacingConstants'
import { UserDto } from '../../../data/api/models'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import EditAccountForm from './EditAccountForm'

interface EditAccountProps {
  user: UserDto
}

const EditAccount: React.FunctionComponent<EditAccountProps> = ({ user }) => {
  const navigate = useNavigate()

  return (
    <Box>
      <Container maxWidth="lg">
        <Box marginBottom={HEADING_SPACING} width={1}>
          <Button variant="text" startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
      </Container>
      <Container maxWidth="xs">
        <Typography variant="h1" pb={6}>
          Edit Account
        </Typography>
        <EditAccountForm
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
          }}
        />
      </Container>
    </Box>
  )
}

export default EditAccount
