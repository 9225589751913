import { Box, Stack, Typography, useTheme } from '@mui/material'
import AccordionWrapper from '../../../common/containers/AccordionWrapper'
import CircularIndicator from '../../../common/outputs/CircularIndicator'
import { perceivedTrustDescriptions } from './descriptionText'

interface PerceivedTrustProps {
  trust: number
  isReportView?: boolean
}

const PerceivedTrust: React.FC<PerceivedTrustProps> = ({ trust, isReportView }) => {
  const theme = useTheme()

  const trustDetails = perceivedTrustDescriptions.find((x) => trust > x.minValue)

  return (
    <Box
      bgcolor={theme.palette.common.white}
      borderRadius={2}
      px={2}
      pt={2}
      pb={isReportView ? 2 : 0}
    >
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignItems={'center'}
        mb={1}
      >
        <Typography variant="h2">
          <b>Trust</b>
        </Typography>
        <CircularIndicator value={trust} label={`${trust}%`} color={'secondary.main'} />
      </Stack>
      <AccordionWrapper showAsAccordion={!isReportView}>
        <Typography variant="h3" display={'span'} color={theme.palette.secondary.main} pb={1}>
          <b>{trustDetails?.title}</b>
        </Typography>
        <Typography>{trustDetails?.description}</Typography>
      </AccordionWrapper>
    </Box>
  )
}

export default PerceivedTrust
