export const CountriesCurrencies: {
  CountryName: string
  CurrencyCode: string
  CurrencyName: string
}[] = [
  { CountryName: 'Afghanistan', CurrencyCode: 'AFA', CurrencyName: 'Afghan Afghani' },
  { CountryName: 'Albania', CurrencyCode: 'ALL', CurrencyName: 'Albanian Lek' },
  { CountryName: 'Algeria', CurrencyCode: 'DZD', CurrencyName: 'Algerian Dinar' },
  { CountryName: 'Andorra', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Angola', CurrencyCode: 'AOA', CurrencyName: 'Angolan Kwanza' },
  { CountryName: 'Argentina', CurrencyCode: 'ARS', CurrencyName: 'Argentine Peso' },
  { CountryName: 'Australia', CurrencyCode: 'AUD', CurrencyName: 'Australian Dollar' },
  { CountryName: 'Azerbaijan', CurrencyCode: 'AZN', CurrencyName: 'Azerbaijani Manat' },
  { CountryName: 'Bahamas', CurrencyCode: 'BSD', CurrencyName: 'Bahamian Dollar' },
  { CountryName: 'Bahrain', CurrencyCode: 'BHD', CurrencyName: 'Bahraini Dinar' },
  { CountryName: 'Bangladesh', CurrencyCode: 'BDT', CurrencyName: 'Bangladeshi Taka' },
  { CountryName: 'Barbados', CurrencyCode: 'BBD', CurrencyName: 'Barbadian Dollar' },
  { CountryName: 'Belarus', CurrencyCode: 'BYR', CurrencyName: 'Belarusian Ruble' },
  { CountryName: 'Belize', CurrencyCode: 'BZD', CurrencyName: 'Belize Dollar' },
  { CountryName: 'Benin', CurrencyCode: 'XOF', CurrencyName: 'West African CFA Franc' },
  { CountryName: 'Bhutan', CurrencyCode: 'BTN', CurrencyName: 'Bhutanese Ngultrum' },
  { CountryName: 'Bolivia', CurrencyCode: 'BOB', CurrencyName: 'Bolivian Boliviano' },
  {
    CountryName: 'Bosnia and Herzegovina',
    CurrencyCode: 'BAM',
    CurrencyName: 'Bosnia-Herzegovina Convertible Mark',
  },
  { CountryName: 'Botswana', CurrencyCode: 'BWP', CurrencyName: 'Botswana Pula' },
  { CountryName: 'Brazil', CurrencyCode: 'BRL', CurrencyName: 'Brazilian Real' },
  { CountryName: 'Brunei', CurrencyCode: 'BND', CurrencyName: 'Brunei Dollar' },
  { CountryName: 'Bulgaria', CurrencyCode: 'BGN', CurrencyName: 'Bulgarian Lev' },
  { CountryName: 'Canada', CurrencyCode: 'CAD', CurrencyName: 'Canadian Dollar' },
  { CountryName: 'Cape Verde', CurrencyCode: 'CVE', CurrencyName: 'Cape Verdean Escudo' },
  {
    CountryName: 'Central African Republic',
    CurrencyCode: 'XAF',
    CurrencyName: 'Central African CFA Franc',
  },
  { CountryName: 'Chile', CurrencyCode: 'CLP', CurrencyName: 'Chilean Peso' },
  { CountryName: 'China', CurrencyCode: 'CNY', CurrencyName: 'Chinese Yuan' },
  { CountryName: 'Colombia', CurrencyCode: 'COP', CurrencyName: 'Colombian Peso' },
  { CountryName: 'Comoros', CurrencyCode: 'KMF', CurrencyName: 'Comorian Franc' },
  { CountryName: 'Costa Rica', CurrencyCode: 'CRC', CurrencyName: 'Costa Rican Colón' },
  { CountryName: 'Croatia', CurrencyCode: 'HRK', CurrencyName: 'Croatian Kuna' },
  { CountryName: 'Cuba', CurrencyCode: 'CUC', CurrencyName: 'Cuban Peso' },
  { CountryName: 'Czech Republic', CurrencyCode: 'CZK', CurrencyName: 'Czech Koruna' },
  { CountryName: 'Denmark', CurrencyCode: 'DKK', CurrencyName: 'Danish Krone' },
  { CountryName: 'Djibouti', CurrencyCode: 'DJF', CurrencyName: 'Djiboutian Franc' },
  { CountryName: 'Dominica', CurrencyCode: 'XCD', CurrencyName: 'East Caribbean Dollar' },
  { CountryName: 'Dominican Republic', CurrencyCode: 'DOP', CurrencyName: 'Dominican Peso' },
  { CountryName: 'Egypt', CurrencyCode: 'EGP', CurrencyName: 'Egyptian Pound' },
  { CountryName: 'Eritrea', CurrencyCode: 'ERN', CurrencyName: 'Eritrean Nakfa' },
  { CountryName: 'Eswatini', CurrencyCode: 'SZL', CurrencyName: 'Swazi Lilangeni' },
  { CountryName: 'Ethiopia', CurrencyCode: 'ETB', CurrencyName: 'Ethiopian Birr' },
  { CountryName: 'Fiji', CurrencyCode: 'FJD', CurrencyName: 'Fijian Dollar' },
  { CountryName: 'Gambia', CurrencyCode: 'GMD', CurrencyName: 'Gambian Dalasi' },
  { CountryName: 'Georgia', CurrencyCode: 'GEL', CurrencyName: 'Georgian Lari' },
  { CountryName: 'Ghana', CurrencyCode: 'GHS', CurrencyName: 'Ghanaian Cedi' },
  { CountryName: 'Guatemala', CurrencyCode: 'GTQ', CurrencyName: 'Guatemalan Quetzal' },
  { CountryName: 'Guinea', CurrencyCode: 'GNF', CurrencyName: 'Guinean Franc' },
  { CountryName: 'Guyana', CurrencyCode: 'GYD', CurrencyName: 'Guyanese Dollar' },
  { CountryName: 'Haiti', CurrencyCode: 'HTG', CurrencyName: 'Haitian Gourde' },
  { CountryName: 'Honduras', CurrencyCode: 'HNL', CurrencyName: 'Honduran Lempira' },
  { CountryName: 'Hungary', CurrencyCode: 'HUF', CurrencyName: 'Hungarian Forint' },
  { CountryName: 'Iceland', CurrencyCode: 'ISK', CurrencyName: 'Icelandic Króna' },
  { CountryName: 'India', CurrencyCode: 'INR', CurrencyName: 'Indian Rupee' },
  { CountryName: 'Indonesia', CurrencyCode: 'IDR', CurrencyName: 'Indonesian Rupiah' },
  { CountryName: 'Iran', CurrencyCode: 'IRR', CurrencyName: 'Iranian Rial' },
  { CountryName: 'Iraq', CurrencyCode: 'IQD', CurrencyName: 'Iraqi Dinar' },
  { CountryName: 'Israel', CurrencyCode: 'ILS', CurrencyName: 'Israeli New Shekel' },
  { CountryName: 'Jamaica', CurrencyCode: 'JMD', CurrencyName: 'Jamaican Dollar' },
  { CountryName: 'Japan', CurrencyCode: 'JPY', CurrencyName: 'Japanese Yen' },
  { CountryName: 'Jordan', CurrencyCode: 'JOD', CurrencyName: 'Jordanian Dinar' },
  { CountryName: 'Kazakhstan', CurrencyCode: 'KZT', CurrencyName: 'Kazakhstani Tenge' },
  { CountryName: 'Kenya', CurrencyCode: 'KES', CurrencyName: 'Kenyan Shilling' },
  { CountryName: 'Kiribati', CurrencyCode: 'AUD', CurrencyName: 'Australian Dollar' },
  { CountryName: 'Kuwait', CurrencyCode: 'KWD', CurrencyName: 'Kuwaiti Dinar' },
  { CountryName: 'Kyrgyzstan', CurrencyCode: 'KGS', CurrencyName: 'Kyrgyzstani Som' },
  { CountryName: 'Laos', CurrencyCode: 'LAK', CurrencyName: 'Lao Kip' },
  { CountryName: 'Latvia', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Lebanon', CurrencyCode: 'LBP', CurrencyName: 'Lebanese Pound' },
  { CountryName: 'Lesotho', CurrencyCode: 'LSL', CurrencyName: 'Lesotho Loti' },
  { CountryName: 'Liberia', CurrencyCode: 'LRD', CurrencyName: 'Liberian Dollar' },
  { CountryName: 'Libya', CurrencyCode: 'LYD', CurrencyName: 'Libyan Dinar' },
  { CountryName: 'Liechtenstein', CurrencyCode: 'CHF', CurrencyName: 'Swiss Franc' },
  { CountryName: 'Lithuania', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Luxembourg', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Madagascar', CurrencyCode: 'MGA', CurrencyName: 'Malagasy Ariary' },
  { CountryName: 'Malawi', CurrencyCode: 'MWK', CurrencyName: 'Malawian Kwacha' },
  { CountryName: 'Malaysia', CurrencyCode: 'MYR', CurrencyName: 'Malaysian Ringgit' },
  { CountryName: 'Maldives', CurrencyCode: 'MVR', CurrencyName: 'Maldivian Rufiyaa' },
  { CountryName: 'Mali', CurrencyCode: 'XOF', CurrencyName: 'West African CFA Franc' },
  { CountryName: 'Malta', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Mauritania', CurrencyCode: 'MRO', CurrencyName: 'Mauritanian Ouguiya' },
  { CountryName: 'Mauritius', CurrencyCode: 'MUR', CurrencyName: 'Mauritian Rupee' },
  { CountryName: 'Mexico', CurrencyCode: 'MXN', CurrencyName: 'Mexican Peso' },
  { CountryName: 'Micronesia', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'Moldova', CurrencyCode: 'MDL', CurrencyName: 'Moldovan Leu' },
  { CountryName: 'Monaco', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Mongolia', CurrencyCode: 'MNT', CurrencyName: 'Mongolian Tugrik' },
  { CountryName: 'Montenegro', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Morocco', CurrencyCode: 'MAD', CurrencyName: 'Moroccan Dirham' },
  { CountryName: 'Mozambique', CurrencyCode: 'MZM', CurrencyName: 'Mozambican Metical' },
  { CountryName: 'Myanmar', CurrencyCode: 'MMK', CurrencyName: 'Myanmar Kyat' },
  { CountryName: 'Namibia', CurrencyCode: 'NAD', CurrencyName: 'Namibian Dollar' },
  { CountryName: 'Nauru', CurrencyCode: 'AUD', CurrencyName: 'Australian Dollar' },
  { CountryName: 'Nepal', CurrencyCode: 'NPR', CurrencyName: 'Nepalese Rupee' },
  { CountryName: 'Netherlands', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'New Zealand', CurrencyCode: 'NZD', CurrencyName: 'New Zealand Dollar' },
  { CountryName: 'Nicaragua', CurrencyCode: 'NIO', CurrencyName: 'Nicaraguan Córdoba' },
  { CountryName: 'Niger', CurrencyCode: 'XOF', CurrencyName: 'West African CFA Franc' },
  { CountryName: 'Nigeria', CurrencyCode: 'NGN', CurrencyName: 'Nigerian Naira' },
  { CountryName: 'North Korea', CurrencyCode: 'KPW', CurrencyName: 'North Korean Won' },
  { CountryName: 'North Macedonia', CurrencyCode: 'MKD', CurrencyName: 'Macedonian Denar' },
  { CountryName: 'Norway', CurrencyCode: 'NOK', CurrencyName: 'Norwegian Krone' },
  { CountryName: 'Oman', CurrencyCode: 'OMR', CurrencyName: 'Omani Rial' },
  { CountryName: 'Pakistan', CurrencyCode: 'PKR', CurrencyName: 'Pakistani Rupee' },
  { CountryName: 'Palau', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'Panama', CurrencyCode: 'PAB', CurrencyName: 'Panamanian Balboa' },
  { CountryName: 'Papua New Guinea', CurrencyCode: 'PGK', CurrencyName: 'Papua New Guinean Kina' },
  { CountryName: 'Paraguay', CurrencyCode: 'PYG', CurrencyName: 'Paraguayan Guarani' },
  { CountryName: 'Peru', CurrencyCode: 'PEN', CurrencyName: 'Peruvian Sol' },
  { CountryName: 'Philippines', CurrencyCode: 'PHP', CurrencyName: 'Philippine Peso' },
  { CountryName: 'Poland', CurrencyCode: 'PLN', CurrencyName: 'Polish Złoty' },
  { CountryName: 'Portugal', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Qatar', CurrencyCode: 'QAR', CurrencyName: 'Qatari Riyal' },
  { CountryName: 'Romania', CurrencyCode: 'RON', CurrencyName: 'Romanian Leu' },
  { CountryName: 'Russia', CurrencyCode: 'RUB', CurrencyName: 'Russian Ruble' },
  { CountryName: 'Rwanda', CurrencyCode: 'RWF', CurrencyName: 'Rwandan Franc' },
  {
    CountryName: 'Saint Kitts and Nevis',
    CurrencyCode: 'XCD',
    CurrencyName: 'East Caribbean Dollar',
  },
  { CountryName: 'Saint Lucia', CurrencyCode: 'XCD', CurrencyName: 'East Caribbean Dollar' },
  {
    CountryName: 'Saint Vincent and the Grenadines',
    CurrencyCode: 'XCD',
    CurrencyName: 'East Caribbean Dollar',
  },
  { CountryName: 'Samoa', CurrencyCode: 'WST', CurrencyName: 'Samoan Tala' },
  { CountryName: 'San Marino', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  {
    CountryName: 'Sao Tome and Principe',
    CurrencyCode: 'STD',
    CurrencyName: 'São Tomé and Príncipe Dobra',
  },
  { CountryName: 'Saudi Arabia', CurrencyCode: 'SAR', CurrencyName: 'Saudi Riyal' },
  { CountryName: 'Senegal', CurrencyCode: 'XOF', CurrencyName: 'West African CFA Franc' },
  { CountryName: 'Serbia', CurrencyCode: 'RSD', CurrencyName: 'Serbian Dinar' },
  { CountryName: 'Seychelles', CurrencyCode: 'SCR', CurrencyName: 'Seychellois Rupee' },
  { CountryName: 'Sierra Leone', CurrencyCode: 'SLL', CurrencyName: 'Sierra Leonean Leone' },
  { CountryName: 'Singapore', CurrencyCode: 'SGD', CurrencyName: 'Singapore Dollar' },
  { CountryName: 'Slovakia', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Slovenia', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Solomon Islands', CurrencyCode: 'SBD', CurrencyName: 'Solomon Islands Dollar' },
  { CountryName: 'Somalia', CurrencyCode: 'SOS', CurrencyName: 'Somali Shilling' },
  { CountryName: 'South Africa', CurrencyCode: 'ZAR', CurrencyName: 'South African Rand' },
  { CountryName: 'South Korea', CurrencyCode: 'KRW', CurrencyName: 'South Korean Won' },
  { CountryName: 'South Sudan', CurrencyCode: 'SSP', CurrencyName: 'South Sudanese Pound' },
  { CountryName: 'Spain', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Sri Lanka', CurrencyCode: 'LKR', CurrencyName: 'Sri Lankan Rupee' },
  { CountryName: 'Sudan', CurrencyCode: 'SDG', CurrencyName: 'Sudanese Pound' },
  { CountryName: 'Suriname', CurrencyCode: 'SRD', CurrencyName: 'Surinamese Dollar' },
  { CountryName: 'Sweden', CurrencyCode: 'SEK', CurrencyName: 'Swedish Krona' },
  { CountryName: 'Switzerland', CurrencyCode: 'CHF', CurrencyName: 'Swiss Franc' },
  { CountryName: 'Syria', CurrencyCode: 'SYP', CurrencyName: 'Syrian Pound' },
  { CountryName: 'Taiwan', CurrencyCode: 'TWD', CurrencyName: 'New Taiwan Dollar' },
  { CountryName: 'Tajikistan', CurrencyCode: 'TJS', CurrencyName: 'Tajikistani Somoni' },
  { CountryName: 'Tanzania', CurrencyCode: 'TZS', CurrencyName: 'Tanzanian Shilling' },
  { CountryName: 'Thailand', CurrencyCode: 'THB', CurrencyName: 'Thai Baht' },
  { CountryName: 'Timor-Leste', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'Togo', CurrencyCode: 'XOF', CurrencyName: 'West African CFA Franc' },
  { CountryName: 'Tonga', CurrencyCode: 'TOP', CurrencyName: 'Tongan Paʻanga' },
  {
    CountryName: 'Trinidad and Tobago',
    CurrencyCode: 'TTD',
    CurrencyName: 'Trinidad and Tobago Dollar',
  },
  { CountryName: 'Tunisia', CurrencyCode: 'TND', CurrencyName: 'Tunisian Dinar' },
  { CountryName: 'Turkey', CurrencyCode: 'TRY', CurrencyName: 'Turkish Lira' },
  { CountryName: 'Turkmenistan', CurrencyCode: 'TMT', CurrencyName: 'Turkmenistan Manat' },
  { CountryName: 'Tuvalu', CurrencyCode: 'AUD', CurrencyName: 'Australian Dollar' },
  { CountryName: 'Uganda', CurrencyCode: 'UGX', CurrencyName: 'Ugandan Shilling' },
  { CountryName: 'Ukraine', CurrencyCode: 'UAH', CurrencyName: 'Ukrainian Hryvnia' },
  {
    CountryName: 'United Arab Emirates',
    CurrencyCode: 'AED',
    CurrencyName: 'United Arab Emirates Dirham',
  },
  { CountryName: 'United Kingdom', CurrencyCode: 'GBP', CurrencyName: 'British Pound' },
  { CountryName: 'United States', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'Uruguay', CurrencyCode: 'UYU', CurrencyName: 'Uruguayan Peso' },
  { CountryName: 'Uzbekistan', CurrencyCode: 'UZS', CurrencyName: 'Uzbekistani Som' },
  { CountryName: 'Vanuatu', CurrencyCode: 'VUV', CurrencyName: 'Vanuatu Vatu' },
  { CountryName: 'Vatican City', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Venezuela', CurrencyCode: 'VEF', CurrencyName: 'Venezuelan Bolívar' },
  { CountryName: 'Vietnam', CurrencyCode: 'VND', CurrencyName: 'Vietnamese Đồng' },
  { CountryName: 'Yemen', CurrencyCode: 'YER', CurrencyName: 'Yemeni Rial' },
  { CountryName: 'Zambia', CurrencyCode: 'ZMK', CurrencyName: 'Zambian Kwacha' },
  { CountryName: 'Zimbabwe', CurrencyCode: 'ZWL', CurrencyName: 'Zimbabwean Dollar' },
  {
    CountryName: 'Antigua and Barbuda',
    CurrencyCode: 'XCD',
    CurrencyName: 'East Caribbean Dollar',
  },
  { CountryName: 'Armenia', CurrencyCode: 'AMD', CurrencyName: 'Armenian Dram' },
  { CountryName: 'Aruba', CurrencyCode: 'AWG', CurrencyName: 'Aruban Florin' },
  { CountryName: 'Austria', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Belgium', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Cambodia', CurrencyCode: 'KHR', CurrencyName: 'Cambodian Riel' },
  { CountryName: 'Cameroon', CurrencyCode: 'XAF', CurrencyName: 'Central African CFA Franc' },
  { CountryName: 'Cayman Islands', CurrencyCode: 'KYD', CurrencyName: 'Cayman Islands Dollar' },
  { CountryName: 'Congo', CurrencyCode: 'CDF', CurrencyName: 'Congolese Franc' },
  { CountryName: 'Cyprus', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Ecuador', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'El Salvador', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'Estonia', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Faroe Islands', CurrencyCode: 'DKK', CurrencyName: 'Danish Krone' },
  { CountryName: 'Finland', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'France', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'French Polynesia', CurrencyCode: 'XPF', CurrencyName: 'CFP Franc' },
  { CountryName: 'Gabon', CurrencyCode: 'XAF', CurrencyName: 'Central African CFA Franc' },
  { CountryName: 'Germany', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Greece', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Greenland', CurrencyCode: 'DKK', CurrencyName: 'Danish Krone' },
  { CountryName: 'Guadeloupe', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Guam', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  {
    CountryName: 'Hashemite Kingdom of Jordan',
    CurrencyCode: 'JOD',
    CurrencyName: 'Jordanian Dinar',
  },
  { CountryName: 'Hong Kong', CurrencyCode: 'HKD', CurrencyName: 'Hong Kong Dollar' },
  { CountryName: 'Ireland', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Isle of Man', CurrencyCode: 'GBP', CurrencyName: 'British Pound' },
  { CountryName: 'Italy', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Kosovo', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Macedonia', CurrencyCode: 'MKD', CurrencyName: 'Macedonian Denar' },
  { CountryName: 'Martinique', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Mayotte', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Myanmar [Burma]', CurrencyCode: 'MMK', CurrencyName: 'Myanmar Kyat' },
  { CountryName: 'New Caledonia', CurrencyCode: 'XPF', CurrencyName: 'CFP Franc' },
  { CountryName: 'Palestine', CurrencyCode: 'ILS', CurrencyName: 'Israeli New Shekel' },
  { CountryName: 'Puerto Rico', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
  { CountryName: 'Republic of Korea', CurrencyCode: 'KRW', CurrencyName: 'South Korean Won' },
  { CountryName: 'Republic of Lithuania', CurrencyCode: 'EUR', CurrencyName: 'Euro' },
  { CountryName: 'Republic of Moldova', CurrencyCode: 'MDL', CurrencyName: 'Moldovan Leu' },
  { CountryName: 'Swaziland', CurrencyCode: 'SZL', CurrencyName: 'Swazi Lilangeni' },
  { CountryName: 'U.S. Virgin Islands', CurrencyCode: 'USD', CurrencyName: 'United States Dollar' },
]

export const GetCurrencyNameByCountryName = (countryName: string) =>
  CountriesCurrencies.find((x) => x.CountryName === countryName)?.CurrencyName ??
  'Australian Dollar'
export const GetCurrencyCodeByCurrencyName = (currencyName: string) =>
  CountriesCurrencies.find((x) => x.CurrencyName === currencyName)!.CurrencyCode

export const GetCurrencyCodeByCountryName = (countryName: string) =>
  CountriesCurrencies.find((x) => x.CountryName === countryName)!.CurrencyCode

export const GetAllCurrencyNames = Array.from(
  new Set(CountriesCurrencies.map((x) => x.CurrencyName))
).sort()
