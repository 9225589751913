/* tslint:disable */
/* eslint-disable */
/**
 * api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
import { QuestionDto } from '../models'
import { SubmitSingleUserContractSurveyRequest } from '../models'
import { SubmitSurveyCommand } from '../models'
import { SubmitSurveyDetailsCommand } from '../models'
/**
 * SurveyApi - axios parameter creator
 * @export
 */
export const SurveyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveyQuestionsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Survey/questions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {SubmitSingleUserContractSurveyRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveySubmitSingleUserContractSurveyPost: async (
      body?: SubmitSingleUserContractSurveyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Survey/submitSingleUserContractSurvey`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {SubmitSurveyDetailsCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveySubmitSurveyDetailsPost: async (
      body?: SubmitSurveyDetailsCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Survey/submitSurveyDetails`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {SubmitSurveyCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveySubmitSurveyPost: async (
      body?: SubmitSurveyCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Survey/submitSurvey`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SurveyApi - functional programming interface
 * @export
 */
export const SurveyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSurveyQuestionsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionDto>>> {
      const localVarAxiosArgs = await SurveyApiAxiosParamCreator(
        configuration
      ).apiSurveyQuestionsGet(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {SubmitSingleUserContractSurveyRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSurveySubmitSingleUserContractSurveyPost(
      body?: SubmitSingleUserContractSurveyRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await SurveyApiAxiosParamCreator(
        configuration
      ).apiSurveySubmitSingleUserContractSurveyPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {SubmitSurveyDetailsCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSurveySubmitSurveyDetailsPost(
      body?: SubmitSurveyDetailsCommand,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await SurveyApiAxiosParamCreator(
        configuration
      ).apiSurveySubmitSurveyDetailsPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {SubmitSurveyCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSurveySubmitSurveyPost(
      body?: SubmitSurveyCommand,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await SurveyApiAxiosParamCreator(
        configuration
      ).apiSurveySubmitSurveyPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SurveyApi - factory interface
 * @export
 */
export const SurveyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveyQuestionsGet(options?: any): AxiosPromise<Array<QuestionDto>> {
      return SurveyApiFp(configuration)
        .apiSurveyQuestionsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {SubmitSingleUserContractSurveyRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveySubmitSingleUserContractSurveyPost(
      body?: SubmitSingleUserContractSurveyRequest,
      options?: any
    ): AxiosPromise<void> {
      return SurveyApiFp(configuration)
        .apiSurveySubmitSingleUserContractSurveyPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {SubmitSurveyDetailsCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveySubmitSurveyDetailsPost(
      body?: SubmitSurveyDetailsCommand,
      options?: any
    ): AxiosPromise<void> {
      return SurveyApiFp(configuration)
        .apiSurveySubmitSurveyDetailsPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {SubmitSurveyCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSurveySubmitSurveyPost(body?: SubmitSurveyCommand, options?: any): AxiosPromise<void> {
      return SurveyApiFp(configuration)
        .apiSurveySubmitSurveyPost(body, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SurveyApi - object-oriented interface
 * @export
 * @class SurveyApi
 * @extends {BaseAPI}
 */
export class SurveyApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyApi
   */
  public apiSurveyQuestionsGet(options?: any) {
    return SurveyApiFp(this.configuration)
      .apiSurveyQuestionsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {SubmitSingleUserContractSurveyRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyApi
   */
  public apiSurveySubmitSingleUserContractSurveyPost(
    body?: SubmitSingleUserContractSurveyRequest,
    options?: any
  ) {
    return SurveyApiFp(this.configuration)
      .apiSurveySubmitSingleUserContractSurveyPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {SubmitSurveyDetailsCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyApi
   */
  public apiSurveySubmitSurveyDetailsPost(body?: SubmitSurveyDetailsCommand, options?: any) {
    return SurveyApiFp(this.configuration)
      .apiSurveySubmitSurveyDetailsPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {SubmitSurveyCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveyApi
   */
  public apiSurveySubmitSurveyPost(body?: SubmitSurveyCommand, options?: any) {
    return SurveyApiFp(this.configuration)
      .apiSurveySubmitSurveyPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
