import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

const Profile = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box>
        <Logo width={250} />
      </Box>

      <Typography
        mt={'250px'}
        display={'inline-block'}
        fontSize={50}
        lineHeight={1.2}
        fontWeight={900}
      >
        RELATIONAL
        <br />
        IMPACT PROFILE
      </Typography>
      <Typography mt={4} fontSize={30} lineHeight={1.2}>
        The Relational Impact Profile compares the relational qualities of the Buyer
        <br />
        and Supplier groups. The data can be used for insights to improve commercial
        <br />
        relationships and respective level of trust.
      </Typography>
      <Copyright pageNumber={4} />
    </Box>
  )
}

export default Profile
