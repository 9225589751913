import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'

interface FilledLabelChipsProps {
  label: string
  mx?: number
  fontSize?: string
  bgColor?: string
  color?: string
  justifyContent?: string
}

const FilledLabelChip: React.FC<FilledLabelChipsProps> = ({
  label,
  bgColor,
  color,
  mx = 1,
  fontSize = '15px',
  justifyContent = 'center',
}) => {
  const theme = useTheme()

  const isSmall = useMediaQuery((t: Theme) => t.breakpoints.down('lg'))

  return (
    <Box display="flex" justifyContent={justifyContent}>
      <Box
        bgcolor={bgColor ?? theme.palette.secondary.main}
        px={2}
        py={0.5}
        mx={mx}
        borderRadius={25}
      >
        <Typography
          variant={isSmall ? 'subtitle2' : 'body1'}
          fontSize={fontSize}
          color={color ?? theme.palette.common.white}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

export default FilledLabelChip
