import React, { createContext, useState } from 'react'
import OrganisationDetails from './OrganisationDetails'
import AddPersonnel from './AddPersonnel'
import SelectPrimaryRole from './SelectPrimaryRole'
import PrimaryContactDetails from './PrimaryContactDetails'
import PerformanceDetails from './PerformanceDetails'
import {
  ContractFormState,
  ContractFormContextModel,
  ContractFormStateModel,
  ContractRole,
} from './data/models'
import Details from './Details'
import { Box, Button, Container, Theme, Typography, useMediaQuery } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { HEADING_SPACING, HEADING_SPACING_MOBILE } from '../../../constants/spacingConstants'
import { useNavigate, useParams } from 'react-router-dom'

export const CreateContractForm = createContext<ContractFormContextModel | undefined>(undefined)

export const AddContract: React.FunctionComponent = () => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const [formData, setFormData] = useState<ContractFormStateModel>({
    contractRole: {
      selectedContractRole: ContractRole.Enabler,
    },
    primaryContactDetails: {
      firstName: '',
      lastName: '',
      email: '',
      organisationName: '',
    },
    organisationDetails: {
      buyerOrganisationName: '',
      supplierOrganisationName: '',
    },
    contractDetails: {
      contractName: '',
      buyer: '',
      supplier: '',
      term: 0,
      country: '',
      city: '',
      goodsAndServicesCategory: '',
      contractValue: 0,
      contractCurrency: '',
    },
    contractPerformanceDetails: {
      reportingPeriod: '',
      invoicedAmount: 0,
      costToManage: 0,
      totalKPI: 0,
      periodKPI: 0,
      disputes: '',
    },
    contractPersonnelDetails: {
      buyerPersonnel: [],
      supplierPersonnel: [],
    },
  })

  const { page } = useParams()
  const pageNum = page ? Number.parseInt(page) : 1
  const navigate = useNavigate()

  const movePageForward = () => {
    navigate(`/contract/1/add/${pageNum + 1}`)
  }

  const movePageBackward = () => {
    navigate(`/contract/1/add/${pageNum - 1}`)
  }

  const navigateHome = () => {
    navigate(`/`)
  }

  const renderForm = () => {
    switch (pageNum as ContractFormState) {
      case ContractFormState.PrimaryContactDetails:
        return <PrimaryContactDetails onNextPageClick={movePageForward} />
      case ContractFormState.OrganizationDetails:
        return <OrganisationDetails onNextPageClick={movePageForward} />
      case ContractFormState.Details:
        return <Details onNextPageClick={movePageForward} />
      case ContractFormState.PerformanceDetails:
        return <PerformanceDetails onNextPageClick={movePageForward} />
      case ContractFormState.AddPersonnel:
        return <AddPersonnel />
      default:
        return <SelectPrimaryRole onNextPageClick={movePageForward} />
    }
  }

  return (
    <Container maxWidth="xl">
      <Box marginBottom={isMobile ? HEADING_SPACING_MOBILE : HEADING_SPACING}>
        <Button
          sx={{ '& .MuiButton-startIcon': { marginRight: '0px' } }}
          variant="text"
          startIcon={<ArrowBackIosIcon />}
          onClick={pageNum === 1 ? navigateHome : movePageBackward}
        >
          <Typography fontWeight={'bold'}>Back</Typography>
        </Button>
      </Box>
      <CreateContractForm.Provider value={{ formData, setFormData }}>
        {renderForm()}
      </CreateContractForm.Provider>
    </Container>
  )
}
