import { useParams } from 'react-router-dom'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import ContractDashboard from '../components/contract/ContractDashboard'
import AuthenticatedRoute from './AuthenticatedRoute'
import { ReactComponent as BackgroundGraphics } from '../assets/ReportDashboard-Graphics.svg'
import { Theme, useMediaQuery } from '@mui/material'
import theme from '../theme'
import { useEffect } from 'react'
import { reportApi } from '../data/myApis'
import useApi from '../hooks/useApi'
import LoadingContainer from '../components/common/containers/LoadingContainer'
import { ContractType } from '../data/api/models/contract-type'

const ContractDashboardRoute: React.FunctionComponent = () => {
  const params = useParams()
  const contractId = Number(params['contractId'])
  let isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('lg'))
  const { callApi, data, state } = useApi(reportApi.apiReportGetReportPost)

  useEffect(() => {
    callApi({ contractId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSoloContract = data?.contractDto?.contractType === ContractType.NUMBER_1

  return (
    <AuthenticatedRoute>
      <FullscreenContainer
        bgColor={
          isMobileView || isSoloContract
            ? theme.palette.background.gradient
            : theme.palette.common.white
        }
        appBarBgColor={
          isMobileView || isSoloContract
            ? theme.palette.common.white
            : theme.palette.background.grey
        }
        showHeader
        bgGraphics={isMobileView || isSoloContract ? <BackgroundGraphics /> : <></>}
      >
        <LoadingContainer
          isLoading={state === 'loading' || state === 'idle'}
          isErrored={state === 'errored' || !data?.contractCalculations || !data.contractDto}
        >
          <ContractDashboard data={data} />
        </LoadingContainer>
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default ContractDashboardRoute
