import { Container, Box, Theme, useMediaQuery, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { HEADING_SPACING, HEADING_SPACING_MOBILE } from '../../../../constants/spacingConstants'
import Button from '../../../common/inputs/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import useApi from '../../../../hooks/useApi'
import { contractApi } from '../../../../data/myApis'
import React from 'react'
import { CountriesWithCities } from '../data/CountriesWithCitiesArray'
import ContractDetailsForm, { ContractDetailsValues } from './ContractDetailsForm'
import PrimaryRoleForm from './PrimaryRoleForm'
import { ContractRole } from '../data/models'
import Chip from '../../../common/text/Chip'
import { AnonymousSoloAssessmentContext } from '../../../../contexts/AnonymousSoloAssessmentContext'

type AddSingleUserContractProps = {
  unregistered?: boolean
}

export interface SingleUserContractFormState {
  role?: ContractRole
  email?: string
  contractName?: string
  country: keyof typeof CountriesWithCities
  city?: string
  goodsAndServicesCategory?: string
  contractValueRangePerYearId?: number
}

export const AddSingleUserContract: React.FunctionComponent<AddSingleUserContractProps> = ({
  unregistered,
}: AddSingleUserContractProps) => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const { setContractDetails, setCorrelationId } = useContext(AnonymousSoloAssessmentContext)
  const [formData, setFormData] = useState<SingleUserContractFormState>({
    country: 'Australia',
    goodsAndServicesCategory: 'Other Services',
    city: 'Melbourne',
    contractValueRangePerYearId: 1,
  })

  const { callApi, state } = useApi(contractApi.apiContractSingleUserContractPut, (res) =>
    navigate(`/assess/details/solo/${res.contractId}`)
  )

  const { callApi: beginUnregisteredSolo, state: beginUnregisteredSoloState } = useApi(
    contractApi.apiContractBeginUnregisteredSoloPost,
    (res) => {
      navigate('/anonymous/assess/details')
      setCorrelationId(res.correlationId)
    }
  )

  const submitContractRole = (role: ContractRole) => {
    setFormData({ ...formData, role })
    movePageForward()
  }

  const submitContractDetails = (contractDetails: ContractDetailsValues) => {
    const updatedValues = { ...formData, ...contractDetails }
    setFormData(updatedValues)
    if (unregistered) {
      setContractDetails(updatedValues)
      beginUnregisteredSolo({ contractDetails: updatedValues })
      navigate('/anonymous/assess/details')
    } else {
      callApi(updatedValues)
    }
  }

  let [searchParams] = useSearchParams()

  const page = searchParams.get('page')
  const pageNum = Number.parseInt(page ?? '1')
  const navigate = useNavigate()
  const path = `${unregistered ? '/anonymous' : ''}/contract/2/add`

  const movePageForward = () => {
    navigate(`${path}?page=${pageNum + 1}`)
  }

  const movePageBackward = () => {
    navigate(`${path}?page=${pageNum - 1}`)
  }

  const navigateHome = () => {
    navigate(`/`)
  }

  React.useEffect(() => {
    navigate(`${path}?page=1`)
    window.onbeforeunload = () => true

    return () => {
      window.onbeforeunload = null
    }
  }, [navigate, path])

  const renderForm = () => {
    switch (pageNum) {
      case 2:
        return (
          <ContractDetailsForm
            unregistered={unregistered}
            defaultValues={formData}
            onSubmit={submitContractDetails}
            isLoading={state === 'loading' || beginUnregisteredSoloState === 'loading'}
          />
        )
      default:
        return <PrimaryRoleForm onSelectRole={submitContractRole} role={formData.role} />
    }
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        paddingX: '24px',
      }}
    >
      <Box
        marginBottom={isMobile ? HEADING_SPACING_MOBILE : HEADING_SPACING}
        display={'flex'}
        justifyContent={'space-between'}
      >
        {!(unregistered && pageNum === 1) ? (
          <Button
            sx={{ '& .MuiButton-startIcon': { marginRight: '0px' } }}
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            onClick={pageNum === 1 ? navigateHome : movePageBackward}
            fullWidth={false}
          >
            <Typography fontWeight={'bold'}>Back</Typography>
          </Button>
        ) : (
          <Box></Box>
        )}

        <Chip label={`${pageNum} of 2`} />
      </Box>
      {renderForm()}
    </Container>
  )
}
