import { Box, Theme, Tooltip, useMediaQuery } from '@mui/material'
import React from 'react'
import { ContractSummary } from '../../../data/api/models'
import ContractListItem from './ContractListItem'
import SortByDate from '@mui/icons-material/Sort'
import SortByAlpha from '@mui/icons-material/SortByAlpha'
import { HEADING_SPACING } from '../../../constants/spacingConstants'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/inputs/Button'

interface ContractListProps {
  canCreate: boolean
  contracts: ContractSummary[]
  refreshContracts: () => void
}

enum SortOption {
  Newest,
  Oldest,
  Alphabetical,
  ReverseAlphabetical,
}

const ContractList: React.FC<ContractListProps> = ({ canCreate, contracts, refreshContracts }) => {
  const [sortOption, setSortOption] = React.useState(SortOption.Newest)
  const navigate = useNavigate()
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  const confirmSubscription = () => {
    if (!canCreate) {
      navigate('/plans')
    } else {
      navigate('/contract/1/add/1')
    }
  }

  const sortedContracts = React.useMemo(() => {
    switch (sortOption) {
      case SortOption.Newest:
        return contracts.sort((a, b) => (a.contractDto.id > b.contractDto.id ? -1 : 1))
      case SortOption.Oldest:
        return contracts.sort((a, b) => (a.contractDto.id < b.contractDto.id ? -1 : 1))
      case SortOption.Alphabetical:
        return contracts.sort((a, b) =>
          a.contractDto.contractDetails.contractName.localeCompare(
            b.contractDto.contractDetails.contractName
          )
        )
      case SortOption.ReverseAlphabetical:
        return contracts.sort((a, b) =>
          b.contractDto.contractDetails.contractName.localeCompare(
            a.contractDto.contractDetails.contractName
          )
        )
      default:
        return contracts
    }
  }, [contracts, sortOption])

  const toggleSortByDate = () => {
    if (sortOption === SortOption.Newest) {
      setSortOption(SortOption.Oldest)
    } else {
      setSortOption(SortOption.Newest)
    }
  }

  const toggleSortByAlpha = () => {
    if (sortOption === SortOption.Alphabetical) {
      setSortOption(SortOption.ReverseAlphabetical)
    } else {
      setSortOption(SortOption.Alphabetical)
    }
  }
  const withMobileWidthOverride = (isMobile: boolean) =>
    isMobile ? { sx: { borderRadius: 25, paddingX: 4, textTransform: 'none' } } : undefined
  const withBackgroundHighlightWhen = (boolFunc: () => boolean) =>
    boolFunc()
      ? { sx: { background: 'rgba(0, 0, 0, 0.1)', borderRadius: '20%', paddingY: '2px' } }
      : { sx: { borderRadius: '20%', paddingY: '2px' } }
  return (
    <Box my={2}>
      <Box
        mt={HEADING_SPACING}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Button
          onClick={confirmSubscription}
          fullWidth={false}
          {...withMobileWidthOverride(isMobile)}
        >
          Add Contract
        </Button>
        {sortedContracts.length > 1 && (
          <Box display={'flex'} sx={{ flexDirection: 'row-reverse' }}>
            <Tooltip
              title={`Click to sort by ${
                sortOption === SortOption.Alphabetical ? 'Z - A' : 'A - Z'
              }`}
            >
              <Box
                {...withBackgroundHighlightWhen(
                  () =>
                    sortOption === SortOption.Alphabetical ||
                    sortOption === SortOption.ReverseAlphabetical
                )}
              >
                <SortByAlpha
                  onClick={toggleSortByAlpha}
                  cursor={'pointer'}
                  sx={{ marginX: '7px', fontSize: '25px' }}
                />
              </Box>
            </Tooltip>
            <Tooltip
              title={`Click to sort by ${
                sortOption === SortOption.Newest ? 'Oldest - Newest' : 'Newest - Oldest'
              }`}
            >
              <Box
                {...withBackgroundHighlightWhen(
                  () => sortOption === SortOption.Newest || sortOption === SortOption.Oldest
                )}
              >
                <SortByDate
                  onClick={toggleSortByDate}
                  cursor={'pointer'}
                  sx={{ marginX: '7px', fontSize: '25px' }}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
      {sortedContracts.length ? (
        <>
          {sortedContracts.map((c) => (
            <ContractListItem
              contract={c}
              key={c.contractDto.id}
              refreshContracts={refreshContracts}
            />
          ))}
        </>
      ) : undefined}
    </Box>
  )
}

export default ContractList
