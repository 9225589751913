import { Box } from '@mui/material'
import Copyright from './Copyright'
import { Dashboard, DashboardProps } from '../ContractDashboard'
import Logo from '../../common/logos/Logo'

const DashboardView = (props: DashboardProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingTop: '20px',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
          marginLeft: '20px',
          marginBottom: '45px',
        }}
      >
        <Logo width={250} />
      </Box>
      <Dashboard {...props} isReportView />
      <Box ml={2}>
        <Copyright pageNumber={6} pageNumberMargin={'1260px'} />
      </Box>
    </Box>
  )
}

export default DashboardView
