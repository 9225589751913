import { Box, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import signupSchema from './signupSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import TextInput from '../../common/inputs/TextInput'
import LoadingButton from '../../common/inputs/LoadingButton'
import useApi from '../../../hooks/useApi'
import { accountApi } from '../../../data/myApis'
import { TokenResponse } from '../../../data/api/models'
import { setToken } from '../../../utils/authenticationUtils'
import { Container } from '@mui/material'
import LogoWithTitle from '../../common/logos/LogoWithTitle'
import { HEADER_SPACING } from '../../../constants/spacingConstants'
import PasswordInput from '../../common/inputs/PasswordInput'
import { getPathWithRedirectQuery } from '../../../utils/routeUtils'
import { AnonymousSoloAssessmentContext } from '../../../contexts/AnonymousSoloAssessmentContext'
import { useContext, useEffect } from 'react'

interface SignupFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

const Signup: React.FunctionComponent = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormValues>({ resolver: yupResolver(signupSchema) })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')
  const saveAssessment = searchParams.get('saveContract')
  const { contractDetails, answers } = useContext(AnonymousSoloAssessmentContext)

  useEffect(() => {
    if (saveAssessment && (!contractDetails || !answers)) {
      navigate('/anonymous/contract/2/add')
    }
  }, [answers, contractDetails, navigate, saveAssessment])

  const onSuccess = (res: TokenResponse) => {
    setToken(res.token)
    if (redirect) {
      navigate(redirect)
    } else {
      navigate('/')
    }
  }

  var { callApi, state } = useApi(accountApi.apiAccountRegisterPost, onSuccess)
  var { callApi: registerWithContract, state: registerWithContractState } = useApi(
    accountApi.apiAccountRegisterWithContractPost,
    onSuccess
  )

  const onSubmit = (data: SignupFormValues) => {
    if (!!saveAssessment) {
      registerWithContract({
        contractDetails: contractDetails,
        assessmentAnswers: answers,
        registrationDetails: {
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      })
    } else {
      callApi({
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
      })
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        paddingX: '24px',
      }}
    >
      <Box mt={HEADER_SPACING} mb={5} textAlign="center">
        <LogoWithTitle width="65%" height="auto" />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.firstName?.message}
              placeholder="First Name"
              label="First Name"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.lastName?.message}
              placeholder="Last Name"
              label="Last Name"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.email?.message}
              placeholder="your@email.com"
              label="Email Address"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.password?.message}
              placeholder="Password"
              label="Password"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.confirmPassword?.message}
              placeholder="Retype Password"
              label="Confirm Password"
              fullWidth
            />
          )}
        />
        <Box my={3}>
          <LoadingButton
            loading={state === 'loading' || registerWithContractState === 'loading'}
            type="submit"
          >
            Continue
          </LoadingButton>
        </Box>
      </form>
      <Typography textAlign="center">Already have an account?</Typography>
      <Link to={getPathWithRedirectQuery('/login', redirect)} style={{ textDecoration: 'none' }}>
        <Typography textAlign="center" color="primary">
          Login here
        </Typography>
      </Link>
    </Container>
  )
}

export default Signup
