import { Box, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import { ReactElement } from 'react'

interface SubscriptionCardProps {
  productId: number
  name: string
  detailsList: React.ReactNode[]
  price: string
  isSelected: boolean
  onClick: (productId: number) => void
  icon: ReactElement
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  onClick,
  productId,
  name,
  detailsList,
  price,
  icon,
  isSelected,
}) => {
  const theme = useTheme()

  const white = theme.palette.common.white
  const grey = theme.palette.grey[500]
  const primary = theme.palette.primary.main

  return (
    <Card elevation={0} style={{ backgroundColor: isSelected ? primary : white, borderRadius: 12 }}>
      <CardActionArea onClick={() => onClick(productId)}>
        <Stack direction={'row'} p={4} spacing={4}>
          <Box display={'flex'} alignItems={'center'} color={isSelected ? white : grey}>
            {icon}
          </Box>
          <Box>
            <Typography color={isSelected ? white : primary} fontSize={21}>
              <b>{name}</b>
            </Typography>
            {detailsList.map((detail, index) => (
              <div key={index}>
                <Typography color={isSelected ? white : undefined}>{detail}</Typography>
              </div>
            ))}
            <Typography color={isSelected ? white : undefined} mt={1}>
              <b>{price}</b>
            </Typography>
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default SubscriptionCard
