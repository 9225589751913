import {
  Box,
  CircularProgress,
  circularProgressClasses,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

interface DualCircularIndicatorProps {
  outerValue: number
  innerValue: number
  label: string
  innerColor?: string
  outerColor?: string
  scale?: number
  labelStyle?: SxProps<Theme>
}

const DualCircularIndicator: React.FC<DualCircularIndicatorProps> = ({
  outerValue,
  innerValue,
  label,
  innerColor = 'primary.main',
  outerColor = 'secondary.main',
  scale = 1,
  labelStyle,
}) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: (theme) => theme.palette.grey[200],
      }}
      size={80 * scale}
      thickness={4}
      value={100}
    />
    <CircularProgress
      variant="determinate"
      value={outerValue}
      size={80 * scale}
      thickness={4}
      sx={{
        color: outerColor,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
        position: 'absolute',
        left: 0,
      }}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[200],
        }}
        size={60 * scale}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={innerValue}
        size={60 * scale}
        thickness={4}
        sx={{
          color: innerColor,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          position: 'absolute',
        }}
      />
    </Box>
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant={!!labelStyle ? undefined : 'h2'}
        component="div"
        textAlign="center"
        sx={labelStyle}
      >
        {label}
      </Typography>
    </Box>
  </Box>
)

export default DualCircularIndicator
