export const standProductsList = [
  { name: 'Agriculture, Forestry and Fishing' },
  { name: 'Mining' },
  { name: 'Manufacturing' },
  { name: 'Electricity, Gas, Water and Waste Services' },
  { name: 'Construction' },
  { name: 'Wholesale Trade' },
  { name: 'Retail Trade' },
  { name: 'Accommodation and Food Services' },
  { name: 'Transport, Postal and Warehousing' },
  { name: 'Information Media and Telecommunications' },
  { name: 'Financial and Insurance Services' },
  { name: 'Rental, Hiring and Real Estate Services' },
  { name: 'Professional, Scientific and Technical Services' },
  { name: 'Public Administration and Safety' },
  { name: 'Administrative and Support Services' },
  { name: 'Health Care and Social Assistance' },
  { name: 'Education and Training' },
  { name: 'Other Services' },
  { name: 'Arts and Recreation Services' },
]
