import { Box, Typography } from '@mui/material'
import BackgroundImage from '../../../assets/introductionBackground.jpg'
import LogoWithTitle from '../../common/logos/LogoWithTitle'
import theme from '../../../theme'

const Introduction = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'auto 105%',
        width: '100%',
        height: '100%',
        padding: '100px',
      }}
    >
      <LogoWithTitle width={400} fill={theme.palette.background.paper} />
      <Typography
        marginTop={35}
        fontSize={65}
        fontWeight={900}
        lineHeight={1}
        color={theme.palette.background.paper}
      >
        RELATIONAL
        <br />
        IMPACT
        <br />
        REPORT
      </Typography>
    </Box>
  )
}

export default Introduction
