import React from 'react'
import { Container, Paper, Box, Typography } from '@mui/material'

const TermsOfService: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ marginTop: 3, marginBottom: 3, padding: 2 }}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          TERMS OF SERVICE & PRIVACY
        </Typography>

        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1">
            Please read these terms carefully. These terms form a legally binding agreement between
            you (“Customer”) and Contract Harmonics Pty Ltd (ACN 28 647 217 016) (“Contract
            Harmonics”)
          </Typography>

          <Typography variant="body1">
            These terms and conditions govern the terms on which the Customer may access Contract
            Harmonics’ products (“Products”). By accessing this website and the Products you agree
            to abide by these terms.
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>1. Definitions</strong>
              <br />
              <strong>Customer Data:</strong> The information that Customers transmit to the
              Products or create by means of the Products that is of a business or commercial nature
              associated with the organisation using the Products. Customer data includes
              organisational, commercial, contractual, and personal related information. This
              further includes but is not limited to the organisation name, address, contract email
              address and telephone number, contract and/or commercial agreement details, contract
              value, term, personnel names, personnel roles etc.
            </Typography>

            <Typography variant="body1">
              <strong>Aggregate Data</strong>
              <br />
              Contract Harmonics aggregates Customer Data with similar data from public, private
              data sets, including Customer Data from Contract Harmonics customers, and use and
              commercialize the resulting data sets and insights derived from the analysis of the
              resulting data sets, provided that Contract Harmonics: (i) deidentifies the data in
              accordance with applicable regulatory standards, and (ii) removes from the data set
              any information that identifies Customer from the presented Aggregate Data.
            </Typography>

            <Typography variant="body1">
              <strong>Privacy Laws</strong>
              <br />
              a. the Privacy Act 1988 (Cth), the Telecommunications Act 1997 (Cth), the Australian
              Privacy Principles; and
              <br />
              b. any other legislation, principles, industry codes and policies relating to the
              collection, use, disclosure, storage or granting of access rights to Personal
              Information, applicable to a party, or which Contract Harmonics notifies the Customer
              is a Privacy Law for the purposes of this agreement.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>4. Access</strong>
              <br />
              Access is by the registration of the Customer as a user through the Contract Harmonics
              website and application, or by alternative means agreed to by Contract Harmonics,
              which together entitle the Customer to use the Contract Harmonics Products. Access to
              the Products will be by user identity and password selected by the Customer for
              individual users through the Contract Harmonics website registration process. The
              Customer accepts sole responsibility for usage of Products and all charges incurred
              through such registration and issue of the password. The Customer will ensure that
              proper and effective security is provided for the user identity and password so that
              unauthorised or unlawful use of the Products is prevented. The Customer also agrees to
              supervise persons having access to the user identity and the password to ensure that
              the terms and conditions of this Licence are strictly observed. The Customer
              acknowledges that the password, and, if necessary, user identity, can be changed as
              often as is necessary through the Contract Harmonics website to maintain security.
            </Typography>

            <Typography variant="body1">
              <strong>Customer Responsibilities</strong>
              <br />
              The Customer is responsible for providing itself with such computer and other
              facilities as may be required to access the Products and receive information from it,
              and where applicable to provide data to the Products.
            </Typography>

            <Typography variant="body1">
              <strong>Accessibility and Operational Availability</strong>
              <br />
              While Contract Harmonics will use reasonable endeavours to ensure the Products are
              accessible and operational at all times, it does not guarantee this will be the case.
              The Customer acknowledges that the Products rely on services and information from
              third-party suppliers and from time to time, certain services and information may not
              be available to the Products (for example, if there is a technology failure, or
              scheduled downtime, or maintenance issues, or investigations of breaches of security).
              The Customer will have no claim of any kind upon Contract Harmonics for the failure of
              any system or information to be available at any particular time or at all whether
              temporarily or permanently. Contract Harmonics will use reasonable endeavours to
              ensure that where possible, prior notification of scheduled maintenance will be
              provided by Contract Harmonics to the Customer.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>3. Payment</strong>
              <br />
              Payment for the Product is a subscription-based service where the Customer pays by
              credit card. In order to use the Products, you must create a Contract Harmonics
              account, choose a subscription tier and pay the fee specified. Payment of the
              subscription fee will grant you access to the Products. Fees must be paid in advance
              via credit card. Where services are provided by Contract Harmonics without prepayment,
              payment of invoices shall be due and payable within 14 days of the invoice date. Where
              the Customer disputes an invoice then the Customer will pay that invoice, less the
              disputed amount by the due date and the balance shall be paid (as appropriate) once
              the dispute has been resolved. If a dispute cannot be resolved by the parties within
              30 days of the dispute being raised by the customer, Contract Harmonics has the right
              to suspend the Customer account until the dispute is resolved.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>4. Support</strong>
              <br />
              All support is provided on a reasonable endeavours’ basis and without a guaranteed
              resolution time. First-level email support will be available to Customers to assist
              generally with Products use. A response will be provided for the support ticket within
              2 business days of the request being submitted. Should any support issue raised go
              beyond the capacity of first-level email support to resolve, becoming a second-level
              issue, Contract Harmonics will similarly provide support on those issues with a target
              response time of five business days. Support services to be provided by Contract
              Harmonics do not include: a. correction of errors or defects caused by the Customer or
              a third party, including the failure by the Customer or a third party to maintain a
              suitable operating environment or to use the Products in the approved fashion; b.
              diagnosis or rectification of faults not associated with the Products; c. resolution
              of installation or implementation issues, technical architecture issues and other
              customer-specific issues.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>5. Use</strong>
              <br />
              The Customer must access and use the Products only as incidental to its bonafide and
              lawful business being a business where any failure of the Products could not lead to
              death, personal injury, commercial or environmental damage. In particular, without
              limiting the generality of the foregoing, the Customer shall not use any data or
              information from the Products:
              <br />
              a. for resale or provision to any third party
              <br />
              b. in any information storage and retrieval system
              <br />
              c. to data mine or conduct automated searches or attempt to reverse engineer,
              decompile, create derive works from any elements of the Contract Harmonics website,
              the Products or the data accessible through it, nor attempt to create a substitute
              similar service or otherwise disassemble or attempt to extract the source code
              contained in the Products.
              <br />
              The Customer agrees to indemnify Contract Harmonics against any claims arising against
              Contract Harmonics as a result of any unlawful use of the Products by the Customer,
              its servants or agents, or otherwise through use of the Customer’s user identity
              and/or password.
              <br />
              The Customer hereby grants to Contract Harmonics and to its partners and suppliers of
              data an irrevocable, perpetual, worldwide, royalty-free, assignable and sub-licensable
              licence to use any content provided by the Customer for the purposes of improving the
              Products and the quality of the searches carried out through the Products. The
              Customer warrants that any use by Contract Harmonics or others involved in the
              provision of services for the Products does not violate the rights of any third party,
              whether by way of privacy, intellectual property or otherwise.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>6. Free trials</strong>
              <br />
              A Free trial is any Contract Harmonics subscription or functionality that Contract
              Harmonics makes available to Customer to try at Customer’s option, at no additional
              charge, and which is clearly designated as beta, trial, pilot, developer preview, free
              trial, evaluation, or by a similar designation.
              <br />
              If Customer uses a Free trial subscription of the Product, Contract Harmonics will
              make such Free trial available to Customer on a trial basis, free of charge, until the
              earlier of (a) the end of the free trial period for which Customer agreed to use such
              Free trial, (b) the start date of any Product subscription purchased by Customer, or
              (c) termination of the Free trial by Contract Harmonics in its sole discretion. A Free
              trial period may be extended upon mutual agreement by Contract Harmonics and Customer.
              <br />
              Notwithstanding anything to the contrary in this Agreement, a Free trial is provided
              “as is” and with limited: features, functionality, information, and performance.
              Contract Harmonics makes no representation or warranty and shall have no
              indemnification obligations with respect to a free trial. Contract Harmonics shall
              have no liability of any kind with respect to the Free trial. The Customer shall not
              use the free trial in a manner that violates applicable laws and will be fully liable
              for any damages caused by its use of free trial. Any data and information may be
              permanently lost upon termination of the Free trial.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>7. Indemnity (continued)</strong>
              <br />
              The Customer hereby indemnifies and holds harmless Contract Harmonics and its
              directors, officers, employees, agents, licensees and contractors from and against any
              loss, damage, claims, liability, cost or expense (including legal fees) incurred in
              connection with a breach of these terms by the Customer. It is not necessary for
              Contract Harmonics to incur any expense or to make any payment before enforcing a
              right of indemnity conferred by these terms.
              <br />
              Neither party hereto shall be liable for special, incidental, punitive or
              consequential damages arising from the Product, or out of this agreement, and the
              exercise of its rights within, including lost profits arising from or relating to any
              breach of this agreement, regardless of any notice of such damages.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>8. Ownership of Data/Information</strong>
              <br />
              Contract Harmonics Product collects and uses Customer Data and information in
              accordance with Contract Harmonics' Privacy Policy. In order to provide Customers with
              ongoing access to the Product and Customer Data, the Company will store Customer Data
              associated with the Customer’s Contract Harmonics account for as long as the
              Customer’s Contract Harmonics account is active. If the Customer’s Contract Harmonics
              account becomes inactive for 24 months, Contract Harmonics will retire the Customer’s
              account and anonymize the Customer Data associated with the Contract Harmonics
              account. If the Contract Harmonics account is terminated and anonymized, then the
              Customer will lose access to the account and all associated data. Once the Contract
              Harmonics account has been terminated or anonymized, the Customer will be required to
              create a new account and pay the fees stated on the site as per new product
              subscription. At all times, including while the Contract Harmonics account remains
              active or inactive and thereafter, Contract Harmonics reserves the right to retain all
              data generated via the Products in non-personally identifiable format for Contract
              Harmonics research, product development, Aggregate Data and other business and
              commercial purposes.
              <br />
              The Customer acknowledges that as between the Customer and Contract Harmonics, the
              Customer owns and retains ownership and all rights in Customer Data and Contract
              Harmonics may use Customer Data for the purpose of providing the Products and
              exercising its legal rights and remedies in connection with the Agreement.
              <br />
              Customer Data does not include Aggregate Data and the Customer acknowledges that
              Aggregate Data is owned by Contract Harmonics and holds no claim of ownership and
              copyright in that data and information, and the Customer shall respect, observe, and
              not dispute any such rights and claims. These terms do not grant the Customer any
              licence or other right in relation to the intellectual property contained in the
              Products, Aggregate data or information, except as expressly provided.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>9. Termination/Suspension of subscription - Default by Customer</strong>
              <br />
              In addition to any other rights, it may have at law or in equity, Contract Harmonics
              may, without notice, suspend access to the Products and/or terminate this agreement:
              <br />
              1. If the Customer fails to pay when due any sum payable under this agreement;
              <br />
              2. If the Customer fails to perform or observe any of the provisions of this
              agreement;
              <br />
              3. If the Customer provides false or misleading information in regard to this
              agreement;
              <br />
              4. If Contract Harmonics is reasonably of the opinion that the Customer or any other
              person using the Customer’s user identity or password has used, or may use, the
              Products or any information
            </Typography>

            <Typography variant="body1">
              <strong>
                Termination/Suspension of subscription - Default by Customer (continued)
              </strong>
              <br />
              contained therein for any unlawful or improper purpose or in a manner that may
              jeopardise security, contravene any Federal or State law of Australia, or may
              interfere in or impede the proper functioning of the Products,
              <br />
              5. If the operation of the Products or any of Contract Harmonics’ obligations under
              these terms become unlawful.
              <br />
              and in any such case the Customer shall not be entitled to any refund for unused term
              of the subscription service.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>10. Variations</strong>
              <br />
              Contract Harmonics may from time to time change the terms and conditions of this
              licence in its sole discretion, including but not limited to situations where Contract
              Harmonics reasonably considers appropriate to meet legal requirements or restrictions
              or any Code that applies to any participant in the telecommunications industry, the
              laws of any relevant foreign country, or of international law, or to meet the
              reasonable requests or requirements of officials such as the Australian Privacy
              Commissioner or Australian Information Commissioner.
              <br />
              Where notice of such changes is practicable before they take effect, Contract
              Harmonics shall give such notice in writing. Contract Harmonics shall otherwise give
              such notice of the changes as soon as is practicable and shall include up-to-date
              licence information in the on-screen licence information acknowledged by users
              accessing the Products from time to time.
              <br />
              If as a result of any such changes it is necessary for Contract Harmonics to request
              that the Customer execute a new form of agreement, then the Customer agrees to do so
              and return such new agreement to Contract Harmonics within 7 days of such agreement
              being presented for execution.
              <br />
              By accessing this website or the Products after the date of variation the Customer
              acknowledges that they have agreed to the terms as varied. If the changes are such
              that the Customer is materially and adversely affected by them, the Customer must stop
              accessing this website and the Products and may terminate this agreement and shall be
              entitled to a partial refund for any unused subscription months.
              <br />
              Contract Harmonics will not be responsible for any loss sustained including without
              limitation any consequential loss, loss of profits or economic loss associated with
              such variation.
              <br />
              Contract Harmonics shall be entitled from time to time to modify or substitute some or
              all of the databases upon which the Products are based, provided that where a database
              is removed a substitute database is provided that contains information of a type
              similar to that contained in the database whose use has ceased.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>11. Variation of Fees</strong>
              <br />
              Contract Harmonics reserves the right to vary its fees at any time and from time to
              time. In any such case, the Customer shall be entitled to maintain its current
              subscription pricing until it expires and the Customer is to renewing the Product
              subscription.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>12. Data Quality</strong>
              <br />
              The Customer acknowledges that the information contained within the Products is
              obtained from a variety of sources and is provided on an “as is” and “as available”
              basis. Contract Harmonics discloses that the Products may contain omissions and errors
              and none of Contract Harmonics, its related companies, employees and agents, nor the
              data sources to Contract Harmonics of data used in the Products, warrants or
              represents that the Products or information contained therein are complete, accurate
              or free from errors or omissions, nor that they are of any particular quality or
              suitable for any particular purpose. The Customer acknowledges that Contract Harmonics
              is not liable for any losses incurred which may be wholly or partly caused by any
              inaccuracy, omission, defect or error in the Products or information or data contained
              therein.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>13. Withdrawal/Return of Information</strong>
              <br />
              If a customer and/or supplier of data or information to Contract Harmonics requires
              that data or information supplied to the Customer be withdrawn or returned or deleted
              from all records, for example, if confidential information has been inadvertently
              supplied, the Customer shall comply with any such request made by Contract Harmonics
              as soon as reasonably practicable.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>14. Report, Audit</strong>
              <br />
              The Customer acknowledges that Contract Harmonics may be entitled to call for reports
              and/or audit the use of their data by Contract Harmonics, and the Customer consents to
              Contract Harmonics performing its obligations in respect of any such reporting or
              audit process including in relation to use by the Customer of the Products.
              <br />
              Upon reasonable notice to the Customer, Contract Harmonics or its authorised
              representatives, reserves the right to inspect and audit the Customer’s records
              concerning the Products or information or data derived therefrom for the purpose of
              verifying the Customer’s compliance with these terms.
              <br />
              The Customer agrees to Contract Harmonics disclosing such information as may
              reasonably be requested pursuant to law by any government, semi-government or
              statutory authority concerning the provision of data information generally by Contract
              Harmonics and concerning the provision of that information to the Customer
              specifically, if such information is apparently lawfully requested.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>15. Limitations on Liability</strong>
              <br />
              Contract Harmonics shall not be liable for any damages, injury or loss arising or
              resulting, directly or indirectly, from the Customer’s use of the Products, nor for
              any loss or damage arising or resulting directly or indirectly, from any statement
              information or advice made or given, whether negligently or otherwise, in relation to
              the Products or their compilation or production or the use to which any data in them
              is intended to be put.
              <br />
              Contract Harmonics does not exclude or limit the applicability of any statute (such as
              the Australian Competition and Consumer Act 2010) where to do so would contravene that
              statute or cause any part hereof to be void. Subject to that and to the extent
              permitted by law, Contract Harmonics, its related companies and their officers’
              employees and agents exclude all express or implied representations, conditions,
              warranties guarantees that are not included in these terms including those relating to
              an acceptable quality, timeliness or fitness for purpose and will have no liability
              (including liability for negligence) to the Customer for any loss, damage, cost or
              expense (including but not limited to consequential loss, economic loss, lost profits
              or data) incurred or arising by reason of any person relying on the Contract Harmonics
              Products, its functions, data and reports, nor by reason of any error, omission or
              misrepresentation, nor in respect of any difficulties that the Customer may have from
              time to time or at any time in accessing or otherwise using any of the Products, or in
              accessing or securing assistance from any communication or other support service that
              Contract Harmonics provides from time to time. Contract Harmonics’ liability for
              breach of any obligation or for a condition or warranty that by law cannot be excluded
              is limited (at Contract Harmonics’ option) to the supplying of the services again, or
              the payment of the cost of having the services supplied again.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="body1">
              <strong>16. Force Majeure</strong>
              <br />
              Contract Harmonics shall not be liable for any failure to perform its obligations
              under these terms where that performance is delayed, prevented, restricted or
              interfered with for any reason outside Contract Harmonics’ reasonable control.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>17. Website Terms and Conditions</strong>
              <br />
              The Customer will observe the Terms and Conditions that are a pre-requisite to
              entering the Contract Harmonics website from time to time.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>18. Privacy</strong>
              <br />
              The Customer must comply with all Privacy Laws in relation to the Personal
              Information, whether or not the Customer is an organisation or agency bound by the
              Privacy Laws.
              <br />
              The Customer acknowledges that it has not relied on any information from Contract
              Harmonics to the Customer as advice regarding the Customer’s obligations under Privacy
              Laws.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>19. General</strong>
              <br />
              Any provision of these terms that is wholly or partially void or unenforceable is
              severed to the extent that it is void or unenforceable. The validity or enforceability
              of the remainder of these terms is not affected.
              <br />
              A failure to or delay in Contract Harmonics exercising enforcing its rights will not
              constitute a formal waiver. A provision or right under these terms may not be waived
              unless in writing signed by the party granting the waiver.
              <br />
              These terms are governed by the laws of the State of Victoria, in the Commonwealth of
              Australia and the Customer irrevocably and unconditionally submits to the exclusive
              jurisdiction of the courts of Victoria and the Commonwealth of Australia.
              <br />
              In the event that a dispute or claim arises in relation to these terms, Contract
              Harmonics may elect to settle the dispute or claim by arbitration in accordance with
              the Australian Centre for International Commercial Arbitration (ACICA) Arbitration
              Rules at ACICA in Melbourne, Australia.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>20. Trademarks</strong>
              <br />
              Contract Harmonics is a registered trademark of Contract Harmonics Pty Ltd in
              Australia and other countries. The Contract Harmonics logo copyright is owned by
              Contract Harmonics Pty Ltd in Australia.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default TermsOfService
