import { Box, Typography, Grid, useTheme } from '@mui/material'
import FilledLabelChip from '../../common/outputs/FilledLabelChip'
import TrustBaseChart from '../../../assets/TrustBaseChart.png'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

export type TrustBaseInRelationshipProps = {
  trustBase: string
}
const TrustBaseInRelationship = ({ trustBase }: TrustBaseInRelationshipProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>
      <Grid container spacing={7} mt={'0.01px'}>
        <Grid item xs={5} mt={20}>
          <FilledLabelChip
            bgColor={theme.palette.dataBlue.main}
            mx={0}
            fontSize="25px"
            label={trustBase.toLocaleUpperCase()}
            justifyContent="left"
          />
          <Typography fontSize={35} fontWeight={900} lineHeight={1.1} mt={'40px'}>
            TRUST BASE IN THIS RELATIONSHIP.
          </Typography>
          <Typography fontSize={25} mt={6}>
            We evaluate the nature of trust by comparing capabilities of performance and commitment
            in your commercial relationship. Performance is looking at the outcomes, while
            commitment gauges the intensity of effort and level of resources invested into the
            ongoing relationship.
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              backgroundImage: `url(${TrustBaseChart})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'auto 100%',
              width: '100%',
              height: '800px',
            }}
          ></Box>
        </Grid>
      </Grid>
      <Copyright pageNumber={8} />
    </Box>
  )
}

export default TrustBaseInRelationship
