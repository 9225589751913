import styled from '@emotion/styled'
import {
  TableCell,
  tableCellClasses,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Box,
} from '@mui/material'
import { UserContractDto, UserContractRole } from '../../../data/api/models'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React from 'react'
import { contractApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import { useSnackbar } from 'notistack'
import SurveyIcon from '@mui/icons-material/FeedOutlined'
import ClearIcon from '@mui/icons-material/Clear'

interface PersonnelTableRowProps {
  personnel: UserContractDto
  buyerOrganisationName: string
  supplierOrganisationName: string
  refreshContracts: () => void
  allowRemovePersonnel?: boolean
  contractId: number
  isMinimalView: boolean
  isEvenRow: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    border: 0,
    fontWeight: 400,
  },
}))

const PersonnelTableRow: React.FC<PersonnelTableRowProps> = ({
  personnel,
  buyerOrganisationName,
  supplierOrganisationName,
  refreshContracts,
  contractId,
  allowRemovePersonnel = false,
  isMinimalView,
  isEvenRow,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { enqueueSnackbar } = useSnackbar()

  const { callApi: callResendApi, state: resendApiState } = useApi(
    contractApi.apiContractResendInviteTokenPost,
    () =>
      enqueueSnackbar('Successfully sent email', {
        variant: 'success',
      })
  )

  const { callApi: callRemoveUserApi, state: removeUserApiState } = useApi(
    contractApi.apiContractRemoveUserPost,
    () => {
      enqueueSnackbar('Successfully removed user from contract', {
        variant: 'success',
      })

      refreshContracts()
    }
  )

  const requestResendEmail = () => {
    if (resendApiState !== 'loading') callResendApi({ userContractId: personnel.id })
  }

  const removeUser = () => {
    if (removeUserApiState !== 'loading')
      callRemoveUserApi({ userContractId: personnel.id, contractId })
  }

  const isLoading = resendApiState === 'loading' || removeUserApiState === 'loading'
  const withHighlightOnRowOpen = (menuOpen: boolean) =>
    menuOpen ? { background: 'rgba(240, 240, 240, 0.40)' } : {}
  return (
    <>
      <TableRow
        key={personnel.email}
        sx={{
          bgcolor: `${isEvenRow ? '#F9F9F9' : ''}`,
          'td:first-child': {
            paddingLeft: '24px',
          },
          'td:last-child': {
            paddingLast: '24px',
          },
          ...withHighlightOnRowOpen(menuOpen),
        }}
      >
        {!isMinimalView && (
          <StyledTableCell>
            {personnel.firstName ? `${personnel.firstName} ${personnel.lastName}` : '-'}
          </StyledTableCell>
        )}
        <StyledTableCell>{personnel.email ? personnel.email : '-'}</StyledTableCell>
        {!isMinimalView && (
          <>
            <StyledTableCell>
              <Box
                sx={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {personnel.role === UserContractRole.NUMBER_1
                  ? buyerOrganisationName
                  : supplierOrganisationName}
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              {personnel.contractRole ? personnel.contractRole : '-'}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell>
          <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
            {personnel.completedSurvey ? 'Complete' : 'Not Started'}
            <IconButton aria-label="more" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Stack>
        </StyledTableCell>
      </TableRow>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
        <MenuItem
          onClick={removeUser}
          disabled={personnel.completedSurvey || !allowRemovePersonnel || isLoading}
        >
          <ClearIcon sx={{ mr: 1 }} />
          Remove
        </MenuItem>
        <MenuItem onClick={requestResendEmail} disabled={personnel.completedSurvey || isLoading}>
          <SurveyIcon sx={{ mr: 1 }} />
          Assess
        </MenuItem>
      </Menu>
    </>
  )
}

export default PersonnelTableRow
