import { Box, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { HEADER_SPACING } from '../../../constants/spacingConstants'
import SurveyInformation from '../SurveyInformation'
import Button from '../../common/inputs/Button'

interface SurveyDetailsForSoloProps {
  contractId: number
  unregistered?: boolean
}

const SurveyDetailsForSolo: React.FC<SurveyDetailsForSoloProps> = ({
  contractId,
  unregistered,
}) => {
  const navigate = useNavigate()

  return (
    <Box display="flex" boxSizing="border-box" my={2} mt={HEADER_SPACING}>
      <Container maxWidth="sm">
        <SurveyInformation />
        <Box mt={5}>
          <Button
            onClick={() => {
              if (unregistered) {
                navigate(`/anonymous/assess/solo`)
              } else {
                navigate(`/assess/solo/${contractId}`)
              }
            }}
          >
            Start Assessment
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default SurveyDetailsForSolo
