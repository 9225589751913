import { Box, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import loginSchema from './loginSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import TextInput from '../../common/inputs/TextInput'
import LoadingButton from '../../common/inputs/LoadingButton'
import useApi, { MyApiError } from '../../../hooks/useApi'
import { accountApi } from '../../../data/myApis'
import { TokenResponse } from '../../../data/api/models'
import { setToken } from '../../../utils/authenticationUtils'
import { useSnackbar } from 'notistack'
import { Container } from '@mui/material'
import LogoWithTitle from '../../common/logos/LogoWithTitle'
import { HEADER_SPACING } from '../../../constants/spacingConstants'
import PasswordInput from '../../common/inputs/PasswordInput'
import { getPathWithRedirectQuery } from '../../../utils/routeUtils'
import UserContext from '../../../contexts/UserContext'
import { useContext } from 'react'

interface LoginFormValues {
  email: string
  password: string
}

const Login: React.FunctionComponent = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({ resolver: yupResolver(loginSchema) })
  const [searchParams] = useSearchParams()
  const userContext = useContext(UserContext)
  const redirect = searchParams.get('redirect')
  const navigate = useNavigate()
  const onSubmit = (data: LoginFormValues) =>
    callApi({ email: data.email, password: data.password })

  const { enqueueSnackbar } = useSnackbar()

  const onFailure = (error: MyApiError) => {
    if (error.statusCode === 401) enqueueSnackbar('Invalid email or password', { variant: 'error' })
  }

  const onSuccess = (res: TokenResponse) => {
    setToken(res.token)
    userContext.refreshUser()
    if (redirect) {
      navigate(redirect)
    } else {
      navigate('/')
    }
  }

  const { callApi, state } = useApi(accountApi.apiAccountLoginPost, onSuccess, onFailure)

  return (
    <Container
      maxWidth="xs"
      sx={{
        paddingX: '24px',
      }}
    >
      <Box mt={HEADER_SPACING} mb={10} textAlign="center">
        <LogoWithTitle width="80%" height="auto" />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.email?.message}
              placeholder="your@email.com"
              label="Email Address"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.password?.message}
              placeholder="Password"
              label="Password"
              fullWidth
            />
          )}
        />
        <Box my={3} display={'flex'} gap={1} flexDirection={'column'}>
          <LoadingButton loading={state === 'loading'} type="submit">
            Sign In
          </LoadingButton>
          <LoadingButton onClick={() => navigate('/anonymous/contract/2/add')} color="dark">
            Try for Free
          </LoadingButton>
        </Box>
      </form>
      <Typography textAlign="center">Don't have an account?</Typography>
      <Link to={getPathWithRedirectQuery('/signup', redirect)} style={{ textDecoration: 'none' }}>
        <Typography textAlign="center" color="primary">
          Create one now
        </Typography>
      </Link>
    </Container>
  )
}

export default Login
