import { PaletteOptions } from '@mui/material'

const colours: PaletteOptions = {
  primary: {
    main: '#CD1719',
    light: '#D94F4A',
  },
  secondary: {
    main: '#0085FF',
    dark: '#005DB2',
  },
  white: {
    main: '#ffffff',
    light: '#ffffff',
    dark: '#e6e7e8',
    contrastText: '#000000',
  },
  dark: {
    main: '#000',
    light: '#fff',
    dark: '#000',
    contrastText: '#fff',
  },
  background: {
    default: '#F2F9F7',
    grey: '#F8F8F8',
    gradient:
      'linear-gradient(360deg, rgba(60, 152, 127, 0.6) 0%, rgba(60, 152, 127, 0.01) 62.42%)',
  },
  dataBlue: {
    main: '#3781ad',
    light: '#98bdd3',
  },
  dataYellow: {
    main: '#ffd33a',
    light: '#fff6dc',
  },
  tonalOffset: 0.3,
  grey: {
    400: '#f9f9f9',
    300: '#e6e7e8',
  },
}

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary']
    dark: Palette['primary']
    dataBlue: Palette['primary']
    dataYellow: Palette['primary']
  }
  interface PaletteOptions {
    white: Palette['primary']
    dark: Palette['primary']
    dataBlue: PaletteOptions['primary']
    dataYellow: PaletteOptions['primary']
  }
  interface TypeBackground {
    default: string
    paper: string
    grey: string
    gradient: string
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
    dark: true
  }
}

export default colours
