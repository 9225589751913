import { useParams } from 'react-router-dom'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import theme from '../theme'
import SurveyDetailsForSolo from '../components/surveyDetails/surveyDetailsForSolo'

interface SurveyDetailsForSoloRouteProps {
  unregistered?: boolean
}

const SurveyDetailsForSoloRoute: React.FunctionComponent<SurveyDetailsForSoloRouteProps> = ({
  unregistered,
}: SurveyDetailsForSoloRouteProps) => {
  const params = useParams()

  const contractId = params.contractId as string

  return (
    <FullscreenContainer showHeader bgColor={theme.palette.grey[300]}>
      <SurveyDetailsForSolo contractId={Number(contractId)} unregistered={unregistered} />
    </FullscreenContainer>
  )
}

export default SurveyDetailsForSoloRoute
