import { Box, Grid, Typography } from '@mui/material'
import LogoWithTitle from '../../common/logos/LogoWithTitle'
import BackgroundImage from '../../../assets/ServicesBackground.png'
import Copyright from './Copyright'

const Services = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'auto 78%',
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <LogoWithTitle width={350} />
      <Typography fontSize={45} fontWeight={900} mt={'150px'}>
        OUR SERVICES
      </Typography>
      <Grid container spacing={5} mt={'0.01px'}>
        {services.map((x) => (
          <Grid item xs={4} key={x.heading}>
            <ServiceDetail {...x} />
          </Grid>
        ))}
      </Grid>
      <Typography fontSize={25} fontWeight={900} mt={33}>
        Contact us
      </Typography>
      <Typography fontSize={25}>services@contract-harmonics.com</Typography>
      <Copyright pageNumber={14} />
    </Box>
  )
}

export type ServicesProps = {
  heading: string
  description: string
}

const services: ServicesProps[] = [
  {
    heading: 'TRAINING',
    description:
      'We run training programs that help organisations and practitioners develop understanding and proficiency in relational contracting. Our training courses can be tailored to meet your needs and presented either in-person or online, depending on the scale and nature of training required.',
  },
  {
    heading: 'RELATIONAL ASSESSMENTS',
    description:
      'We will liaise with you to ensure everything is done perfectly, from gathering contract performance data to organising assessments for contract personnel. We will also provide relational impact profile reports that include a SWOT analysis from our experienced relational contracting experts.',
  },
  {
    heading: 'TRANSFORMATION',
    description:
      'We help organisations adopt relational contracting practices. We use a collaborative workshop approach to assess, plan, prepare and implement the adoption of relational contracting practices into existing and future projects and agreements.',
  },
]

const ServiceDetail = ({ heading, description }: ServicesProps) => {
  return (
    <>
      <Typography fontSize={30} fontWeight={900}>
        {heading}
      </Typography>
      <Typography mt={1} fontSize={20}>
        {description}
      </Typography>
    </>
  )
}

export default Services
