import { yupResolver } from '@hookform/resolvers/yup'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import TextInput from '../../common/inputs/TextInput'
import { CreateContractForm } from '.'
import { ContractFormContextModel } from './data/models'
import Grid2 from '@mui/material/Unstable_Grid2'
import Button from '../../common/inputs/Button'
import FormHeader from './FormHeader'

interface OrganisationDetailsValues {
  buyerOrganisationName: string
  supplierOrganisationName: string
  enablerOrganisationName: string
}

const OrganisationDetailsSchema = yup.object().shape({
  buyerOrganisationName: yup
    .string()
    .max(40, 'Buyer organisation name must be 40 characters or fewer')
    .label('Buyer Organisation Name'),
  supplierOrganisationName: yup
    .string()
    .max(40, 'Supplier organisation name must be 40 characters or fewer')
    .label('Supplier Organisation Name'),
  enablerOrganisationName: yup
    .string()
    .max(40, 'Enabler organisation name must be 40 characters or fewer')
    .label('Enabler Organisation Name'),
})

interface OrganisationDetailsProps {
  onNextPageClick: () => void
}

const OrganisationDetails: React.FunctionComponent<OrganisationDetailsProps> = ({
  onNextPageClick,
}) => {
  const context = useContext<ContractFormContextModel | undefined>(CreateContractForm!)
  const { formData, setFormData } = context!

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganisationDetailsValues>({
    resolver: yupResolver(OrganisationDetailsSchema),
    defaultValues: {
      ...formData.organisationDetails,
    },
  })

  const onSubmit = (data: OrganisationDetailsValues) => {
    setFormData({
      ...formData,
      organisationDetails: data,
    })
    onNextPageClick()
  }

  return (
    <Grid2 container>
      <Grid2 xs={12} lg={3}>
        <FormHeader
          title="Buyer & Supplier"
          mobileTitle="Add Contract"
          mobileSubTitle="Buyer and supplier"
          details="Add buyer and supplier organisation names"
        />
      </Grid2>
      <Grid2 xs={12} lg={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="buyerOrganisationName"
            render={({ field }) => (
              <TextInput
                {...field}
                errorMessage={errors.buyerOrganisationName?.message}
                placeholder="Buyer Organisation Name"
                label="Buyer Organisation Name"
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="supplierOrganisationName"
            render={({ field }) => (
              <TextInput
                {...field}
                errorMessage={errors.supplierOrganisationName?.message}
                placeholder="Supplier Organisation Name"
                label="Supplier Organisation Name"
                fullWidth
              />
            )}
          />
          <Button type="submit">Add Buyer And Supplier</Button>
        </form>
      </Grid2>
    </Grid2>
  )
}

export default OrganisationDetails
