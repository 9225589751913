import { Box, Button, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { HEADING_SPACING } from '../../../constants/spacingConstants'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ChangePasswordForm from './ChangePasswordForm'

const ChangePassword: React.FunctionComponent = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <Container maxWidth="lg">
        <Box marginBottom={HEADING_SPACING} width={1}>
          <Button variant="text" startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
      </Container>
      <Container maxWidth="sm">
        <Typography variant="h1" pb={6}>
          Update Password
        </Typography>
        <ChangePasswordForm />
      </Container>
    </Box>
  )
}

export default ChangePassword
