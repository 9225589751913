import {
  Box,
  Container,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  Button as MuiButton,
} from '@mui/material'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import { ContractReportNormDto, ContractSummary } from '../../../../data/api/models'
import SectionHeader from '../SectionHeader'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PerceivedTrust from './PerceivedTrust'
import TrustBase from './TrustBase'
import RelationalIndex from './RelationalIndex'
import RelationalProfile from './RelationalProfile'
import RelationalNormIntensityLevels from './RelationalNormIntensityLevels'
import RelationalNorms from './RelationalNorms'
import theme from '../../../../theme'
import { generatePdf } from '../PdfUtils'
import Button from '../../../common/inputs/Button'

interface SingleUserDashboardProps {
  contract: ContractSummary
  unregistered?: boolean
}

const SingleUserDashboard: React.FC<SingleUserDashboardProps> = ({ contract, unregistered }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  return (
    <>
      <Container maxWidth="md">
        <Box mx={2} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} my={2}>
          <Box>
            <Button
              sx={{ '& .MuiButton-startIcon': { marginRight: '0px' } }}
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => navigate('/')}
            >
              <Typography color="text.primary" fontWeight={'700'} fontSize={15}>
                My Contracts
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} marginBottom={1}>
          <SectionHeader groupName={contract?.contractDto.contractDetails.contractName} isSolo />
          {isMobile && (
            <Tooltip title="Download your PDF report" arrow>
              <MuiButton
                variant="text"
                endIcon={<PdfIcon />}
                color="info"
                onClick={() => generatePdf('portrait')}
              >
                {isMobile ? '' : 'Report'}
              </MuiButton>
            </Tooltip>
          )}
        </Box>
        <Stack direction={'column'} spacing={1.5} mb={12}>
          <PerceivedTrust trust={contract?.contractCalculations.coreTrust} />
          <TrustBase trustBase={contract?.contractCalculations.trustBase} />
          <RelationalIndex relationalIndex={contract?.contractCalculations.relationalIndex} />
          <RelationalProfile
            metricIconsProps={{
              color: 'dataBlue',
              metrics: contract?.contractCalculations.contractNorms.map(
                (n: ContractReportNormDto) => ({
                  metric: n.metric,
                  percentage: n.combinedValue,
                })
              ),
            }}
          />
          <RelationalNormIntensityLevels />
          <RelationalNorms />
        </Stack>
      </Container>
      <Box
        textAlign={'center'}
        position={'fixed'}
        bottom={0}
        left={0}
        py={1}
        width={1}
        bgcolor={theme.palette.common.white}
      >
        <Typography variant="h3" pb={0.5}>
          <b>Want a complete team assessment?</b>
        </Typography>
        <Button
          fullWidth={false}
          onClick={() => navigate(unregistered ? '/signup?saveContract=1' : '/plans?plan=1')}
        >
          {unregistered ? 'Register and save Assessment' : 'Upgrade'}
        </Button>
      </Box>
    </>
  )
}

export default SingleUserDashboard
