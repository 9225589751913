import { Box, Container, Typography } from '@mui/material'
import { HEADER_SPACING } from '../../../constants/spacingConstants'
import ResetPasswordForm from './ResetPasswordForm'
import LogoWithTitle from '../../common/logos/LogoWithTitle'

interface ResetPasswordProps {
  token: string
  userId: string
}

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({ token, userId }) => (
  <Box>
    <Container maxWidth="xs">
      <Box mt={HEADER_SPACING} mb={5} textAlign="center">
        <LogoWithTitle width="65%" height="auto" />
      </Box>
      <Typography variant="h1" pb={6}>
        Update Password
      </Typography>
      <ResetPasswordForm token={token} userId={userId} />
    </Container>
  </Box>
)

export default ResetPassword
