import { Box, Container, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { HEADER_SPACING, LOGO_SPACING_MOBILE } from '../../../constants/spacingConstants'
import { RequestPaymentResponse } from '../../../data/api/models'
import { contractApi, paymentApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import LoadingButton from '../../common/inputs/LoadingButton'
import LogoWithTitle from '../../common/logos/LogoWithTitle'
import SubscriptionCard from './SubscriptionCard'
import { ReactComponent as PractitionerIcon } from '../../../assets/Icon-Practitioner.svg'
import { ReactComponent as SoloIcon } from '../../../assets/Icon-Solo.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoadingContainer from '../../common/containers/LoadingContainer'

const SelectPlan: React.FC = () => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const { callApi, data, state } = useApi(contractApi.apiContractContractsAvailabilityGet)
  const navigate = useNavigate()
  const scrollRef = useRef(null)

  const [searchParams] = useSearchParams()

  const preselectedPlan = Number(searchParams.get('plan'))

  const [selectedProductId, setSelectedProductId] = React.useState<number | null>(preselectedPlan)

  useEffect(() => {
    callApi()
    // eslint-disable-next-line
  }, [])

  const handleSuccess = (response: RequestPaymentResponse) => {
    window.location.href = response.paymentUrl
  }

  const { state: confirmPaymentState, callApi: callPaymentApi } = useApi(
    () => paymentApi.apiPaymentRequestPaymentProductIdGet(selectedProductId || 0),
    handleSuccess
  )

  const confirmProduct = () => {
    switch (selectedProductId) {
      case 1:
      case 20:
        if (!data?.contractCreationAvailability.canCreate) {
          callPaymentApi()
        } else {
          navigate('/contract/1/add/1')
        }
        break
      case 3:
        navigate('/contract/2/add?pageNum=1')
        break
    }
  }

  const handleProductIdChange = (productId: number) => {
    ;(scrollRef.current as any).scrollIntoView({ block: 'end', behavior: 'smooth' })
    setSelectedProductId((x) => (x === productId ? null : productId))
  }

  return (
    <LoadingContainer
      isLoading={state === 'loading' || state === 'idle'}
      isErrored={state === 'errored'}
    >
      <Container
        maxWidth="sm"
        sx={{
          paddingX: '24px',
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            paddingX: '0px',
          }}
        >
          <Box
            mt={isMobile ? LOGO_SPACING_MOBILE : HEADER_SPACING}
            mb={isMobile ? 5 : 12}
            textAlign="center"
          >
            <LogoWithTitle width="75%" height="auto" />
          </Box>
        </Container>
        <Typography variant="h6">
          <b>Select a Plan</b>
        </Typography>
        <Stack my={3} spacing={2}>
          <SubscriptionCard
            onClick={handleProductIdChange}
            name="Solo Assessment"
            detailsList={[
              '1 Contract self-assessment',
              <Typography component="span">
                Basic report (
                <Typography component="span" fontWeight="bold" display="inline">
                  without
                </Typography>{' '}
                analysis)
              </Typography>,
            ]}
            price="FREE"
            productId={3}
            isSelected={selectedProductId === 3}
            icon={<SoloIcon />}
          />
          <SubscriptionCard
            onClick={handleProductIdChange}
            name="Practitioner Plan"
            detailsList={[
              '3 assessments',
              <Typography component="span">Standard report</Typography>,
            ]}
            price="$2,200"
            productId={1}
            isSelected={selectedProductId === 1}
            icon={<PractitionerIcon />}
          />
          <SubscriptionCard
            onClick={handleProductIdChange}
            name="Professional plan"
            detailsList={[
              '25 assessments',
              <Typography component="span">Standard report</Typography>,
            ]}
            price="$9,900"
            productId={20}
            isSelected={selectedProductId === 20}
            icon={<PractitionerIcon />}
          />
        </Stack>
        <Box display={'flex'} justifyContent={'center'} mb={3} mt={7}>
          <LoadingButton
            loading={confirmPaymentState === 'loading'}
            fullWidth={false}
            disabled={!selectedProductId}
            onClick={confirmProduct}
          >
            Continue
          </LoadingButton>
        </Box>
      </Container>
      <div ref={scrollRef} />
    </LoadingContainer>
  )
}

export default SelectPlan
