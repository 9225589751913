import { Box, Tooltip } from '@mui/material'
import { PropsWithChildren } from 'react'
interface HoverMessageProps {
  message: string
}

const HoverMessage: React.FC<PropsWithChildren<HoverMessageProps>> = ({ children, message }) => {
  return (
    <Tooltip followCursor placement="top" title={message}>
      <Box>{children}</Box>
    </Tooltip>
  )
}

export default HoverMessage
