import { ReactComponent as Agility } from '../../../assets/Agility.svg'
import { ReactComponent as AgilityLine } from '../../../assets/Agility-line.svg'
import { ReactComponent as Capability } from '../../../assets/Capability.svg'
import { ReactComponent as CapabilityLine } from '../../../assets/Capability-line.svg'
import { ReactComponent as Equality } from '../../../assets/Equality.svg'
import { ReactComponent as EqualityLine } from '../../../assets/Equality-line.svg'
import { ReactComponent as Harmony } from '../../../assets/Harmony.svg'
import { ReactComponent as HarmonyLine } from '../../../assets/Harmony-line.svg'
import { ReactComponent as Integrity } from '../../../assets/Integrity.svg'
import { ReactComponent as IntegrityLine } from '../../../assets/Integrity-line.svg'
import { ReactComponent as Solidarity } from '../../../assets/Solidarity.svg'
import { ReactComponent as SolidarityLine } from '../../../assets/Solidarity-line.svg'

export type MetricType =
  | 'Integrity'
  | 'Solidarity'
  | 'Equality'
  | 'Commitment'
  | 'Agility'
  | 'Harmony'

interface MetricIconProps {
  metric: MetricType
  fill?: string
  stroke?: string
  filled: boolean
  width?: number
  height?: number
  color: string
}

const MetricIcon: React.FC<MetricIconProps> = ({ filled, metric, ...rest }) => {
  switch (metric) {
    case 'Agility':
      return filled ? <Agility {...rest} /> : <AgilityLine {...rest} />
    case 'Commitment':
      return filled ? <Capability {...rest} /> : <CapabilityLine {...rest} />
    case 'Equality':
      return filled ? <Equality {...rest} /> : <EqualityLine {...rest} />
    case 'Harmony':
      return filled ? <Harmony {...rest} /> : <HarmonyLine {...rest} />
    case 'Integrity':
      return filled ? <Integrity {...rest} /> : <IntegrityLine {...rest} />
    case 'Solidarity':
      return filled ? <Solidarity {...rest} /> : <SolidarityLine {...rest} />
  }
}

export default MetricIcon
