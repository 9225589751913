import ChangePassword from '../components/account/changePassword'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import AuthenticatedRoute from './AuthenticatedRoute'

const ChangePasswordRoute: React.FunctionComponent = () => {
  return (
    <AuthenticatedRoute>
      <FullscreenContainer showHeader>
        <ChangePassword />
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default ChangePasswordRoute
