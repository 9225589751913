import {
  AccountApiFactory,
  SurveyApiFactory,
  ContractApiFactory,
  ReportApiFactory,
  PaymentApiFactory,
} from './api'
import { myAxiosInstance } from './AxiosInterceptors'

const baseUrl = window._env_.API_URL

export const accountApi = AccountApiFactory(undefined, baseUrl, myAxiosInstance)
export const surveyApi = SurveyApiFactory(undefined, baseUrl, myAxiosInstance)
export const contractApi = ContractApiFactory(undefined, baseUrl, myAxiosInstance)
export const reportApi = ReportApiFactory(undefined, baseUrl, myAxiosInstance)
export const paymentApi = PaymentApiFactory(undefined, baseUrl, myAxiosInstance)
