import { Theme, Typography, useMediaQuery } from '@mui/material'

interface FormHeaderProps {
  title: string
  details?: string | React.ReactNode
  mobileTitle: string
  mobileSubTitle: string
}

const FormHeader: React.FC<FormHeaderProps> = ({ title, details, mobileTitle, mobileSubTitle }) => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  return isMobile ? (
    <>
      <Typography variant="h1" pb={6}>
        {mobileTitle}
      </Typography>
      <Typography variant="h3" pb={2}>
        {mobileSubTitle}
      </Typography>
      <Typography variant="body1" color="GrayText" pb={2}>
        {details}
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h1">{title}</Typography>
      <Typography variant="body1" color="GrayText" pt={2} pr={5}>
        {details}
      </Typography>
    </>
  )
}

export default FormHeader
