import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import MyContracts from '../components/contract/MyContracts'
import AuthenticatedRoute from './AuthenticatedRoute'
import { ReactComponent as BackgroundGraphics } from '../assets/MyContract-Graphics.svg'

const MyContractsRoute: React.FunctionComponent = () => {
  return (
    <AuthenticatedRoute>
      <FullscreenContainer showHeader bgGraphics={<BackgroundGraphics />}>
        <MyContracts />
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default MyContractsRoute
