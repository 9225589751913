import { yupResolver } from '@hookform/resolvers/yup'
import { Icon, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import TextInput from '../../common/inputs/TextInput'
import { CreateContractForm } from '.'
import { ContractFormContextModel } from './data/models'
import { contractApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import { useNavigate } from 'react-router-dom'
import Grid2 from '@mui/material/Unstable_Grid2'
import Button from '../../common/inputs/Button'
import AddIcon from '@mui/icons-material/Add'
import LoadingButton from '../../common/inputs/LoadingButton'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { Box } from '@mui/system'
import FormHeader from './FormHeader'

interface ContractPersonnelValues {
  buyerPersonnel: { email: string }[]
  supplierPersonnel: { email: string }[]
}

const arrayShape = {
  email: yup.string().email('Please enter a valid email address').required().label('Email'),
}

const PersonnelSchema = yup.object({
  buyerPersonnel: yup.array().of(yup.object().shape(arrayShape)),
  supplierPersonnel: yup.array().of(yup.object().shape(arrayShape)),
})

const AddPersonnel: React.FunctionComponent = () => {
  const context = useContext<ContractFormContextModel | undefined>(CreateContractForm!)
  const { formData, setFormData } = context!
  const navigate = useNavigate()

  var { callApi, state } = useApi(contractApi.apiContractContractPost, () => {
    navigate('/')
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ContractPersonnelValues>({
    resolver: yupResolver(PersonnelSchema),
    defaultValues: {
      buyerPersonnel: [{ email: '' }],
      supplierPersonnel: [{ email: '' }],
    },
  })

  const {
    fields: buyerFields,
    append: buyerAppend,
    remove: buyerRemove,
  } = useFieldArray<ContractPersonnelValues>({
    control,
    name: 'buyerPersonnel',
  })

  const {
    fields: supplierFields,
    append: supplierAppend,
    remove: supplierRemove,
  } = useFieldArray<ContractPersonnelValues>({
    control,
    name: 'supplierPersonnel',
  })

  const hasMoreThanOnePersonnel = React.useMemo(
    () => buyerFields.length + supplierFields.length > 1,
    [buyerFields, supplierFields]
  )

  const onSubmit = (data: ContractPersonnelValues) => {
    setFormData({
      ...formData,
      contractPersonnelDetails: data,
    })

    callApi({
      contractRole: formData.contractRole,
      primaryContactDetails: formData.primaryContactDetails,
      organisationDetails: formData.organisationDetails,
      contractDetails: formData.contractDetails,
      contractPerformanceDetails: formData.contractPerformanceDetails,
      contractPersonnelDetails: data,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={4}>
        <Grid2 md={3} xs={12}>
          <FormHeader
            title="Add Personnel"
            mobileTitle="Add Contract"
            mobileSubTitle="Add personnel"
            details={
              <>
                Add the emails of personnel with leadership roles that are assigned to manage the
                contract. These personnel will be requested to undertake the relational assessment
                to help identify the relational qualities of this contract.
                <br />
                <br />
                <Icon fontSize={'inherit'}>
                  <DeleteIcon fontSize={'inherit'} />
                </Icon>{' '}
                - To remove personnel email fields, use the delete icon.
              </>
            }
          />
        </Grid2>
        <Grid2 md={4} xs={12}>
          <Typography textAlign="left" variant="h3">
            Buyer Personnel
          </Typography>
          {buyerFields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`buyerPersonnel.${index}.email`}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder="Email"
                  label="Personnel Email"
                  fullWidth
                  errorMessage={errors.buyerPersonnel?.[index]?.email?.message}
                  endAdornment={
                    hasMoreThanOnePersonnel && (
                      <IconButton onClick={() => buyerRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                />
              )}
            />
          ))}
          <Box mt={5}>
            <Button
              onClick={() => buyerAppend({ email: '' })}
              fullWidth={false}
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add Buyer
            </Button>
          </Box>
        </Grid2>
        <Grid2 md={4} xs={12}>
          <Typography textAlign="left" variant="h3">
            Supplier Personnel
          </Typography>
          {supplierFields.map((_, index) => (
            <Controller
              key={index}
              control={control}
              name={`supplierPersonnel.${index}.email`}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder="Email"
                  label="Personnel Email"
                  fullWidth
                  errorMessage={errors.supplierPersonnel?.[index]?.email?.message}
                  endAdornment={
                    hasMoreThanOnePersonnel && (
                      <IconButton onClick={() => supplierRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                />
              )}
            />
          ))}
          <Box mt={5}>
            <Button
              onClick={() => supplierAppend({ email: '' })}
              fullWidth={false}
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add Supplier
            </Button>
          </Box>
        </Grid2>
        <Grid2 xs={12} md={11} display="flex" justifyContent="right">
          <LoadingButton type="submit" fullWidth={false} loading={state === 'loading'}>
            Send Email Invites
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  )
}

export default AddPersonnel
