import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '../../common/inputs/LoadingButton'
import { Box } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { accountApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import TextInput from '../../common/inputs/TextInput'
import { useSnackbar } from 'notistack'
import editAccountSchema from './editAccountSchema'
import userContext from '../../../contexts/UserContext'
import { useContext } from 'react'

interface EditAccountFormValues {
  firstName: string
  lastName: string
  email: string
}

interface EditAccountFormProps {
  initialValues: EditAccountFormValues
}

const EditAccountForm: React.FC<EditAccountFormProps> = ({ initialValues }) => {
  const { refreshUser } = useContext(userContext)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditAccountFormValues>({
    resolver: yupResolver(editAccountSchema),
    defaultValues: initialValues,
  })

  const onSuccess = () => {
    enqueueSnackbar('Account updated successfully', { variant: 'success' })
    refreshUser()
  }

  const { callApi, state } = useApi(accountApi.apiAccountEditAccountPost, onSuccess)

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data: EditAccountFormValues) =>
    callApi({ email: data.email, firstName: data.firstName, lastName: data.lastName })

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.email?.message}
              placeholder="your@email.com"
              label="Email Address"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.firstName?.message}
              placeholder="First Name"
              label="First Name"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.lastName?.message}
              placeholder="Last Name"
              label="Last Name"
              fullWidth
            />
          )}
        />
        <Box mt={3}>
          <LoadingButton loading={state === 'loading'} type="submit">
            Update
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

export default EditAccountForm
