import {
  ContractModelsContractRole,
  ContractModelsPrimaryContactDetails,
  ContractModelsOrganisationDetails,
  ContractModelsContractDetails,
  ContractModelsContractPerformanceDetails,
  ContractModelsContractPersonnelDetails,
} from '../../../../data/api/models'

export enum ContractRole {
  Buyer = 1,
  Supplier,
  Enabler,
}

export type ContractFormContextModel = {
  formData: ContractFormStateModel
  setFormData: React.Dispatch<React.SetStateAction<ContractFormStateModel>>
}

export enum ContractFormState {
  Role = 1,
  PrimaryContactDetails,
  OrganizationDetails,
  Details,
  PerformanceDetails,
  AddPersonnel,
}

export type ContractFormStateModel = {
  contractRole: ContractModelsContractRole
  primaryContactDetails: ContractModelsPrimaryContactDetails
  organisationDetails: ContractModelsOrganisationDetails
  contractDetails: ContractModelsContractDetails
  contractPerformanceDetails: ContractModelsContractPerformanceDetails
  contractPersonnelDetails: ContractModelsContractPersonnelDetails
}
