import { Box, Typography, useTheme } from '@mui/material'

interface ChipProps {
  label: string
}

const Chip: React.FC<ChipProps> = ({ label }) => {
  const theme = useTheme()

  return (
    <Box ml={1} borderRadius={25} py={1} px={1.5} bgcolor={theme.palette.common.white}>
      <Typography variant="subtitle1">
        <b>{label}</b>
      </Typography>
    </Box>
  )
}

export default Chip
