import { yupResolver } from '@hookform/resolvers/yup'
import { MenuItem, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { HEADING_SPACING, LINEBREAK_SPACING } from '../../constants/spacingConstants'
import { surveyApi } from '../../data/myApis'
import useApi from '../../hooks/useApi'
import LoadingButton from '../common/inputs/LoadingButton'
import SelectInput from '../common/inputs/SelectInput'
import TextInput from '../common/inputs/TextInput'
import surveyDetailsSchema from './surveyDetailsFormSchema'

interface SurveyDetailsFormProps {
  inviteToken: string
  contractName: string
  organisationName: string
}

interface SurveyDetailsFormValues {
  firstName: string
  lastName: string
  role: number
  yearsInRole: number
}

const surveyRoleOptions = ['Executive', 'Manager', 'Lead']

const SurveyDetailsForm: React.FC<SurveyDetailsFormProps> = ({
  contractName,
  inviteToken,
  organisationName,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SurveyDetailsFormValues>({
    resolver: yupResolver(surveyDetailsSchema),
  })

  const navigate = useNavigate()

  const { callApi, state } = useApi(surveyApi.apiSurveySubmitSurveyDetailsPost, () =>
    navigate(`/assess/${inviteToken}`)
  )

  const onSubmit = ({ firstName, lastName, role, yearsInRole }: SurveyDetailsFormValues) =>
    callApi({ firstName, lastName, role, yearsInRole, inviteToken })

  return (
    <>
      <Typography variant="h1" mb={HEADING_SPACING}>
        Your Details
      </Typography>
      <Typography variant="body1" mb={LINEBREAK_SPACING}>
        {contractName}
      </Typography>
      <Typography variant="body1">{organisationName}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <TextInput
              {...field}
              fullWidth
              errorMessage={errors.firstName?.message}
              label="First Name"
              placeholder="First Name"
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <TextInput
              {...field}
              fullWidth
              errorMessage={errors.lastName?.message}
              label="Surname"
              placeholder="Surname"
            />
          )}
        />
        <Controller
          control={control}
          name="role"
          render={({ field }) => (
            <SelectInput
              label="Role"
              {...field}
              inputProps={{
                name: 'Role',
                id: 'role',
              }}
              fullWidth
              errorMessage={errors.role?.message}
            >
              {surveyRoleOptions.map((value) => {
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                )
              })}
            </SelectInput>
          )}
        />
        <Controller
          control={control}
          name="yearsInRole"
          render={({ field }) => (
            <TextInput
              {...field}
              errorMessage={errors.yearsInRole?.message}
              label="Years in this role"
              fullWidth
              placeholder="# of years in role"
              type="number"
            />
          )}
        />
        <LoadingButton loading={state === 'loading'} fullWidth={false} type="submit">
          Continue
        </LoadingButton>
      </form>
    </>
  )
}

export default SurveyDetailsForm
