import { Box, Button, Container, Theme, Typography, useMediaQuery } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { HEADING_SPACING_MOBILE, HEADING_SPACING } from '../../../constants/spacingConstants'
import { ContractRole, EditContractFormContextModel } from './data/models'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Details from '../AddContract/Details'
import { contractApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import LoadingContainer from '../../common/containers/LoadingContainer'
import { CreateContractForm } from '../AddContract'
import { ContractFormStateModel } from '../AddContract/data/models'

export const EditContractForm = createContext<EditContractFormContextModel | undefined>(undefined)

export const EditContract: React.FunctionComponent = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { callApi, data, state } = useApi(contractApi.apiContractContractByIdPost)
  const { callApi: submitEditApi } = useApi(contractApi.apiContractEditContractPost, () => {
    navigate(`/contract/${params.id}`)
  })
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [formData, setFormData] = useState<ContractFormStateModel>({
    contractRole: {
      selectedContractRole: ContractRole.Enabler,
    },
    primaryContactDetails: {
      firstName: '',
      lastName: '',
      email: '',
      organisationName: '',
    },
    organisationDetails: {
      buyerOrganisationName: '',
      supplierOrganisationName: '',
    },
    contractDetails: {
      contractName: '',
      buyer: '',
      supplier: '',
      term: 0,
      country: '',
      city: '',
      goodsAndServicesCategory: '',
      contractValue: 0,
      contractCurrency: '',
    },
    contractPerformanceDetails: {
      reportingPeriod: '',
      invoicedAmount: 0,
      costToManage: 0,
      totalKPI: 0,
      periodKPI: 0,
      disputes: '',
    },
    contractPersonnelDetails: {
      buyerPersonnel: [],
      supplierPersonnel: [],
    },
  })

  const onSubmit = () => {
    setFormSubmitted(true)
  }

  useEffect(() => {
    if (formSubmitted) {
      submitEditApi({
        editContractDetails: formData.contractDetails,
        contractId: params.id as string,
      })
    }
    // eslint-disable-next-line
  }, [formSubmitted])

  useEffect(() => {
    if (!data) {
      callApi({ contractId: params.id as string })
    }

    if (data) {
      setFormData({
        ...formData,
        contractDetails: {
          contractName: data?.contractDetails.contractName,
          buyer: data?.organisationDetails.buyerOrganisationName,
          supplier: data?.organisationDetails.supplierOrganisationName,
          term: data?.contractDetails.term,
          country: data?.contractDetails.country,
          city: data?.contractDetails.city,
          goodsAndServicesCategory: data?.contractDetails.goodsAndServicesCategory,
          contractValue: data?.contractDetails.contractValue,
          contractCurrency: data?.contractDetails.contractCurrency,
        },
      })
    }
    // eslint-disable-next-line
  }, [data])

  const navigateHome = () => {
    navigate(`/`)
  }

  return (
    <LoadingContainer
      isLoading={state === 'loading' || state === 'idle' || !formData.contractDetails?.contractName}
      isErrored={state === 'errored'}
    >
      <Container maxWidth="xl">
        <Box marginBottom={isMobile ? HEADING_SPACING_MOBILE : HEADING_SPACING}>
          <Button
            sx={{ '& .MuiButton-startIcon': { marginRight: '0px' } }}
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            onClick={navigateHome}
          >
            <Typography fontWeight={'bold'}>Back</Typography>
          </Button>
        </Box>
        <CreateContractForm.Provider value={{ formData, setFormData }}>
          <Details isEditMode onNextPageClick={onSubmit} />
        </CreateContractForm.Provider>
      </Container>
    </LoadingContainer>
  )
}
