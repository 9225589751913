import AuthenticatedRoute from '../../../routes/AuthenticatedRoute'
import FullscreenContainer from '../../common/containers/FullscreenContainer'
import { PurchaseContract } from './PurchaseContract'

const PurchaseContractRoute: React.FunctionComponent = () => {
  return (
    <AuthenticatedRoute>
      <FullscreenContainer showHeader>
        <PurchaseContract />
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default PurchaseContractRoute
