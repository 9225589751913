import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import LoadingContainer from '../components/common/containers/LoadingContainer'
import Survey from '../components/survey'
import { contractApi, surveyApi } from '../data/myApis'
import useApi from '../hooks/useApi'
import BackgroundImage from '../assets/SurveyBackground.png'
import { useTheme } from '@mui/material'
import { AnonymousSoloAssessmentContext } from '../contexts/AnonymousSoloAssessmentContext'

interface SurveyRouteProps {
  isSoloContract?: boolean
  unregistered?: boolean
}

const SurveyRoute: React.FunctionComponent<SurveyRouteProps> = ({
  isSoloContract,
  unregistered,
}) => {
  const {
    data: questions,
    callApi: callGetQuestionApi,
    state: getQuestionState,
  } = useApi(surveyApi.apiSurveyQuestionsGet)

  const {
    data: contractByToken,
    callApi: callGetContractByTokenApi,
    state: getContractByTokenState,
  } = useApi(contractApi.apiContractContractByInviteTokenPost)

  const {
    data: contract,
    callApi: callGetContractApi,
    state: getContractState,
  } = useApi(contractApi.apiContractContractByIdPost)

  const navigate = useNavigate()
  const { contractDetails } = useContext(AnonymousSoloAssessmentContext)

  const params = useParams()
  const theme = useTheme()

  const inviteToken = params.inviteToken as string
  const contractId = params.contractId as string

  const callRetrieveContract = () =>
    isSoloContract ? callGetContractApi({ contractId }) : callGetContractByTokenApi({ inviteToken })

  const contractState = isSoloContract ? getContractState : getContractByTokenState

  useEffect(() => {
    if (unregistered && !contractDetails) {
      navigate('/anonymous/contract/2/add')
      return
    }

    callGetQuestionApi()
    if (unregistered) {
    } else {
      callRetrieveContract()
    }

    window.onbeforeunload = () => true
    return () => {
      window.onbeforeunload = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FullscreenContainer showHeader bgImage={BackgroundImage} bgColor={theme.palette.grey[300]}>
      <LoadingContainer
        isLoading={
          getQuestionState === 'loading' ||
          getQuestionState === 'idle' ||
          (contractState === 'loading' && !unregistered) ||
          (contractState === 'idle' && !unregistered)
        }
        isErrored={
          getQuestionState === 'errored' ||
          getContractState === 'errored' ||
          getContractByTokenState === 'errored'
        }
      >
        <Survey
          questions={questions ?? []}
          contractName={contract?.contractDetails?.contractName ?? contractByToken?.contractName}
          isSoloContract={isSoloContract}
          unregistered={unregistered}
        />
      </LoadingContainer>
    </FullscreenContainer>
  )
}

export default SurveyRoute
