import { Box, Container, Stack } from '@mui/material'
import Logo from '../../../common/logos/Logo'
import { ContractReportNormDto, ContractSummary } from '../../../../data/api/models'
import React from 'react'
import { HEADER_SPACING_MOBILE } from '../../../../constants/spacingConstants'
import SectionHeader from '../SectionHeader'
import PerceivedTrust from './PerceivedTrust'
import RelationalIndex from './RelationalIndex'
import RelationalNormIntensityLevels from './RelationalNormIntensityLevels'
import RelationalProfile from './RelationalProfile'
import TrustBase from './TrustBase'

const SingleUserDashboardViewPageOne = (contract: ContractSummary) => {
  return (
    <SingleUserDashboardViewContainer>
      <SectionHeader
        groupName={contract?.contractDto.contractDetails.contractName}
        mb={HEADER_SPACING_MOBILE}
      />
      <Stack direction={'column'} spacing={1.5}>
        <PerceivedTrust trust={contract?.contractCalculations.coreTrust} isReportView />
        <TrustBase trustBase={contract?.contractCalculations.trustBase} isReportView />
      </Stack>
    </SingleUserDashboardViewContainer>
  )
}

const SingleUserDashboardViewPageTwo = (contract: ContractSummary) => {
  return (
    <SingleUserDashboardViewContainer>
      <Stack direction={'column'} spacing={1.5} mt={2}>
        <RelationalIndex
          relationalIndex={contract?.contractCalculations.relationalIndex}
          isReportView
        />
        <RelationalProfile
          metricIconsProps={{
            color: 'dataBlue',
            metrics: contract?.contractCalculations.contractNorms.map(
              (n: ContractReportNormDto) => ({
                metric: n.metric,
                percentage: n.combinedValue,
              })
            ),
          }}
        />
      </Stack>
    </SingleUserDashboardViewContainer>
  )
}

const SingleUserDashboardViewPageThree = () => {
  return (
    <SingleUserDashboardViewContainer>
      <Stack direction={'column'} spacing={1.5} mt={2}>
        <RelationalNormIntensityLevels isReportView />
      </Stack>
    </SingleUserDashboardViewContainer>
  )
}

const SingleUserDashboardViewContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingTop: '20px',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
          marginLeft: '20px',
          marginBottom: '10px',
        }}
      >
        <Logo width={250} />
      </Box>
      <Container maxWidth={false} sx={{ width: '100%' }}>
        {children}
      </Container>
    </Box>
  )
}

export {
  SingleUserDashboardViewPageOne,
  SingleUserDashboardViewPageTwo,
  SingleUserDashboardViewPageThree,
}
