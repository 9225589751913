import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '../../common/inputs/LoadingButton'
import { Box } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { accountApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import { useSnackbar } from 'notistack'
import changePasswordSchema from './changePasswordSchema'
import userContext from '../../../contexts/UserContext'
import { useContext } from 'react'
import PasswordInput from '../../common/inputs/PasswordInput'

interface ChangePasswordFormValues {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePasswordForm: React.FC = () => {
  const { refreshUser } = useContext(userContext)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(changePasswordSchema),
  })

  const onSuccess = () => {
    enqueueSnackbar('Password updated successfully', { variant: 'success' })
    refreshUser()
  }

  const { callApi, state } = useApi(accountApi.apiAccountUpdatePasswordPost, onSuccess)

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data: ChangePasswordFormValues) =>
    callApi({ oldPassword: data.oldPassword, password: data.newPassword })

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="oldPassword"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.oldPassword?.message}
              placeholder="Old Password"
              label="Old Password"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.newPassword?.message}
              placeholder="New Password"
              label="New Password"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.confirmPassword?.message}
              placeholder="New Password"
              label="Confirm New Password"
              fullWidth
            />
          )}
        />
        <Box mt={3}>
          <LoadingButton loading={state === 'loading'} type="submit">
            Update
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

export default ChangePasswordForm
