import { Box, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import CircularIndicator from '../../common/outputs/CircularIndicator'

interface ContractMetricsProps {
  metrics: {
    label: string
    value: number
  }[]
  reportingPeriod: Date
  isReportView: boolean
}

const ContractMetrics: React.FC<ContractMetricsProps> = ({
  metrics,
  reportingPeriod,
  isReportView,
}) => {
  const theme = useTheme()
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('lg')) && !isReportView

  return (
    <Box
      bgcolor={isMobileView ? theme.palette.common.white : '#F5F5F5'}
      py={3}
      borderRadius="0px 0px 5px 5px"
      px={4}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h3">Relational Impact Metrics</Typography>
        <Typography variant="subtitle1" color="GrayText">
          Reporting period:{' '}
          {reportingPeriod.toLocaleDateString('en-us', { year: 'numeric', month: 'short' })}
        </Typography>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt={3}>
        {metrics.map((m, i) => (
          <Box key={m.label} sx={{ boxSizing: 'borderBox' }}>
            <Box display="flex" justifyContent="center">
              <CircularIndicator
                value={m.value}
                label={`${m.value}%`}
                color={i < 3 ? '#ffd33a' : 'secondary.main'}
              />
            </Box>
            <Typography textAlign={'center'} my={1}>
              {m.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default ContractMetrics
