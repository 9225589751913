import {
  Box,
  Paper,
  ButtonBase,
  Typography,
  Stack,
  useMediaQuery,
  Theme,
  Collapse,
  Divider,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ContractSummary, ContractType } from '../../../data/api/models'
import CircularIndicator from '../../common/outputs/CircularIndicator'
import AddUserButton from './AddUserButton'
import PersonnelTable from './PersonnelTable'

interface ContractListItemProps {
  contract: ContractSummary
  refreshContracts: () => void
}

const ContractListItem: React.FC<ContractListItemProps> = ({ contract, refreshContracts }) => {
  const [showTable, setShowTable] = React.useState(false)
  const navigate = useNavigate()
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  const isContractComplete = !!contract.contractCalculations

  const handleClick = () => {
    if (isContractComplete) return navigate(`/contract/${contract.contractDto.id}`)

    if (contract?.contractDto?.contractType === ContractType.NUMBER_1)
      return navigate(`/assess/details/solo/${contract.contractDto.id}`)

    setShowTable((cur) => !cur)
  }

  return (
    <Box my={2}>
      <Paper>
        <ButtonBase onClick={handleClick} sx={{ width: 1 }}>
          <Grid2
            minHeight={'120px'}
            container
            pl={3}
            pr={4}
            alignItems="center"
            width={1}
            justifyContent="space-between"
          >
            <Grid2 xs={6} md={4}>
              <Typography noWrap variant="h3" align="left">
                {contract.contractDto.contractDetails.contractName}
              </Typography>
              <Typography noWrap variant="body1" color="GrayText" align="left" fontSize={12}>
                {contract.contractDto.contractDetails.goodsAndServicesCategory}
              </Typography>
              {isMobileView && (
                <Typography align="left" noWrap fontSize={12}>
                  {
                    contract.contractDto.organisationDetails[
                      contract.contractDto.contractRole.selectedContractRole === 2
                        ? 'supplierOrganisationName'
                        : 'buyerOrganisationName'
                    ]
                  }
                </Typography>
              )}
            </Grid2>
            <Grid2 xs={0} sm={0} md={2}>
              <Typography noWrap align="center" fontSize={12}>
                {
                  contract.contractDto.organisationDetails[
                    contract.contractDto.contractRole.selectedContractRole === 2
                      ? 'supplierOrganisationName'
                      : 'buyerOrganisationName'
                  ]
                }
              </Typography>
            </Grid2>
            <Grid2 xs={0} sm={0} md={2}>
              <Typography noWrap align="center" fontSize={12}>
                {contract.contractDto.contractDetails.city
                  ? `${contract.contractDto.contractDetails.city}, ${contract.contractDto.contractDetails.country}`
                  : contract.contractDto.contractDetails.country}
              </Typography>
            </Grid2>
            <Grid2 flexGrow={1} xs={1} md={2}>
              {isContractComplete ? (
                <Stack direction="row" spacing={3} justifyContent="flex-end">
                  {!isMobileView && (
                    <Stack alignItems={'center'}>
                      <Box display="flex" justifyContent="center" mb={1}>
                        <CircularIndicator
                          value={contract.contractCalculations.relationalIndex}
                          label={`${contract.contractCalculations.relationalIndex}%`}
                          color="#ffd33a"
                          small
                          scale={0.9}
                          labelStyle={{
                            fontSize: '12px',
                          }}
                        />
                      </Box>
                      <Typography textAlign={'center'} variant="body2" fontSize={12}>
                        Relational Index
                      </Typography>
                    </Stack>
                  )}
                  <Stack alignItems={'center'}>
                    <Box display="flex" justifyContent="center" mb={1}>
                      <CircularIndicator
                        value={contract.contractCalculations.trust}
                        label={`${contract.contractCalculations.trust}%`}
                        color="secondary.main"
                        small
                        scale={0.9}
                        labelStyle={{
                          fontSize: '12px',
                        }}
                      />
                    </Box>
                    <Typography textAlign={'center'} variant="body2" fontSize={12}>
                      Trust
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Typography align="center" fontSize={12}>
                  <u>In progress</u>
                </Typography>
              )}
            </Grid2>
          </Grid2>
        </ButtonBase>
        {!isContractComplete && (
          <Collapse in={showTable}>
            <Divider />
            <Box pt={2}>
              <PersonnelTable
                personnel={contract.contractDto.personnelList}
                buyerOrganisationName={
                  contract.contractDto.organisationDetails.buyerOrganisationName
                }
                supplierOrganisationName={
                  contract.contractDto.organisationDetails.supplierOrganisationName
                }
                refreshContracts={refreshContracts}
                contractId={contract.contractDto.id}
              />
              <Box px={3} py={2}>
                {contract?.contractDto?.personnelList?.length < 10 &&
                  contract?.contractDto?.contractType !== ContractType.NUMBER_1 && (
                    <AddUserButton
                      contractId={contract.contractDto.id}
                      contractName={contract.contractDto.contractDetails.contractName}
                      onSuccessfulAdd={refreshContracts}
                    />
                  )}
              </Box>
            </Box>
          </Collapse>
        )}
      </Paper>
    </Box>
  )
}

export default ContractListItem
