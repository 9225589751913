import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CreateContractForm } from '.'
import Button from '../../common/inputs/Button'
import TextInput from '../../common/inputs/TextInput'
import HoverMessage from '../../common/text/HoverMessage'
import { ContractFormContextModel } from './data/models'
import FormHeader from './FormHeader'
import { GetSymbolForCurrencyCode } from './data/Currencies'

interface PerformanceDetailsValues {
  reportingPeriod: string
  invoicedAmount: number
  costToManage: number
  totalKPI: number
  periodKPI: number
  disputes: string
}

const PerformanceDetailsSchema = yup.object().shape({
  reportingPeriod: yup
    .date()
    .typeError('Reporting period is required')
    .required()
    .label('Reporting period'),
  invoicedAmount: yup.string().required().label('Invoiced Amount'),
  costToManage: yup.number().typeError('Total value is required').required().label('Total value'),
  totalKPI: yup
    .number()
    .typeError('Total KPIs are required')
    .required()
    .integer('Value must be a whole number')
    .min(0)
    .label('Total KPIs'),
  periodKPI: yup
    .number()
    .typeError('KPIs achieved for period is required')
    .required()
    .integer('Value must be a whole number')
    .min(0)
    .label('KPIs achieved for period')
    .max(yup.ref('totalKPI'), 'KPIs achieved for period cannot be more than total KPIs'),
  disputes: yup.string().required().label('# Disputes for period'),
})

interface PerformanceDetailsProps {
  onNextPageClick: () => void
}

const PerformanceDetails: React.FunctionComponent<PerformanceDetailsProps> = ({
  onNextPageClick,
}) => {
  const context = useContext<ContractFormContextModel | undefined>(CreateContractForm!)
  const { formData, setFormData } = context!

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PerformanceDetailsValues>({
    resolver: yupResolver(PerformanceDetailsSchema),
    defaultValues: {
      ...formData.contractPerformanceDetails,
    },
  })

  const onSubmit = (data: PerformanceDetailsValues) => {
    setFormData({
      ...formData,
      contractPerformanceDetails: data,
    })
    onNextPageClick()
  }

  return (
    <Grid2 container>
      <Grid2 xs={12} lg={3}>
        <FormHeader
          title="Contract Performance"
          mobileTitle="Add Contract"
          mobileSubTitle="Contract performance"
          details="Select a recent or typical performance
          reporting period and provide requested
          key performance data.
          Cost to manage includes all expenses
          related to managing the contract for this
          reporting period."
        />
      </Grid2>
      <Grid2 xs={12} lg={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HoverMessage message="End date of a reporting period for a contract. (e.g., A monthly performance report for the month of September, ends on 30th)">
            <Controller
              control={control}
              name="reportingPeriod"
              render={({ field }) => (
                <TextInput
                  {...field}
                  type="date"
                  errorMessage={errors.reportingPeriod?.message}
                  placeholder="May 12 2022"
                  label="Reporting period"
                  fullWidth
                />
              )}
            />
          </HoverMessage>
          <HoverMessage message="The amount that is listed on the Suppliers invoice presented to the Buyer for payment.">
            <Controller
              control={control}
              name="invoicedAmount"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.invoicedAmount?.message}
                  placeholder="Total value"
                  label="Invoiced Amount"
                  fullWidth
                  type="number"
                  startAdornment={GetSymbolForCurrencyCode(
                    formData.contractDetails.contractCurrency
                  )}
                />
              )}
            />
          </HoverMessage>
          <HoverMessage message="The amount spent on managing the contract, including personnel and all other contract management related expenditure.">
            <Controller
              control={control}
              name="costToManage"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.costToManage?.message}
                  placeholder="Total value"
                  label="Cost to manage"
                  fullWidth
                  type="number"
                  startAdornment={GetSymbolForCurrencyCode(
                    formData.contractDetails.contractCurrency
                  )}
                />
              )}
            />
          </HoverMessage>

          <Stack direction="row" spacing={1}>
            <Box width={1}>
              <HoverMessage message="All Key Performance Indicators, milestones, deliverables listed in the contract, that are to be achieved by the Supplier.">
                <Controller
                  control={control}
                  name="totalKPI"
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      errorMessage={errors.totalKPI?.message}
                      placeholder="Total KPIs"
                      label="Total KPIs"
                      type="number"
                      fullWidth
                    />
                  )}
                />
              </HoverMessage>
            </Box>
            <Box width={1}>
              <HoverMessage message="How many of those Total KPIs were achieved for the nominated Reporting period.">
                <Controller
                  control={control}
                  name="periodKPI"
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      errorMessage={errors.periodKPI?.message}
                      placeholder="KPIs"
                      type="number"
                      label="KPIs achieved for period"
                      fullWidth
                    />
                  )}
                />
              </HoverMessage>
            </Box>
          </Stack>
          <HoverMessage message="The number of disputes and serious issues related to the contract, that have been raised or remain unresolved during the Reporting period.">
            <Controller
              control={control}
              name="disputes"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.disputes?.message}
                  placeholder="# Disputes"
                  label="# Disputes for period"
                  type="number"
                  fullWidth
                />
              )}
            />
          </HoverMessage>
          <Box display="flex" justifyContent="right">
            <Button type="submit" fullWidth={false}>
              Add Personnel
            </Button>
          </Box>
        </form>
      </Grid2>
    </Grid2>
  )
}

export default PerformanceDetails
