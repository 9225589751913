import { useParams } from 'react-router-dom'
import { paymentApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import { useEffect } from 'react'
import LoadingContainer from '../../common/containers/LoadingContainer'

export const PurchaseContract = () => {
  const { callApi, state, data } = useApi(paymentApi.apiPaymentPurchaseReportPost)
  const params = useParams()

  useEffect(() => {
    callApi({ contractId: params.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!data?.purchaseUrl) {
      window.location.href = data.purchaseUrl
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadingContainer
      isLoading={state === 'loading' || state === 'idle'}
      isErrored={state === 'errored'}
    ></LoadingContainer>
  )
}
