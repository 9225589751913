import { Box, Stack, Typography, useTheme } from '@mui/material'
import AccordionWrapper from '../../../common/containers/AccordionWrapper'
import FilledLabelChip from '../../../common/outputs/FilledLabelChip'
import { trustBaseDescriptions } from './descriptionText'

interface TrustBaseProps {
  trustBase: string
  isReportView?: boolean
}

const TrustBase: React.FC<TrustBaseProps> = ({ trustBase, isReportView }) => {
  const theme = useTheme()

  const trustBaseDescription = trustBaseDescriptions.find(
    (x) => trustBase?.toLocaleUpperCase() === x.trustBase.toLocaleUpperCase()
  )

  return (
    <Box
      bgcolor={theme.palette.common.white}
      borderRadius="0px 0px 5px 5px"
      px={2}
      pt={2}
      pb={isReportView ? 2 : 0}
    >
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignItems={'center'}
        mb={1}
      >
        <Typography variant="h2">
          <b>Trust Base</b>
        </Typography>
        <FilledLabelChip label={trustBase} />
      </Stack>
      <AccordionWrapper showAsAccordion={!isReportView}>
        <Typography variant="h3" display={'span'} color={theme.palette.secondary.main}>
          <b>{trustBaseDescription?.heading}</b>
        </Typography>
        <Typography variant="subtitle1" color={'GrayText'} py={1.5}>
          {trustBaseDescription?.subheading}
        </Typography>
        <Typography>{trustBaseDescription?.description}</Typography>
      </AccordionWrapper>
    </Box>
  )
}

export default TrustBase
