import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import LoadingContainer from '../components/common/containers/LoadingContainer'
import SurveyDetails from '../components/surveyDetails'
import { contractApi } from '../data/myApis'
import useApi from '../hooks/useApi'
import theme from '../theme'

const SurveyDetailsRoute: React.FunctionComponent = () => {
  const params = useParams()

  const inviteToken = params.inviteToken as string

  const { callApi, data, state } = useApi(contractApi.apiContractContractByInviteTokenPost)

  useEffect(() => {
    callApi({ inviteToken })
    // eslint-disable-next-line
  }, [])

  return (
    <FullscreenContainer showHeader bgColor={theme.palette.grey[300]}>
      <LoadingContainer
        isLoading={state === 'loading'}
        isErrored={state === 'errored' || !data}
        errorMessage="You have already completed your relational assessment for this contract."
        showRefreshButton={false}
      >
        <SurveyDetails inviteToken={inviteToken} contract={data} />
      </LoadingContainer>
    </FullscreenContainer>
  )
}

export default SurveyDetailsRoute
