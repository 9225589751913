import { Box, Container, Typography } from '@mui/material'
import { HEADER_SPACING, HEADING_SPACING } from '../../constants/spacingConstants'
import { useContext } from 'react'
import userContext from '../../contexts/UserContext'
import Button from '../common/inputs/Button'
import { useNavigate } from 'react-router-dom'

const SurveyCompletion: React.FC = () => {
  const { user } = useContext(userContext)

  const navigate = useNavigate()

  return (
    <>
      <Container maxWidth="sm">
        <Typography variant="h1" mt={HEADER_SPACING} mb={HEADING_SPACING}>
          Thank you for completing your Relational assessment.
        </Typography>
        <Typography variant="body1">
          Your contribution to this commercial relationship is valuable and greatly appreciated by
          your organisation.
          <br />
          <br />
          It will be used to improve the efficiency and performance of the commercial agreement.
          <br />
          <br />
          It’s now safe to close this browser window.
        </Typography>
      </Container>
      {user && (
        <Box
          position={'absolute'}
          bottom={0}
          left={0}
          right={0}
          marginX={'auto'}
          pb={4}
          textAlign={'center'}
        >
          <Button fullWidth={false} onClick={() => navigate('/')}>
            Done
          </Button>
        </Box>
      )}
    </>
  )
}

export default SurveyCompletion
