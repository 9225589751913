import FullscreenContainer from '../common/containers/FullscreenContainer'
import TermsOfService from './TermsOfService'

const TermsOfServiceRouter: React.FunctionComponent = () => {
  return (
    <FullscreenContainer showHeader>
      <TermsOfService />
    </FullscreenContainer>
  )
}

export default TermsOfServiceRouter
