import { Box, Container, Theme, useMediaQuery } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { HEADER_SPACING, LEFT_SPACING } from '../../constants/spacingConstants'
import { ContractOverview } from '../../data/api/models'
import Button from '../common/inputs/Button'
import SurveyDetailsForm from './SurveyDetailsForm'
import SurveyInformation from './SurveyInformation'
import React from 'react'

interface SurveyDetailsProps {
  inviteToken: string
  contract?: ContractOverview
}

const SurveyDetails: React.FC<SurveyDetailsProps> = ({ inviteToken, contract }) => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const [search, setSearch] = useSearchParams()

  const showMultiplePages = useMediaQuery((t: Theme) => t.breakpoints.down('lg'))
  const moveToFormPage = () => setSearch({ page: 'form' }, { replace: false })

  React.useEffect(() => {
    window.onbeforeunload = () => true

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  return (
    <Box
      display="flex"
      boxSizing="border-box"
      my={2}
      ml={showMultiplePages ? undefined : LEFT_SPACING}
      mt={isMobile ? 7 : HEADER_SPACING}
    >
      {(!showMultiplePages || search.get('page') !== 'form') && (
        <Box width={showMultiplePages ? 1 : 0.45} mx={2}>
          <Container maxWidth="sm" sx={{ margin: showMultiplePages ? undefined : 0 }}>
            <SurveyInformation />
            {showMultiplePages && (
              <Box mt={5}>
                <Button onClick={moveToFormPage}>Start Assessment</Button>
              </Box>
            )}
          </Container>
        </Box>
      )}
      {(!showMultiplePages || search.get('page') === 'form') && (
        <Box width={showMultiplePages ? 1 : 0.45} mx={2}>
          <Container maxWidth="sm" sx={{ margin: showMultiplePages ? undefined : 0 }}>
            <SurveyDetailsForm
              contractName={contract?.contractName}
              inviteToken={inviteToken}
              organisationName={contract?.organisationName}
            />
          </Container>
        </Box>
      )}
    </Box>
  )
}

export default SurveyDetails
