import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Button from '../../common/inputs/Button'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useApi from '../../../hooks/useApi'
import { contractApi } from '../../../data/myApis'
import SelectInput from '../../common/inputs/SelectInput'
import { useSnackbar } from 'notistack'
import TextInput from '../../common/inputs/TextInput'
import LoadingButton from '../../common/inputs/LoadingButton'
import { ReactComponent as UserPlusIcon } from '../../../assets/User-Plus.svg'

interface AddUserButtonProps {
  contractId: number
  contractName: string
  onSuccessfulAdd: () => void
}

interface AddUserFormValues {
  email: string
  role: number
}

const AddUserSchema = yup.object().shape({
  email: yup.string().required().email().label('Email'),
  role: yup.number().positive().integer().label('Role'),
})

const AddUserButton: React.FC<AddUserButtonProps> = ({
  onSuccessfulAdd,
  contractId,
  contractName,
}) => {
  const [open, setOpen] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const onSuccess = () => {
    enqueueSnackbar('User added successfully', { variant: 'success' })
    onSuccessfulAdd()
  }

  const { callApi, state } = useApi(contractApi.apiContractAddUserPost, onSuccess)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddUserFormValues>({
    resolver: yupResolver(AddUserSchema),
    defaultValues: { email: '', role: 1 },
  })

  const onSubmit = (data: AddUserFormValues) => {
    callApi({ contractId, email: data.email, role: data.role })
  }

  const handleOpen = () => {
    reset()
    setOpen(true)
  }

  return (
    <>
      <LoadingButton
        loading={state === 'loading'}
        onClick={handleOpen}
        fullWidth={false}
        color="dark"
        size="small"
        paddingX={3}
      >
        <UserPlusIcon />
        <Typography ml={1}>Add Personnel</Typography>
      </LoadingButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Add user to contract <b>{contractName}</b>
        </DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextInput
                {...field}
                fullWidth
                errorMessage={errors.email?.message}
                label="Email"
                placeholder="Email"
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <SelectInput
                label="Role"
                {...field}
                inputProps={{
                  name: 'Role',
                  id: 'role',
                }}
                fullWidth
                errorMessage={errors.role?.message}
                defaultValue={'Buyer'}
              >
                <MenuItem value={1}>Buyer</MenuItem>
                <MenuItem value={2}>Supplier</MenuItem>
              </SelectInput>
            )}
          />
        </DialogContent>
        <DialogActions>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" spacing={1}>
              <Button
                onClick={() => setOpen(false)}
                disabled={state === 'loading'}
                fullWidth={false}
              >
                Cancel
              </Button>
              <LoadingButton type="submit" loading={state === 'loading'} fullWidth={false}>
                Submit
              </LoadingButton>
            </Stack>
          </form>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddUserButton
