/* tslint:disable */
/* eslint-disable */
/**
 * api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
import { GetPaymentStatusResponse } from '../models'
import { InitiateSwotReportPurchaseCommand } from '../models'
import { InitiateSwotReportPurchaseResponse } from '../models'
import { RequestPaymentResponse } from '../models'
/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPaymentPaymentStatusProductIdGet: async (
      productId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      if (productId === null || productId === undefined) {
        throw new RequiredError(
          'productId',
          'Required parameter productId was null or undefined when calling apiPaymentPaymentStatusProductIdGet.'
        )
      }
      const localVarPath = `/api/Payment/PaymentStatus/{productId}`.replace(
        `{${'productId'}}`,
        encodeURIComponent(String(productId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {InitiateSwotReportPurchaseCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPaymentPurchaseReportPost: async (
      body?: InitiateSwotReportPurchaseCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Payment/PurchaseReport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPaymentRequestPaymentProductIdGet: async (
      productId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productId' is not null or undefined
      if (productId === null || productId === undefined) {
        throw new RequiredError(
          'productId',
          'Required parameter productId was null or undefined when calling apiPaymentRequestPaymentProductIdGet.'
        )
      }
      const localVarPath = `/api/Payment/RequestPayment/{productId}`.replace(
        `{${'productId'}}`,
        encodeURIComponent(String(productId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPaymentPaymentStatusProductIdGet(
      productId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentStatusResponse>
    > {
      const localVarAxiosArgs = await PaymentApiAxiosParamCreator(
        configuration
      ).apiPaymentPaymentStatusProductIdGet(productId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {InitiateSwotReportPurchaseCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPaymentPurchaseReportPost(
      body?: InitiateSwotReportPurchaseCommand,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitiateSwotReportPurchaseResponse>
    > {
      const localVarAxiosArgs = await PaymentApiAxiosParamCreator(
        configuration
      ).apiPaymentPurchaseReportPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPaymentRequestPaymentProductIdGet(
      productId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPaymentResponse>> {
      const localVarAxiosArgs = await PaymentApiAxiosParamCreator(
        configuration
      ).apiPaymentRequestPaymentProductIdGet(productId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPaymentPaymentStatusProductIdGet(
      productId: number,
      options?: any
    ): AxiosPromise<GetPaymentStatusResponse> {
      return PaymentApiFp(configuration)
        .apiPaymentPaymentStatusProductIdGet(productId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {InitiateSwotReportPurchaseCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPaymentPurchaseReportPost(
      body?: InitiateSwotReportPurchaseCommand,
      options?: any
    ): AxiosPromise<InitiateSwotReportPurchaseResponse> {
      return PaymentApiFp(configuration)
        .apiPaymentPurchaseReportPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPaymentRequestPaymentProductIdGet(
      productId: number,
      options?: any
    ): AxiosPromise<RequestPaymentResponse> {
      return PaymentApiFp(configuration)
        .apiPaymentRequestPaymentProductIdGet(productId, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
  /**
   *
   * @param {number} productId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public apiPaymentPaymentStatusProductIdGet(productId: number, options?: any) {
    return PaymentApiFp(this.configuration)
      .apiPaymentPaymentStatusProductIdGet(productId, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {InitiateSwotReportPurchaseCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public apiPaymentPurchaseReportPost(body?: InitiateSwotReportPurchaseCommand, options?: any) {
    return PaymentApiFp(this.configuration)
      .apiPaymentPurchaseReportPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {number} productId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public apiPaymentRequestPaymentProductIdGet(productId: number, options?: any) {
    return PaymentApiFp(this.configuration)
      .apiPaymentRequestPaymentProductIdGet(productId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
