import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '../../common/inputs/LoadingButton'
import { Box } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { accountApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import { useSnackbar } from 'notistack'
import resetPasswordSchema from './resetPasswordSchema'
import PasswordInput from '../../common/inputs/PasswordInput'
import { useNavigate } from 'react-router-dom'

interface ResetPasswordFormValues {
  newPassword: string
  confirmPassword: string
}

interface ResetPasswordFormProps {
  token: string
  userId: string
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ token, userId }) => {
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(resetPasswordSchema),
  })

  const onSuccess = () => {
    enqueueSnackbar('Password updated successfully', { variant: 'success' })
    navigate('/login')
  }

  const { callApi, state } = useApi(accountApi.apiAccountResetPasswordPost, onSuccess)

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data: ResetPasswordFormValues) =>
    callApi({ password: data.newPassword, userId, token })

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.newPassword?.message}
              placeholder="New Password"
              label="New Password"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <PasswordInput
              {...field}
              errorMessage={errors.confirmPassword?.message}
              placeholder="New Password"
              label="Confirm New Password"
              fullWidth
            />
          )}
        />
        <Box mt={3}>
          <LoadingButton loading={state === 'loading'} type="submit">
            Reset
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

export default ResetPasswordForm
