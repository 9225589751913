import React from 'react'
import { UserDto } from '../data/api/models'

interface UserContextState {
  user?: UserDto
  state: 'loading' | 'fulfilled' | 'errored' | 'idle'
  refreshUser: () => void
}

const userContext = React.createContext<UserContextState>({ state: 'idle', refreshUser: () => {} })

export default userContext
