import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import { AddContract } from '../components/contract/AddContract'
import AuthenticatedRoute from './AuthenticatedRoute'

const AddContractRoute: React.FunctionComponent = () => {
  return (
    <AuthenticatedRoute>
      <FullscreenContainer showHeader>
        <AddContract />
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default AddContractRoute
