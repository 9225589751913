import React from 'react'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import SelectPlan from '../components/payment/SelectPlan'
import AuthenticatedRoute from './AuthenticatedRoute'

const SelectPlanRoute: React.FunctionComponent = () => (
  <AuthenticatedRoute>
    <FullscreenContainer>
      <SelectPlan />
    </FullscreenContainer>
  </AuthenticatedRoute>
)

export default SelectPlanRoute
