import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import ContractProfileGraph from './ContractProfileGraph'

interface ContractProfileDetailsProps {
  metrics: { metric: string; buyer: number; supplier: number; combined: number }[]
  isReportView?: boolean
}

const ContractProfileDetails: React.FC<ContractProfileDetailsProps> = ({
  metrics,
  isReportView = false,
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) && !isReportView
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const lgBreakpoint = useMediaQuery((t: Theme) => t.breakpoints.down('lg')) && !isReportView

  return (
    <Box
      bgcolor={lgBreakpoint ? theme.palette.common.white : theme.palette.background.default}
      mb={0.5}
      py={isMobile ? 3 : 4}
      px={isMobile ? 2 : 4}
      width={1}
      height={1}
      borderRadius="5px 5px 0px 0px"
      boxSizing="border-box"
    >
      <Typography variant="h3">{'Relational Impact Profile'}</Typography>
      <ContractProfileGraph dataPoints={metrics} isSmall={isSmall} isReportView={isReportView} />
    </Box>
  )
}

export default ContractProfileDetails
