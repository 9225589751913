import { Box, Grid, Divider, Typography } from '@mui/material'
import Logo from '../../common/logos/Logo'
import CircularIndicator from '../../common/outputs/CircularIndicator'
import Copyright from './Copyright'

export type FrictionProps = {
  frictionValue: number
}

const MetricsFirstPage = (props: FrictionProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>

      <Grid container mt={19}>
        <Header />
        <Grid item xs={12} mt={8}>
          <Divider />
        </Grid>
        <Friction {...props} />
      </Grid>
      <Copyright pageNumber={11} />
    </Box>
  )
}

const Friction = ({ frictionValue }: FrictionProps) => {
  return (
    <>
      <Grid item xs={2} mt={9}>
        <Box display="flex" justifyContent="left" mb={1}>
          <CircularIndicator
            value={frictionValue}
            label={frictionValue + '%'}
            color="secondary.main"
            scale={2.2}
            labelStyle={{
              fontSize: '35px',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={10} mt={8}>
        <Typography fontSize={45} fontWeight={900}>
          FRICTION
        </Typography>
        <Typography fontSize={24} mt={2}>
          This metric gauges the potential friction within the contract. Friction makes contracts
          less efficient and it’s effect can be seen in higher contract management expenditure.
          Contract management expenditure is shown as percentage of the contract value.
        </Typography>
        <Typography fontSize={18} mt={3}>
          The friction figure in industry ranges from 1.5% - 16%, depending on complexity, value and
          external market forces.
        </Typography>
      </Grid>
    </>
  )
}

const Header = () => {
  return (
    <>
      <Grid item xs={2}></Grid>
      <Grid item xs={10}>
        <Typography fontSize={45} fontWeight={900}>
          RELATIONAL IMPACT METRICS
        </Typography>
        <Typography fontSize={24} mt={2}>
          These metrics assist you to understand and interpret the Relational qualities of the deal.
          For each key relational metric, the industry average is provided for benchmark and
          comparative purposes. The industry benchmark is updated regularly.
        </Typography>
      </Grid>
    </>
  )
}

export default MetricsFirstPage
