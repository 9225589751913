import { useContext } from 'react'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import LoadingContainer from '../components/common/containers/LoadingContainer'
import EditAccount from '../components/account/editAccount'
import userContext from '../contexts/UserContext'
import { UserDto } from '../data/api/models'
import AuthenticatedRoute from './AuthenticatedRoute'

const EditAccountRoute: React.FunctionComponent = () => {
  const { state: userState, user } = useContext(userContext)

  return (
    <AuthenticatedRoute>
      <FullscreenContainer showHeader>
        <LoadingContainer
          isLoading={userState === 'loading' || userState === 'idle'}
          isErrored={userState === 'errored'}
        >
          <EditAccount user={user as UserDto} />
        </LoadingContainer>
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default EditAccountRoute
