import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import { AddSingleUserContract } from '../components/contract/AddContract/AddSingleUserContract'
import { ReactComponent as BackgroundGraphics } from '../assets/AddContract-Graphics.svg'
import AuthenticatedRoute from './AuthenticatedRoute'

type AddSingleUserContractRouteProps = {
  unregistered?: boolean
}

const AddSingleUserContractRoute: React.FunctionComponent<AddSingleUserContractRouteProps> = ({
  unregistered,
}: AddSingleUserContractRouteProps) => {
  if (unregistered) {
    return (
      <FullscreenContainer showHeader bgGraphics={<BackgroundGraphics />}>
        <AddSingleUserContract unregistered />
      </FullscreenContainer>
    )
  } else {
    return (
      <AuthenticatedRoute>
        <FullscreenContainer showHeader bgGraphics={<BackgroundGraphics />}>
          <AddSingleUserContract />
        </FullscreenContainer>
      </AuthenticatedRoute>
    )
  }
}

export default AddSingleUserContractRoute
