import { Button, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import EditIcon from '@mui/icons-material/Edit'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import { useNavigate, useParams } from 'react-router-dom'

interface ContractActionsProps {
  isMobile: boolean
  isReportView?: boolean
  enableDownload?: boolean
  onDownloadClicked: () => void
}

const ContractActions: React.FC<ContractActionsProps> = ({
  isMobile,
  onDownloadClicked,
  enableDownload,
}) => {
  const navigate = useNavigate()
  const params = useParams()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box mx={2}>
        <Button
          sx={{ '& .MuiButton-startIcon': { marginRight: '0px' } }}
          variant="text"
          startIcon={<ArrowBackIosIcon />}
          onClick={() => navigate('/')}
        >
          <Typography color="text.primary" fontWeight={'700'} fontSize={15}>
            My Contracts
          </Typography>
        </Button>
      </Box>
      <Box mx={2}>
        <Tooltip title="Download your PDF report" arrow>
          <Button
            disabled={!enableDownload}
            variant="text"
            endIcon={<PdfIcon />}
            color="info"
            onClick={onDownloadClicked}
          >
            {isMobile ? '' : 'Report'}
          </Button>
        </Tooltip>
        {/* Hide Edit button until edit functionality is implemented */}
        <Tooltip title="Edit your contract detail" arrow>
          <Button
            variant="text"
            endIcon={<EditIcon />}
            color="info"
            onClick={() => navigate(`/edit/contract/${params.contractId}`)}
          >
            {isMobile ? '' : 'Edit'}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ContractActions
