/* tslint:disable */
/* eslint-disable */
/**
 * api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum ContractType {
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
}
