import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import Signup from '../components/account/signup'

const SignupRoute: React.FunctionComponent = () => {
  return (
    <FullscreenContainer>
      <Signup />
    </FullscreenContainer>
  )
}

export default SignupRoute
