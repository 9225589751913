import { yupResolver } from '@hookform/resolvers/yup'
import { MenuItem } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import SelectInput from '../../common/inputs/SelectInput'
import TextInput from '../../common/inputs/TextInput'
import { CreateContractForm } from '.'
import { ContractFormContextModel } from './data/models'
import Grid2 from '@mui/material/Unstable_Grid2'
import Button from '../../common/inputs/Button'
import { standProductsList } from './data/StandardProductsList'
import FormHeader from './FormHeader'
import HoverMessage from '../../common/text/HoverMessage'
import { CountriesWithCities } from './data/CountriesWithCitiesArray'
import AutocompleteInput from '../../common/inputs/AutocompleteInput'
import { GetCurrencyCodeByCountryName } from './data/CountriesCurrencies'
import { GetSortedCurrencies, GetSymbolForCurrencyCode } from './data/Currencies'

interface DetailsValues {
  contractName: string
  buyer: string
  supplier: string
  enabler: string
  term: number
  country: keyof typeof CountriesWithCities
  city: string
  goodsAndServicesCategory: string
  contractValue: number
  contractCurrency: string
}

const DetailsSchema = yup.object().shape({
  contractName: yup
    .string()
    .max(50, 'Contract Name must be 50 characters or fewer')
    .required()
    .label('Contract Name'),
  buyer: yup
    .string()
    .max(40, 'Buyer name must be 40 characters or fewer')
    .required()
    .label('Buyer'),
  supplier: yup
    .string()
    .max(40, 'Supplier name must be 40 characters or fewer')
    .required()
    .label('Supplier'),
  term: yup
    .number()
    .typeError('Term is required')
    .integer('Value must be a whole number')
    .required()
    .label('Term (# of years)'),
  country: yup.string().required().label('Country'),
  city: yup.string().required().label('City'),
  goodsAndServicesCategory: yup.string().required().label('Goods and Services category'),
  contractValue: yup
    .number()
    .typeError('Contract value is required')
    .integer('Value must be a whole number')
    .required()
    .label('Contract value'),
  contractCurrency: yup.string().required().label('Contract Currency'),
})

interface DetailsProps {
  isEditMode?: boolean
  onNextPageClick: () => void
}

const Details: React.FunctionComponent<DetailsProps> = ({ isEditMode, onNextPageClick }) => {
  const context = useContext<ContractFormContextModel | undefined>(CreateContractForm!)
  const { formData, setFormData } = context!

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DetailsValues>({
    resolver: yupResolver(DetailsSchema),
    defaultValues: {
      ...formData.contractDetails,
      goodsAndServicesCategory: !!formData.contractDetails.goodsAndServicesCategory
        ? formData.contractDetails.goodsAndServicesCategory
        : 'Other Services',
      buyer: !!formData.contractDetails.buyer
        ? formData.contractDetails.buyer
        : formData.organisationDetails.buyerOrganisationName,
      supplier: !!formData.contractDetails.supplier
        ? formData.contractDetails.supplier
        : formData.organisationDetails.supplierOrganisationName,
      country: formData.contractDetails.country ? formData.contractDetails.country : 'Australia',
      city: formData.contractDetails.city ? formData.contractDetails.city : 'Melbourne',
      contractCurrency: formData.contractDetails.contractCurrency
        ? formData.contractDetails.contractCurrency
        : 'AUD',
    },
  })

  const onSubmit = (data: DetailsValues) => {
    setFormData({
      ...formData,
      contractDetails: data,
    })
    onNextPageClick()
  }

  useEffect(() => {
    watch((value, { name }) => {
      if (name === 'country') {
        const newCountry = value.country as string
        setValue('city', CountriesWithCities[newCountry as keyof typeof CountriesWithCities][0])
        setValue('contractCurrency', GetCurrencyCodeByCountryName(newCountry))
      }
    })
  }, [watch, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={4}>
        <Grid2 xs={12} lg={2}>
          <FormHeader
            title="Contract Details"
            mobileTitle="Add Contract"
            mobileSubTitle="Contract Details"
            details={`${isEditMode ? 'Edit' : 'Input'} the key details of your contract`}
          />
        </Grid2>
        <Grid2 xs={12} lg={5}>
          <HoverMessage message="Official name of the Contract as given by the Buyer organisation.">
            <Controller
              control={control}
              name="contractName"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.contractName?.message}
                  placeholder="Contract Name"
                  label="Contract Name"
                  fullWidth
                />
              )}
            />
          </HoverMessage>
          <HoverMessage message="Organisation or entity identified in a contract as the party to receive commodities or contractual services pursuant to a contract from the Supplier.">
            <Controller
              control={control}
              name="buyer"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.buyer?.message}
                  label={isEditMode ? 'Buyer Organisation Name' : 'Buyer'}
                  fullWidth
                />
              )}
            />
          </HoverMessage>
          <HoverMessage message="Organisation or entity identified in a contract as the party to provide commodities or contractual services pursuant to a contract to the Buyer.">
            <Controller
              control={control}
              name="supplier"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.supplier?.message}
                  label={isEditMode ? 'Supplier Organisation Name' : 'Supplier'}
                  fullWidth
                />
              )}
            />
          </HoverMessage>
        </Grid2>
        <Grid2 xs={12} lg={5}>
          <HoverMessage message="Duration of the contract as defined in the contract terms and conditions. (e.g., 5 years)">
            <Controller
              control={control}
              name="term"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.term?.message}
                  placeholder="Term in years"
                  label="Term (# of Years)"
                  type="number"
                  fullWidth
                />
              )}
            />
          </HoverMessage>
          <Controller
            control={control}
            name="goodsAndServicesCategory"
            render={({ field }) => (
              <SelectInput
                label="Goods and Services Category"
                defaultValue="Other Services"
                {...field}
                inputProps={{
                  name: 'name',
                  id: 'name',
                }}
                fullWidth
              >
                {standProductsList
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((value) => {
                    return (
                      <MenuItem key={value.name} value={value.name}>
                        {value.name}
                      </MenuItem>
                    )
                  })}
              </SelectInput>
            )}
          />
          <Controller
            render={({ ...props }) => (
              <AutocompleteInput
                options={Object.getOwnPropertyNames(CountriesWithCities)}
                onChange={props.field.onChange}
                label="Country"
                value={props.field.value}
                errorMessage={errors.country?.message}
                {...props}
              />
            )}
            name="country"
            control={control}
          />
          <Controller
            render={({ ...props }) => (
              <AutocompleteInput
                options={getValues().country && CountriesWithCities[getValues().country]}
                onChange={props.field.onChange}
                value={props.field.value}
                label="City"
                {...props}
              />
            )}
            name="city"
            control={control}
          />
          <Controller
            control={control}
            name="contractCurrency"
            render={({ field }) => (
              <SelectInput
                label="Currency"
                {...field}
                inputProps={{
                  name: 'contractCurrency',
                  id: 'contractCurrency',
                }}
                defaultValue="AUD"
                fullWidth
              >
                {GetSortedCurrencies().map(({ name, code }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </SelectInput>
            )}
          />
          <HoverMessage
            message={`Amount of consideration $ that will be paid by the buyer to the Supplier as agreed to by both parties, excluding variations and penalties.`}
          >
            <Controller
              control={control}
              name="contractValue"
              render={({ field }) => (
                <TextInput
                  {...field}
                  errorMessage={errors.contractValue?.message}
                  placeholder="Total Value"
                  type="number"
                  label="Value"
                  fullWidth
                  startAdornment={GetSymbolForCurrencyCode(watch('contractCurrency'))}
                />
              )}
            />
          </HoverMessage>
        </Grid2>
        <Grid2 xs={12} justifyContent="right" display="flex">
          <Button type="submit" fullWidth={false}>
            {isEditMode ? 'Finish' : 'Continue'}
          </Button>
        </Grid2>
      </Grid2>
    </form>
  )
}

export default Details
