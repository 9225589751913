import { Box, Stack, Typography, useTheme } from '@mui/material'
import MetricIcon from '../MetricIcon'
import { relationalNormDescriptions } from './descriptionText'

const RelationalNorms: React.FC = () => {
  const theme = useTheme()

  return (
    <Box py={2}>
      <Typography variant="h2">
        <b>Relational Norms</b>
      </Typography>
      <Stack direction={'row'} py={2} height={1} spacing={2} overflow={'auto'}>
        {relationalNormDescriptions.map((x) => (
          <Stack
            flexDirection={'column'}
            width={150}
            style={{ backgroundColor: theme.palette.white.main, borderRadius: 5 }}
            p={2}
            key={x.metric}
          >
            <Box alignSelf={'center'}>
              <MetricIcon
                metric={x.metric}
                filled
                color={theme.palette.primary.main}
                width={60}
                height={60}
              />
            </Box>
            <Typography variant="h3" pb={1}>
              {x.metric}
            </Typography>
            <Typography>{x.description}</Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}

export default RelationalNorms
