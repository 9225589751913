export const perceivedTrustDescriptions: {
  minValue: number
  title: string
  description: string
}[] = [
  {
    minValue: 90,
    title: 'Elite (90%+ )',
    description:
      'This is where magic happens. Environment is right for maximum performance. Monitor and maintain.',
  },
  {
    minValue: 76,
    title: 'High (76-89%)',
    description:
      'Most commercial relationships sit within this range. Look for opportunities for improvement.',
  },
  {
    minValue: 51,
    title: 'Average (51-75%)',
    description:
      'Some trust but could deteriorate. Invest in relationship before it becomes toxic.',
  },
  {
    minValue: 0,
    title: 'Low (0-50%)',
    description: 'Relationship distrust is endemic. Likely to be toxic. Requires major surgery.',
  },
]

export const trustBaseDescriptions: {
  trustBase: string
  heading: string
  subheading: string
  description: string
}[] = [
  {
    trustBase: 'COLLABORATIVE',
    heading: 'Collaborative Trust',
    subheading: 'High performance and High commitment',
    description:
      'The holy grail of long-term strategic partnerships. It is where performance is high, as is the strength of the commercial relationship. Fundamentally, performance and commitment support each other to maintain high levels of trust. This reduces contract friction that in turn improves efficiency and boost performance. Collaborative trust base is hard to achieve and even harder to maintain. However, for achieving success in high value, complex, strategic long term commercial relationships, it’s crucial.',
  },
  {
    trustBase: 'COMMITMENT',
    heading: 'Commitment Based Trust',
    subheading: 'Low performance and High commitment',
    description:
      'The parties focus predominantly on having strong strategic relationships and are there for the long haul. High performance is implied, but not necessarily focused upon. Unfortunately, commitment can only support the relationship for so long before lack of performance starts undermining trust. Commitment based trust is a great starting point for a commercial relationship, however it’s important to quickly start delivering on performance commitments before the relationships end up in the Limited trust quadrant.',
  },
  {
    trustBase: 'PERFORMANCE',
    heading: 'Performance Based Trust',
    subheading: 'High performance and Low commitment',
    description:
      'The commercial relationship is one where the parties are achieving strong transactional performance in the commercial agreement but are reluctant to commit attention or resources to a long-term partnership. Strong performance paves the way to higher trust, but ascension does not occur. This is an appropriate and cost-effective quadrant for highly transactional and commoditised commercial agreements, where the investment to achieve quality trust and relationships far out way the benefits.',
  },
  {
    trustBase: 'LIMITED',
    heading: 'Limited Trust',
    subheading: 'Low performance and Low commitment',
    description:
      'When a commercial relationship has low commitment and performance, its trust base is low and limited. The parties are perceived to not deliver on their promises, are viewed with suspicion, and are seen as opportunistic in their business dealings. For simple transactional agreements this may be satisfactory, however for more complex, higher value, longer term, and strategic relationships, it’s imperative to transform the relationship out of this.',
  },
]

export const relationalIndexDescriptions: {
  minValue: number
  title: string
  description: string
}[] = [
  { minValue: 50, title: 'Greater than 50%', description: 'More Relational than Transactional' },
  { minValue: 0, title: 'Less than 50%', description: 'More Transactional than Relational' },
]

export const relationalNormIntensityLevelDescriptions: { band: string; description: string }[] = [
  {
    band: 'Elite (91%+ )',
    description:
      'Relational norm at its best. Has a positive effect on supporting other norms as well as overall relationship performance. Monitor and maintain.',
  },
  {
    band: 'High (76-90%)',
    description: 'Healthy with manageable negative effects. Look for opportunities to improve.',
  },
  {
    band: 'Average (51-75%)',
    description:
      'Generally normalised and tolerated. Seek to improve sooner rather than later or risk further degradation.',
  },
  {
    band: 'Low (0-50%)',
    description: 'Inferior relational norm. Improve or risk significant relationship compromise.',
  },
]

export const relationalNormDescriptions: {
  metric: 'Integrity' | 'Solidarity' | 'Equality' | 'Commitment' | 'Agility' | 'Harmony'
  description: string
}[] = [
  { metric: 'Integrity', description: 'Always do what you promised to do. Honesty.' },
  { metric: 'Solidarity', description: 'Helping your partner benefits you. Mutuality.' },
  { metric: 'Equality', description: 'See each other as equals.  Fairness in dealings.' },
  {
    metric: 'Commitment',
    description: 'Provide required quality and quantity of resources for success.',
  },
  {
    metric: 'Agility',
    description: 'Adapting and aligning goals. Continuous improvement. Flexibility.',
  },
  { metric: 'Harmony', description: 'Values aligned. Chemistry and communication. Culture.' },
]
