import { yupResolver } from '@hookform/resolvers/yup'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CreateContractForm } from '.'
import Button from '../../common/inputs/Button'
import TextInput from '../../common/inputs/TextInput'
import { ContractFormContextModel } from './data/models'
import FormHeader from './FormHeader'

interface PrimaryContactValues {
  firstName: string
  lastName: string
  email: string
  organisationName: string
}

const PrimaryContactDetailsSchema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().required().label('Email').email('Invalid email address'),
  organisationName: yup.string().required().label('Organisation Name'),
})

interface PrimaryContactDetailsProps {
  onNextPageClick: () => void
}

const PrimaryContactDetails: React.FunctionComponent<PrimaryContactDetailsProps> = ({
  onNextPageClick,
}) => {
  const context = useContext<ContractFormContextModel | undefined>(CreateContractForm!)
  const { formData, setFormData } = context!

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PrimaryContactValues>({
    resolver: yupResolver(PrimaryContactDetailsSchema),
    defaultValues: {
      ...formData.primaryContactDetails,
    },
  })

  const onSubmit = (data: PrimaryContactValues) => {
    setFormData({
      ...formData,
      primaryContactDetails: data,
    })

    onNextPageClick()
  }

  return (
    <Grid2 container>
      <Grid2 xs={12} lg={3}>
        <FormHeader
          title="Primary Contact Details"
          mobileTitle="Add Contract"
          mobileSubTitle="Primary Contact Details"
          details="Input your contact details"
        />
      </Grid2>
      <Grid2 xs={12} lg={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <TextInput
                {...field}
                errorMessage={errors.firstName?.message}
                placeholder="First Name"
                label="First Name"
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <TextInput
                {...field}
                errorMessage={errors.lastName?.message}
                label="Last Name"
                fullWidth
                placeholder="Last Name"
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextInput
                {...field}
                errorMessage={errors.email?.message}
                placeholder="your@email.com"
                label="Email"
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="organisationName"
            render={({ field }) => (
              <TextInput
                {...field}
                errorMessage={errors.organisationName?.message}
                placeholder="Organisation"
                label="Organisation Name"
                fullWidth
              />
            )}
          />
          <Button type="submit">Continue</Button>
        </form>
      </Grid2>
    </Grid2>
  )
}

export default PrimaryContactDetails
