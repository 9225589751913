import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { CreateContractForm } from '.'
import Button from '../../common/inputs/Button'
import LargeButton from '../../common/inputs/LargeButton'
import { ContractFormContextModel, ContractRole } from './data/models'
import { ReactComponent as BuyerIcon } from '../../../assets/Icon-Buyer.svg'
import { ReactComponent as SupplierIcon } from '../../../assets/Icon-Supplier.svg'
import { ReactComponent as EnablerIcon } from '../../../assets/Icon-Enabler.svg'
import Grid2 from '@mui/material/Unstable_Grid2'
import FormHeader from './FormHeader'
import HoverMessage from '../../common/text/HoverMessage'

interface SelectPrimaryRoleProps {
  onNextPageClick: () => void
}

const SelectPrimaryRole: React.FunctionComponent<SelectPrimaryRoleProps> = ({
  onNextPageClick,
}) => {
  const context = useContext<ContractFormContextModel | undefined>(CreateContractForm!)
  const { formData, setFormData } = context!

  const [role, SetRole] = useState<ContractRole | undefined>(
    !!formData.contractRole?.selectedContractRole
      ? formData.contractRole.selectedContractRole
      : undefined
  )

  const isSelected = (buttonRole: ContractRole) => buttonRole === role

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12}>
        <FormHeader
          title="Primary Contact Details"
          mobileTitle="Add Contract"
          mobileSubTitle="Primary contact role"
          details="Select your role in this commercial agreement"
        />
      </Grid2>
      <Grid2 md={4} xs={12}>
        <HoverMessage message="Organisation or entity identified in a contract as the party to provide commodities or contractual services pursuant to a contract to the Buyer.">
          <LargeButton
            color={isSelected(ContractRole.Supplier) ? 'primary' : 'white'}
            onClick={() => SetRole(ContractRole.Supplier)}
            label="Supplier"
            fullWidth
            icon={<SupplierIcon />}
          />
        </HoverMessage>
      </Grid2>
      <Grid2 md={4} xs={12}>
        <HoverMessage message="Organisation involved in improving or adding value to contracts and other commercial agreements. Generally, consultants, facilitators, capability developers, trainers etc, that are engaged by Buyer and Suppliers.">
          <LargeButton
            color={isSelected(ContractRole.Enabler) ? 'primary' : 'white'}
            onClick={() => SetRole(ContractRole.Enabler)}
            label="Enabler"
            fullWidth
            icon={<EnablerIcon />}
          />
        </HoverMessage>
      </Grid2>
      <Grid2 md={4} xs={12}>
        <HoverMessage message="Organisation or entity identified in a contract as the party to receive commodities or contractual services pursuant to a contract from the Supplier.">
          <LargeButton
            color={isSelected(ContractRole.Buyer) ? 'primary' : 'white'}
            onClick={() => SetRole(ContractRole.Buyer)}
            label="Buyer"
            fullWidth
            icon={<BuyerIcon />}
          />
        </HoverMessage>
      </Grid2>
      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            disabled={!role}
            onClick={() => {
              setFormData({
                ...formData,
                contractRole: {
                  selectedContractRole: role!,
                },
              })
              onNextPageClick()
            }}
            fullWidth={false}
          >
            Continue
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default SelectPrimaryRole
