import * as yup from 'yup'

const loginSchema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('First Name'),
  email: yup.string().required().label('Email').email('Invalid email address'),
  password: yup
    .string()
    .required()
    .min(6, ({ min }) => `Password must be at least ${min} characters`)
    .max(25, ({ max }) => `Password must be no more than ${max} characters`)
    .matches(
      /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{6,25}$/,
      'Password must contain at least one number, one lowercase letter, one uppercase letter, and one special character'
    )
    .label('Password'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .label('Password Confirmation'),
})

export default loginSchema
