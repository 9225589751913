import { Box, Fade, Stack, Typography, useTheme } from '@mui/material'
import AccordionWrapper from '../../../common/containers/AccordionWrapper'
import { relationalNormIntensityLevelDescriptions } from './descriptionText'
import React from 'react'
import RainbowBar from '../../../common/logos/RainbowBar'

interface RelationalNormIntensityLevelsProps {
  isReportView?: boolean
}

const RelationalNormIntensityLevels: React.FC<RelationalNormIntensityLevelsProps> = ({
  isReportView = false,
}) => {
  const theme = useTheme()

  const [showHeaderRainbow, setShowHeaderRainbow] = React.useState(isReportView ? false : true)

  return (
    <Box
      bgcolor={theme.palette.common.white}
      borderRadius={2}
      px={2}
      pt={2}
      pb={isReportView ? 2 : 0}
    >
      <Typography variant="h2">
        <b>Relational Norm Intensity Levels</b>
      </Typography>
      <Fade in={showHeaderRainbow}>
        <Box py={2}>
          <RainbowBar />
        </Box>
      </Fade>
      <AccordionWrapper
        onChange={(isOpen) => setShowHeaderRainbow(!isOpen)}
        showAsAccordion={!isReportView}
      >
        <Stack direction={'row'} spacing={2}>
          <RainbowBar isVertical />
          <Box>
            {relationalNormIntensityLevelDescriptions.map((x) => (
              <Box key={x.band}>
                <Typography variant="h3" pt={2} pb={1}>
                  {x.band}
                </Typography>
                <Typography>{x.description}</Typography>
              </Box>
            ))}
          </Box>
        </Stack>
      </AccordionWrapper>
    </Box>
  )
}

export default RelationalNormIntensityLevels
