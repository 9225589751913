import { Box, Divider, Grid, Typography } from '@mui/material'
import DualCircularIndicator from '../../common/outputs/DualCircularIndicator'
import CircularIndicator from '../../common/outputs/CircularIndicator'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

export type TrustProps = {
  coreValue: number
  dynamicValue: number
  combinedValue: number
}

const Trust = ({ coreValue, dynamicValue, combinedValue }: TrustProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>
      <Grid container spacing={0} mt={18}>
        <Grid item xs={2}>
          <DualCircularIndicator
            outerValue={dynamicValue}
            innerValue={coreValue}
            label={combinedValue + '%'}
            outerColor="secondary.main"
            innerColor="secondary.dark"
            scale={2.2}
            labelStyle={{
              fontSize: '35px',
            }}
          />
        </Grid>
        <Grid item xs={10} mt={3}>
          <Typography fontSize={45} fontWeight={900}>
            TRUST
          </Typography>
          <Typography fontSize={24} mt={2}>
            This gauges the level of trust in the commercial relationship.
          </Typography>
          <Grid container spacing={7} mt={1}>
            {levelConfig.map((x) => (
              <Grid item xs={3} key={x.heading}>
                <Level {...x} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={10}>
          <Divider />
        </Grid>

        <Grid container spacing={4} mt={7}>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="left" mb={1}>
              <CircularIndicator
                value={coreValue}
                label={coreValue + '%'}
                color="secondary.main"
                scale={2.2}
                labelStyle={{
                  fontSize: '35px',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography fontSize={25} fontWeight={900} mt={2}>
              CORE TRUST
            </Typography>
            <Typography mt={1} fontSize={16}>
              Core trust is defined by the integrity, solidarity and equality qualities of the
              relationship. These qualities are built over time and don’t vary too much over the
              contract term.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="center" mb={1}>
              <CircularIndicator
                value={dynamicValue}
                label={dynamicValue + '%'}
                color="secondary.main"
                scale={2.2}
                labelStyle={{
                  fontSize: '35px',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography fontSize={25} fontWeight={900} mt={2}>
              DYNAMIC TRUST
            </Typography>
            <Typography mt={1} fontSize={16}>
              Dynamic trust is defined by the commitment, agility and harmony qualities of the
              relationship. These are more fluid than the core trust qualities and often change and
              react to external forces and changes in the commercial environment.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Copyright pageNumber={7} />
    </Box>
  )
}

export type LevelProps = {
  heading: string
  description: string
}

const levelConfig: LevelProps[] = [
  {
    heading: 'Low (0-50%)',
    description: 'Relationship distrust is endemic. Likely to be toxic. Requires major surgery.',
  },
  {
    heading: 'Average (51-75%)',
    description:
      'Some trust but could deteriorate. Invest in relationship before it becomes toxic.',
  },
  {
    heading: 'High (76-89%)',
    description:
      'Most commercial relationships sit within this range. Look for opportunities for improvement.',
  },
  {
    heading: 'Elite (90%+ )',
    description:
      'This is where magic happens. Environment is right for maximum performance.Monitor and maintain.',
  },
]

const Level = ({ heading, description }: LevelProps) => {
  return (
    <>
      <Typography fontSize={25} fontWeight={900}>
        {heading}
      </Typography>
      <Typography mt={'10px'} fontSize={16}>
        {description}
      </Typography>
    </>
  )
}

export default Trust
