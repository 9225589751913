import { Box, Grid, Typography } from '@mui/material'
import Copyright from './Copyright'

const Definitions = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Typography fontSize={45} fontWeight={900}>
        DEFINITIONS
      </Typography>
      <Grid container spacing={7} mt={'0.01px'}>
        {Content.map((colContent) => (
          <Grid item xs={4}>
            {colContent.map((rowContent) => (
              <Definition {...rowContent} key={rowContent.heading} />
            ))}
          </Grid>
        ))}
      </Grid>
      <Copyright pageNumber={13} />
    </Box>
  )
}

const Content: DefinitionProps[][] = [
  [
    {
      heading: 'Buyer',
      description:
        'The organisation or entity identified in a contract as the party to receive commodities or contractual services pursuant to a contract or other contractual instrument from the Supplier under the Contract.',
    },
    {
      heading: 'Supplier',
      description:
        'The organisation or entity identified in a contract as the party whose tender is accepted by the Buyer and identified in the contract as the party to provide commodities or contractual services pursuant to a contract or other contractual instrument to the Buyer under the Contract.',
    },
    {
      heading: 'Enabler',
      description:
        'Organisation involved in improving or adding value to contracts and other commercial agreements. Generally, consultants, capability developers, trainers etc, that are engaged by Buyer and Suppliers.',
    },
    {
      heading: 'Personnel',
      description:
        'Buyer and Supplier staff with a leadership or management role, assigned to the commercial agreement.',
    },
  ],
  [
    {
      heading: 'Personnel roles',
      description:
        'Group personnel names and role in contract (Executive, Manager, Lead). For example, account managers are classified as Executive, contract managers are classified as Management and senior engineers are classified as Lead.',
    },
    {
      heading: 'Relational Impact Profile',
      description:
        'An infographic that represents the Buyer and/ or Supplier contract team Relational qualities.',
    },
    {
      heading: 'Group Relational Impact Profile',
      description:
        'An infographic that represents a single organisation’s relational qualities whether it be the Buyer or Supplier contract team.',
    },
    {
      heading: 'Core trust',
      description:
        'Core trust is defined by the integrity, solidarity and equality qualities of the relationship. These qualities are built over time and don’t vary too much over the contract term.',
    },
  ],
  [
    {
      heading: 'Dynamic Trust',
      description:
        'Dynamic trust is defined by the commitment, agility and harmony qualities of the relationship. These are more fluid than the core trust qualities and often change and react to external forces and changes in the commercial environment.',
    },
    {
      heading: 'Industry Averages',
      description:
        'Benchmark data detailing trends and averages for the selected relational contract criteria. Displayed trends are based on Contract Harmonics database as well as industry benchmarks and research.',
    },
    {
      heading: 'Prime Contact',
      description:
        'Person representing the organisation that is responsible in managing the assessment of this particular contract. The role includes inputting key contract information, as well as assigning and managing key personnel to the undertake relational contract assessments. The prime contact will also have access to all generated reports from Contract Harmonics.',
    },
  ],
]

type DefinitionProps = {
  heading: string
  description: string
}

const Definition = ({ heading, description }: DefinitionProps) => {
  return (
    <Box>
      <Typography mt={3} fontSize={30} fontWeight={900}>
        {heading}
      </Typography>
      <Typography mt={1} fontSize={18}>
        {description}
      </Typography>
    </Box>
  )
}

export default Definitions
