import {
  Button as MuiButton,
  ButtonGroup,
  Container,
  Typography,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  HEADER_SPACING,
  HEADER_SPACING_MOBILE,
  HEADING_SPACING,
  LEFT_SPACING,
} from '../../constants/spacingConstants'
import { QuestionDto } from '../../data/api/models'
import { surveyApi } from '../../data/myApis'
import useApi from '../../hooks/useApi'
import Button from '../common/inputs/Button'
import LoadingButton from '../common/inputs/LoadingButton'
import SurveyCompletion from './SurveyCompletion'
import useSurvey from './useSurvey'
import Chip from '../common/text/Chip'
import { AnonymousSoloAssessmentContext } from '../../contexts/AnonymousSoloAssessmentContext'

interface SurveyProps {
  questions: QuestionDto[]
  contractName: string
  isSoloContract?: boolean
  unregistered?: boolean
}

const Survey: React.FC<SurveyProps> = ({
  questions,
  contractName,
  isSoloContract,
  unregistered,
}) => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const { setAnswers } = useContext(AnonymousSoloAssessmentContext)
  const params = useParams()
  const navigate = useNavigate()

  const inviteToken = params.inviteToken as string
  const contractId = params.contractId as string

  const { currentQuestion, answers, questionNumber, isLast, addAnswer, isComplete } =
    useSurvey(questions)

  const { callApi: callApiForToken, state: stateApiForToken } = useApi(
    surveyApi.apiSurveySubmitSurveyPost,
    useCallback(() => setIsSubmitted(true), [])
  )

  const { callApi, state } = useApi(
    surveyApi.apiSurveySubmitSingleUserContractSurveyPost,
    useCallback(() => setIsSubmitted(true), [])
  )

  const submitSurvey = useCallback(
    () => {
      if (unregistered) {
        setAnswers(answers)
        navigate('/anonymous/contract')
      } else if (isSoloContract) {
        callApi({ answers, contractId })
      } else {
        callApiForToken({ answers, inviteToken })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answers, inviteToken, isSoloContract]
  )

  const { palette } = useTheme()

  const [answer, setAnswer] = useState<number | undefined>(undefined)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleAnswerChange = (answer: number) => setAnswer(answer)

  const completeQuestion = () => {
    if (answer) {
      addAnswer(currentQuestion.questionId, answer)
      if (!isLast) setAnswer(undefined)
    }
  }

  useEffect(() => {
    if (isComplete && !isSubmitted && state !== 'loading' && stateApiForToken !== 'loading') {
      submitSurvey()
      !isSoloContract && setIsSubmitted(true)
    }
  }, [
    answers,
    callApi,
    inviteToken,
    isComplete,
    isSoloContract,
    isSubmitted,
    state,
    stateApiForToken,
    submitSurvey,
  ])

  return isSubmitted ? (
    <SurveyCompletion />
  ) : (
    <Box
      display="flex"
      justifyContent="flex-start"
      px={2}
      sx={{ paddingLeft: { lg: LEFT_SPACING } }}
      pt={isMobile ? HEADER_SPACING_MOBILE : HEADER_SPACING}
    >
      <Container
        maxWidth="sm"
        disableGutters
        sx={{ margin: { lg: 0 }, display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="h2">{contractName}</Typography>
        <Typography variant="h1" mb={isMobile ? 4 : HEADING_SPACING}>
          Relational Assessment
        </Typography>
        <Box display="flex" justifyContent="flex-start" alignItems="center" mb={HEADING_SPACING}>
          <Typography variant="subtitle1">QUESTION</Typography>
          <Chip label={`${questionNumber} OF ${questions.length}`} />
        </Box>
        <Typography variant="h1" mb={HEADING_SPACING}>
          {currentQuestion.questionText}
        </Typography>
        <Box my={2}>
          <ButtonGroup
            fullWidth
            sx={{ backgroundColor: palette.common.white, borderRadius: 25 }}
            variant="text"
            color="inherit"
          >
            {[1, 2, 3, 4, 5].map((x) => (
              <MuiButton
                onClick={() => handleAnswerChange(x)}
                variant={x === answer ? 'contained' : undefined}
                color={x === answer ? 'primary' : undefined}
                key={x}
                disableElevation
              >
                {x}
              </MuiButton>
            ))}
          </ButtonGroup>
          <Box display="flex" justifyContent="space-between" mt={1} mx={0.5}>
            <Typography variant="subtitle1" align="center">
              Not at all
              <br />
              like this
            </Typography>
            <Typography variant="subtitle1" align="center">
              Exactly
              <br />
              like this
            </Typography>
          </Box>
        </Box>
        <Box mt={2} sx={{ float: 'right' }}>
          {!isLast && !isComplete ? (
            <Button onClick={completeQuestion} disabled={!answer} fullWidth={false}>
              Next
            </Button>
          ) : (
            <LoadingButton
              fullWidth={false}
              onClick={isComplete ? submitSurvey : completeQuestion}
              loading={state === 'loading' || stateApiForToken === 'loading'}
              disabled={!answer}
            >
              Submit Survey
            </LoadingButton>
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default Survey
