import { Box, Theme, Typography, useTheme } from '@mui/material'
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Bar,
  Line,
  Legend,
  CartesianGrid,
} from 'recharts'
import { capitaliseFirstLetter } from '../../../utils/stringUtils'

interface ContractProfileGraphProps {
  dataPoints: { metric: string; buyer: number; supplier: number; combined: number }[]
  isSmall?: boolean
  isReportView?: boolean
}

const ContractProfileGraph: React.FC<ContractProfileGraphProps> = ({
  dataPoints,
  isSmall = false,
  isReportView = false,
}) => {
  const theme = useTheme()

  const innerChartContent = (theme: Theme, isSmall = false, isReportView = false) => (
    <>
      <Bar
        dataKey="combined"
        fill="#C0E1D7"
        radius={100}
        maxBarSize={isSmall ? 20 : 35}
        opacity={0.75}
        isAnimationActive={false}
      />
      <CartesianGrid strokeDasharray="3 8" vertical={false} />
      <XAxis
        dataKey="metric"
        axisLine={false}
        tickLine={false}
        type="category"
        tickMargin={5}
        minTickGap={-100}
        fontSize={isSmall ? 10 : 14}
        tickFormatter={(t) => (t === 'Commitment' ? 'Commit.' : t)}
      />
      <YAxis
        max={100}
        ticks={[20, 40, 60, 80, 100]}
        axisLine={false}
        tickLine={false}
        fontSize={isSmall ? 12 : 14}
        tickMargin={0}
      />
      {!isReportView && (
        <RechartsTooltip
          formatter={(value: unknown, name: string) => [value, capitaliseFirstLetter(name)]}
        />
      )}
      <Legend
        verticalAlign="top"
        align="right"
        iconType={isReportView ? undefined : 'circle'}
        formatter={(label) => (
          <Typography component={'span'} color={theme.palette.common.black}>
            {capitaliseFirstLetter(label)}
          </Typography>
        )}
      />
      <Line
        isAnimationActive={false}
        type="monotone"
        dataKey="supplier"
        fill={theme.palette.primary.main}
        stroke={theme.palette.primary.main}
        strokeWidth={isSmall ? 2 : 3}
      />
      <Line
        isAnimationActive={false}
        type="monotone"
        dataKey="buyer"
        fill={theme.palette.dataBlue.main}
        stroke={theme.palette.dataBlue.main}
        strokeWidth={isSmall ? 2 : 3}
      />
    </>
  )

  return (
    <Box height={1} ml={-6}>
      {isReportView ? (
        <ComposedChart width={800} height={550} data={dataPoints}>
          {innerChartContent(theme, isSmall, isReportView)}
        </ComposedChart>
      ) : (
        <ResponsiveContainer width="100%" maxHeight={isSmall ? 250 : 500}>
          <ComposedChart data={dataPoints}>
            {innerChartContent(theme, isSmall, isReportView)}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  )
}

export default ContractProfileGraph
