import { Box, Grid, Typography } from '@mui/material'
import { ReactNode } from 'react'
import MetricIcon, { MetricType } from '../ContractDashboard/MetricIcon'
import theme from '../../../theme'
import Copyright from './Copyright'

const Norms = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Typography fontSize={30} lineHeight={1.2} fontWeight={900}>
        INTRODUCING THE SIX RELATIONAL NORMS
      </Typography>
      <Grid container spacing={7} mt={11}>
        {normConfig.map((x) => (
          <Grid item xs={4} key={x.name}>
            <Norm {...x} />
          </Grid>
        ))}
      </Grid>
      <Copyright pageNumber={3} />
    </Box>
  )
}

type NormProps = {
  icon: ReactNode
  name: string
  description: string
}

const Norm = ({ icon, name, description }: NormProps) => {
  return (
    <>
      {icon}
      <Typography fontSize={27} fontWeight={900}>
        {name}
      </Typography>
      <Typography mt={'12px'} fontSize={17}>
        {description}
      </Typography>
    </>
  )
}

const NormIcon = (metric: MetricType) => {
  return (
    <MetricIcon
      metric={metric}
      filled={true}
      width={50}
      height={50}
      color={theme.palette.primary.main}
    />
  )
}

const normConfig: NormProps[] = [
  {
    icon: NormIcon('Integrity'),
    name: 'Integrity',
    description:
      'Being honest and displaying consistent and uncompromised adherence to principles and values. Team members do what they say! It’s about ensuring that interactions line up with stated intentions or at the least, reducing the difference between what the communicated intentions are, and the actions. Perceived as performing with uprightness to the terms and spirit of the commercial agreement.',
  },
  {
    icon: NormIcon('Solidarity'),
    name: 'Solidarity',
    description:
      'United with common shared goals and objectives to strive for. The parties are one, therefore a party’s success benefits the other. Organisations share risk and rewards, as well as share the pains and gains. Standing side by side as one unit in the face of adversity. Parties act in good faith, with mutual trust and goodwill, understanding that success is tied to each other. Loyalty, cooperation. Talk first, look at contract last. The quality of solidarity and reciprocity is a powerful motivator for driving, developing, and maintaining a sustainable commercial partnership.',
  },
  {
    icon: NormIcon('Equality'),
    name: 'Equality',
    description:
      'Each party’s interests and requirements are valued and met to a practical level as opposed to just one party’s needs overshadowing the relationship. Fairness. Balance of power between parties or at least, where one party has more power whether it be economic, political, or legal, they restrain its use. Mutuality. Organisations see each other at the same level, where any issues or problems are resolved jointly with a collaborative, transparent, respectful approach.',
  },
  {
    icon: NormIcon('Commitment'),
    name: 'Commitment',
    description:
      'Appropriate efforts for required outcomes are committed to the deal. Party’s conduct themselves and their interactions with professionalism, and with care and consideration. Contribution of appropriate quality and quantity of resources to the agreement including skills, maturity, capability, experience, personnel, processes, and systems.',
  },
  {
    icon: NormIcon('Agility'),
    name: 'Agility',
    description:
      'Having a fluid agreement that is easily revisable to adapt to dynamic requirements of the partnership. The parties display qualities of flexibility to accommodate changing market, business, or counterparty requirements. Embracing a philosophy and approach of continuous improvement with regular reviews and adjustments, regular alignment of contract objectives with: KPIs, scope, deliverables. governing contract schedules, resources, personnel, etc.',
  },
  {
    icon: NormIcon('Harmony'),
    name: 'Harmony',
    description:
      'Relational Harmony helps synchronise the key elements for a good commercial relationship. It helps overcome friction caused by misalignment of goals and approaches used by the parties to an agreement. Personnel get along well and work as a cohesive, collaborative group. With similarly aligned values and beliefs, not only individually, but also as an organisational unit. The composition and quality of team chemistry is generally complimentary and supportive of a unified goal. Good communication is embraced and practiced for both operational and strategic.',
  },
]

export default Norms
