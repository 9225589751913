import { Box, Typography, Grid } from '@mui/material'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

const BuildingTrust = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>
      <Grid container mt={15} spacing={7}>
        <Grid item xs={6}>
          <Typography fontSize={45} fontWeight={900}>
            BUILDING TRUST
          </Typography>
          <Typography fontSize={25} mt={3}>
            The best approach to build trust is to identify areas of weakness in the relational
            profile, using the Relational Impact Profile as a starting point. Identify the
            relational norms that are compromising trust and focus on their improvement.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={45} fontWeight={900}>
            TRUST BASE
          </Typography>
          <Typography fontSize={25} mt={3}>
            Commercial relationships should strive for Collaborative Trust as its very nature is one
            that boosts relationship quality and maximises commercial results. Very rarely is this
            strong in commercial relationships, however the report’s evaluation offers a starting
            point and direction to achieve this highly sought-after trust base.
          </Typography>
        </Grid>
      </Grid>
      <Copyright pageNumber={10} />
    </Box>
  )
}

export default BuildingTrust
