/* tslint:disable */
/* eslint-disable */
/**
 * api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
import { AddUserToContractRequest } from '../models'
import { BeginUnregisteredSoloContractCommand } from '../models'
import { BeginUnregisteredSoloContractResult } from '../models'
import { ContractDto } from '../models'
import { ContractOverview } from '../models'
import { ContractSummary } from '../models'
import { CreateContractCommand } from '../models'
import { CreateSingleUserContractRequest } from '../models'
import { CreateSingleUserContractResponse } from '../models'
import { EditContractCommand } from '../models'
import { GetContractByIdRequest } from '../models'
import { GetContractByInviteTokenRequest } from '../models'
import { GetContractResponse } from '../models'
import { GetContractsAvailabilityResponse } from '../models'
import { GetSoloContractSummaryQuery } from '../models'
import { RemoveUserFromContractRequest } from '../models'
import { ResendInviteEmailRequest } from '../models'
import { Unit } from '../models'
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AddUserToContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractAddUserPost: async (
      body?: AddUserToContractRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/addUser`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {BeginUnregisteredSoloContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractBeginUnregisteredSoloPost: async (
      body?: BeginUnregisteredSoloContractCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/beginUnregisteredSolo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetContractByIdRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractByIdPost: async (
      body?: GetContractByIdRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/contractById`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetContractByInviteTokenRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractByInviteTokenPost: async (
      body?: GetContractByInviteTokenRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/contractByInviteToken`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractPost: async (
      body?: CreateContractCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/contract`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractsAvailabilityGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/contracts/availability`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/contracts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {EditContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractEditContractPost: async (
      body?: EditContractCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/editContract`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetSoloContractSummaryQuery} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractGetSoloContractSummaryPost: async (
      body?: GetSoloContractSummaryQuery,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/getSoloContractSummary`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RemoveUserFromContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractRemoveUserPost: async (
      body?: RemoveUserFromContractRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/removeUser`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ResendInviteEmailRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractResendInviteTokenPost: async (
      body?: ResendInviteEmailRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/resendInviteToken`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateSingleUserContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractSingleUserContractPut: async (
      body?: CreateSingleUserContractRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Contract/singleUserContract`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AddUserToContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractAddUserPost(
      body?: AddUserToContractRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractAddUserPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {BeginUnregisteredSoloContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractBeginUnregisteredSoloPost(
      body?: BeginUnregisteredSoloContractCommand,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BeginUnregisteredSoloContractResult>
    > {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractBeginUnregisteredSoloPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {GetContractByIdRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractContractByIdPost(
      body?: GetContractByIdRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractContractByIdPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {GetContractByInviteTokenRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractContractByInviteTokenPost(
      body?: GetContractByInviteTokenRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractOverview>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractContractByInviteTokenPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {CreateContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractContractPost(
      body?: CreateContractCommand,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractContractPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractContractsAvailabilityGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContractsAvailabilityResponse>
    > {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractContractsAvailabilityGet(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractContractsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContractResponse>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractContractsGet(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {EditContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractEditContractPost(
      body?: EditContractCommand,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Unit>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractEditContractPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {GetSoloContractSummaryQuery} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractGetSoloContractSummaryPost(
      body?: GetSoloContractSummaryQuery,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractSummary>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractGetSoloContractSummaryPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {RemoveUserFromContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractRemoveUserPost(
      body?: RemoveUserFromContractRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractRemoveUserPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {ResendInviteEmailRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractResendInviteTokenPost(
      body?: ResendInviteEmailRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractResendInviteTokenPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {CreateSingleUserContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiContractSingleUserContractPut(
      body?: CreateSingleUserContractRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSingleUserContractResponse>
    > {
      const localVarAxiosArgs = await ContractApiAxiosParamCreator(
        configuration
      ).apiContractSingleUserContractPut(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {AddUserToContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractAddUserPost(body?: AddUserToContractRequest, options?: any): AxiosPromise<void> {
      return ContractApiFp(configuration)
        .apiContractAddUserPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {BeginUnregisteredSoloContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractBeginUnregisteredSoloPost(
      body?: BeginUnregisteredSoloContractCommand,
      options?: any
    ): AxiosPromise<BeginUnregisteredSoloContractResult> {
      return ContractApiFp(configuration)
        .apiContractBeginUnregisteredSoloPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetContractByIdRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractByIdPost(
      body?: GetContractByIdRequest,
      options?: any
    ): AxiosPromise<ContractDto> {
      return ContractApiFp(configuration)
        .apiContractContractByIdPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetContractByInviteTokenRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractByInviteTokenPost(
      body?: GetContractByInviteTokenRequest,
      options?: any
    ): AxiosPromise<ContractOverview> {
      return ContractApiFp(configuration)
        .apiContractContractByInviteTokenPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractPost(body?: CreateContractCommand, options?: any): AxiosPromise<Unit> {
      return ContractApiFp(configuration)
        .apiContractContractPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractsAvailabilityGet(
      options?: any
    ): AxiosPromise<GetContractsAvailabilityResponse> {
      return ContractApiFp(configuration)
        .apiContractContractsAvailabilityGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractContractsGet(options?: any): AxiosPromise<GetContractResponse> {
      return ContractApiFp(configuration)
        .apiContractContractsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {EditContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractEditContractPost(body?: EditContractCommand, options?: any): AxiosPromise<Unit> {
      return ContractApiFp(configuration)
        .apiContractEditContractPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetSoloContractSummaryQuery} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractGetSoloContractSummaryPost(
      body?: GetSoloContractSummaryQuery,
      options?: any
    ): AxiosPromise<ContractSummary> {
      return ContractApiFp(configuration)
        .apiContractGetSoloContractSummaryPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RemoveUserFromContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractRemoveUserPost(
      body?: RemoveUserFromContractRequest,
      options?: any
    ): AxiosPromise<void> {
      return ContractApiFp(configuration)
        .apiContractRemoveUserPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ResendInviteEmailRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractResendInviteTokenPost(
      body?: ResendInviteEmailRequest,
      options?: any
    ): AxiosPromise<void> {
      return ContractApiFp(configuration)
        .apiContractResendInviteTokenPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateSingleUserContractRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiContractSingleUserContractPut(
      body?: CreateSingleUserContractRequest,
      options?: any
    ): AxiosPromise<CreateSingleUserContractResponse> {
      return ContractApiFp(configuration)
        .apiContractSingleUserContractPut(body, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
  /**
   *
   * @param {AddUserToContractRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractAddUserPost(body?: AddUserToContractRequest, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractAddUserPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {BeginUnregisteredSoloContractCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractBeginUnregisteredSoloPost(
    body?: BeginUnregisteredSoloContractCommand,
    options?: any
  ) {
    return ContractApiFp(this.configuration)
      .apiContractBeginUnregisteredSoloPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {GetContractByIdRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractContractByIdPost(body?: GetContractByIdRequest, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractContractByIdPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {GetContractByInviteTokenRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractContractByInviteTokenPost(
    body?: GetContractByInviteTokenRequest,
    options?: any
  ) {
    return ContractApiFp(this.configuration)
      .apiContractContractByInviteTokenPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {CreateContractCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractContractPost(body?: CreateContractCommand, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractContractPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractContractsAvailabilityGet(options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractContractsAvailabilityGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractContractsGet(options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractContractsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {EditContractCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractEditContractPost(body?: EditContractCommand, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractEditContractPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {GetSoloContractSummaryQuery} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractGetSoloContractSummaryPost(body?: GetSoloContractSummaryQuery, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractGetSoloContractSummaryPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {RemoveUserFromContractRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractRemoveUserPost(body?: RemoveUserFromContractRequest, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractRemoveUserPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {ResendInviteEmailRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractResendInviteTokenPost(body?: ResendInviteEmailRequest, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractResendInviteTokenPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {CreateSingleUserContractRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractApi
   */
  public apiContractSingleUserContractPut(body?: CreateSingleUserContractRequest, options?: any) {
    return ContractApiFp(this.configuration)
      .apiContractSingleUserContractPut(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
