import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import EnvVars from './envVars'
import LoginRoute from './routes/LoginRoute'
import SignupRoute from './routes/SingupRoute'
import { ThemeProvider } from '@mui/material'
import theme from './theme'
import AxiosInterceptors from './data/AxiosInterceptors'
import { SnackbarProvider } from 'notistack'
import EditAccountRoute from './routes/EditAccountRoute'
import UserContextProvider from './contexts/UserContextProvider'
import ContractDashboardRoute from './routes/ContractDashboardRoute'
import SurveyRoute from './routes/SurveyRoute'
import SurveyDetailsRoute from './routes/SurveyDetailsRoute'
import { CssBaseline } from '@mui/material'
import AddContractRoute from './routes/AddContractRoute'
import MyContractsRoute from './routes/MyContractsRoute'
import SelectPlanRoute from './routes/SelectPlanRoute'
import ChangePasswordRoute from './routes/ChangePasswordRoute'
import ResetPasswordRoute from './routes/ResetPasswordRoute'
import AddSingleUserContractRoute from './routes/AddSingleUserContractRoute'
import SurveyDetailsForSoloRoute from './routes/SurveyDetailsForSoloRoute'
import EditContractRoute from './components/contract/EditContract/EditContractRoute'
import PurchaseContractRoute from './components/contract/PurchaseContract/PurchaseContractRoute'
import TermsOfServiceRouter from './components/documents/TermsOfServiceRouter'
import { AnonymousSoloAssessmentContextProvider } from './contexts/AnonymousSoloAssessmentContext'
import AnonymousContractDashboardRoute from './routes/AnonymousContractDashboardRoute'

declare global {
  interface Window {
    _env_: EnvVars
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AxiosInterceptors>
            <UserContextProvider>
              <AnonymousSoloAssessmentContextProvider>
                <CssBaseline />
                <Routes>
                  <Route path="/" element={<MyContractsRoute />} />
                  <Route path="/login" element={<LoginRoute />} />
                  <Route path="/signup" element={<SignupRoute />} />
                  <Route path="/account/edit" element={<EditAccountRoute />} />
                  <Route path="/account/changepassword" element={<ChangePasswordRoute />} />
                  <Route
                    path="/account/resetpassword/:userId/:token"
                    element={<ResetPasswordRoute />}
                  />
                  <Route path="/contract/1/add/:page" element={<AddContractRoute />} />
                  <Route path="/contract/2/add" element={<AddSingleUserContractRoute />} />
                  <Route path="/edit/contract/:id" element={<EditContractRoute />} />
                  <Route path="/contracts" element={<MyContractsRoute />} />
                  <Route path="/swot/purchase/:id" element={<PurchaseContractRoute />} />
                  <Route path="/contract/:contractId" element={<ContractDashboardRoute />} />
                  <Route path="/assess/details/:inviteToken" element={<SurveyDetailsRoute />} />
                  <Route
                    path="/assess/details/solo/:contractId"
                    element={<SurveyDetailsForSoloRoute />}
                  />
                  <Route path="/assess/:inviteToken" element={<SurveyRoute />} />
                  <Route path="/assess/solo/:contractId" element={<SurveyRoute isSoloContract />} />
                  <Route path="/plans" element={<SelectPlanRoute />} />
                  <Route path="/tos" element={<TermsOfServiceRouter />} />
                  <Route
                    path="/anonymous/contract/2/add"
                    element={<AddSingleUserContractRoute unregistered />}
                  />
                  <Route
                    path="/anonymous/assess/solo"
                    element={<SurveyRoute isSoloContract unregistered />}
                  />
                  <Route path="/anonymous/contract" element={<AnonymousContractDashboardRoute />} />
                  <Route
                    path="/anonymous/assess/details"
                    element={<SurveyDetailsForSoloRoute unregistered />}
                  />
                  <Route path="*" element={<Navigate to="/" replace={true} />} />
                </Routes>
              </AnonymousSoloAssessmentContextProvider>
            </UserContextProvider>
          </AxiosInterceptors>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)
