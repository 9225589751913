import { Box, Typography, useTheme } from '@mui/material'
import MetricIcon from './MetricIcon'

export interface MetricIconsProps {
  metrics: {
    metric: 'Integrity' | 'Solidarity' | 'Equality' | 'Commitment' | 'Agility' | 'Harmony'
    percentage: number
  }[]
  color: 'primary' | 'dataBlue'
}

const MetricIcons: React.FC<MetricIconsProps> = ({ metrics, color }) => {
  const theme = useTheme()

  const shouldBeFilled = (percentage: number) => percentage >= 50

  return (
    <Box display="flex" justifyContent="space-between" ml={'-9px'}>
      {metrics.map((m, idx) => (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          key={m.metric}
          width={'58px'}
        >
          <Box textAlign="center" my={1}>
            <MetricIcon
              metric={m.metric}
              filled={shouldBeFilled(m.percentage)}
              width={40}
              height={40}
              color={theme.palette[color][m.percentage >= 70 ? 'main' : 'light']}
            />
          </Box>
          <Typography textAlign="center" variant="body1">{`${m.percentage}%`}</Typography>
          <Typography textAlign="center" variant="subtitle2" color="GrayText">
            {m.metric}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default MetricIcons
