import { Box, Stack, Typography, useTheme } from '@mui/material'
import AccordionWrapper from '../../../common/containers/AccordionWrapper'
import CircularIndicator from '../../../common/outputs/CircularIndicator'
import { relationalIndexDescriptions } from './descriptionText'

interface RelationalIndexProps {
  relationalIndex: number
  isReportView?: boolean
}

const RelationalIndex: React.FC<RelationalIndexProps> = ({ relationalIndex, isReportView }) => {
  const theme = useTheme()

  const relationalIndexDescription = relationalIndexDescriptions.find(
    (x) => relationalIndex > x.minValue
  )

  return (
    <Box
      bgcolor={theme.palette.common.white}
      borderRadius={2}
      px={2}
      pt={2}
      pb={isReportView ? 2 : 0}
    >
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignItems={'center'}
        mb={1}
      >
        <Typography variant="h2">
          <b>Relational Index</b>
        </Typography>
        <CircularIndicator
          value={relationalIndex}
          label={`${relationalIndex}%`}
          color={theme.palette.dataYellow.main}
        />
      </Stack>
      <AccordionWrapper showAsAccordion={!isReportView}>
        <Typography>
          This indicates where the commercial relationship lies on the Transactional-Relational
          spectrum.
        </Typography>
        <Box
          borderRadius={1}
          p={2}
          m={2}
          border={1}
          borderColor={theme.palette.dataYellow.main}
          bgcolor={theme.palette.dataYellow.light}
        >
          <Typography variant="h3">{relationalIndexDescription?.title}</Typography>
          <Typography>{relationalIndexDescription?.description}</Typography>
        </Box>
        <Typography>
          The more extreme the index value, the more intense are the transactional and relational
          qualities present in the commercial relationship.
        </Typography>
      </AccordionWrapper>
    </Box>
  )
}

export default RelationalIndex
