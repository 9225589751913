import { ContractModelsContractDetails } from '../../../../data/api/models'

export enum ContractRole {
  Buyer = 1,
  Supplier,
  Enabler,
}

export type EditContractFormContextModel = {
  formData: EditContractFormStateModel
  setFormData: React.Dispatch<React.SetStateAction<EditContractFormStateModel>>
}

export type EditContractFormStateModel = {
  contractDetails: ContractModelsContractDetails
}
