import { useParams } from 'react-router-dom'
import ResetPassword from '../components/account/resetPassword'
import FullscreenContainer from '../components/common/containers/FullscreenContainer'

const ResetPasswordRoute: React.FunctionComponent = () => {
  const params = useParams()

  const token = params.token as string
  const userId = params.userId as string

  return (
    <FullscreenContainer>
      <ResetPassword token={token} userId={userId} />
    </FullscreenContainer>
  )
}

export default ResetPasswordRoute
