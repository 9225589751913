import * as yup from 'yup'

const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required().label('Old Password'),
  newPassword: yup
    .string()
    .required()
    .label('New Password')
    .notOneOf([yup.ref('oldPassword')], 'New password must be different from old password')
    .matches(
      /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{6,25}$/,
      'Password must contain at least one number, one lowercase letter, one uppercase letter, and one special character'
    ),
  confirmPassword: yup
    .string()
    .required()
    .label('New Password')
    .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
})

export default changePasswordSchema
