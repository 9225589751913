import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { Container } from '@mui/system'
import React, { useEffect } from 'react'
import { HEADER_SPACING, HEADER_SPACING_MOBILE } from '../../../constants/spacingConstants'
import { contractApi } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import LoadingContainer from '../../common/containers/LoadingContainer'
import ContractList from './ContractList'

const MyContracts: React.FunctionComponent = () => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))
  const { callApi, data, state } = useApi(contractApi.apiContractContractsGet)

  useEffect(() => {
    callApi()
    // eslint-disable-next-line
  }, [])
  return (
    <LoadingContainer
      isLoading={state === 'loading' || state === 'idle'}
      isErrored={state === 'errored'}
    >
      <Container
        maxWidth="lg"
        sx={{
          paddingX: '24px',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          paddingTop={isMobile ? HEADER_SPACING_MOBILE : HEADER_SPACING}
          mb={4}
        >
          <Typography variant="h1">My Contracts</Typography>
          <Typography variant="h6">
            {data?.contractCreationAvailability?.canCreate === false
              ? '0 remaining assessments'
              : `${data?.contractCreationAvailability?.currentContractsCount} of ${data?.contractCreationAvailability?.totalContractsLimit} assessments`}
          </Typography>
        </Box>
        <ContractList
          canCreate={data?.contractCreationAvailability.canCreate}
          contracts={data?.contractSummaryList ?? []}
          refreshContracts={callApi}
        />
      </Container>
    </LoadingContainer>
  )
}

export default MyContracts
