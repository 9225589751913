import { useCallback, useState } from 'react'
import { AnswerDto, QuestionDto } from '../../data/api/models'

const useSurvey = (questions: QuestionDto[]) => {
  const [questionIndex, setQuestionIndex] = useState(0)

  const [answers, setAnswers] = useState<AnswerDto[]>([])

  const currentQuestion = questions[questionIndex]

  const questionNumber = questionIndex + 1

  const isLast = questionNumber === questions.length

  const isComplete = answers.length === questions.length

  const addAnswer = useCallback(
    (questionId: number, answer: number) => {
      if (!isComplete) {
        setAnswers((s) => [...s, { questionId, answer }])
        setQuestionIndex((x) => Math.min(++x, questions.length - 1))
      }
    },
    [isComplete, questions.length]
  )

  return { answers, currentQuestion, isLast, questionNumber, addAnswer, isComplete }
}

export default useSurvey
