import { Box } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import theme from '../../../../theme'
import { ContractSummary } from '../../../../data/api/models'
import {
  SingleUserDashboardViewPageOne,
  SingleUserDashboardViewPageTwo,
  SingleUserDashboardViewPageThree,
} from './SingleUserDashboardView'

const HiddenSingleUserReport = (contract: ContractSummary) => {
  return (
    <Box display={'none'} width="100%" height="100%" id="print">
      <Page>
        <SingleUserDashboardViewPageOne {...contract} />
      </Page>
      <Page>
        <SingleUserDashboardViewPageTwo {...contract} />
      </Page>
      <Page>
        <SingleUserDashboardViewPageThree />
      </Page>
    </Box>
  )
}

const Page: FC<PropsWithChildren> = (props: PropsWithChildren) => {
  const pageStyles = {
    width: 500,
    height: 705,
    bgcolor: theme.palette.background.grey,
  }

  return (
    <Box sx={pageStyles} className="page">
      {props.children}
    </Box>
  )
}

export default HiddenSingleUserReport
