import LogoPath, { LogoPathProps } from './LogoPath'

type LogoProps = LogoPathProps & {
  width?: number | string
  height?: number | string
}

const Logo: React.FC<LogoProps> = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 128 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LogoPath fill={fill} />
    </svg>
  )
}

export default Logo
