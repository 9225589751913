/* tslint:disable */
/* eslint-disable */
/**
 * api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
import { CreateAccountWithSoloContractCommand } from '../models'
import { EditAccountRequest } from '../models'
import { ForgotPasswordRequest } from '../models'
import { LoginRequest } from '../models'
import { RegisterRequest } from '../models'
import { ResetPasswordRequest } from '../models'
import { TokenResponse } from '../models'
import { UpdatePasswordRequest } from '../models'
import { UserDto } from '../models'
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EditAccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountEditAccountPost: async (
      body?: EditAccountRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/editAccount`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ForgotPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountForgotPasswordPost: async (
      body?: ForgotPasswordRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/forgotPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountGetUserGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/getUser`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {LoginRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountLoginPost: async (body?: LoginRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RegisterRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountRegisterPost: async (
      body?: RegisterRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/register`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateAccountWithSoloContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountRegisterWithContractPost: async (
      body?: CreateAccountWithSoloContractCommand,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/registerWithContract`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountResetPasswordPost: async (
      body?: ResetPasswordRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/resetPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdatePasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountUpdatePasswordPost: async (
      body?: UpdatePasswordRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Account/updatePassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = new URLSearchParams(query).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || ''

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EditAccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountEditAccountPost(
      body?: EditAccountRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountEditAccountPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {ForgotPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountForgotPasswordPost(
      body?: ForgotPasswordRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountForgotPasswordPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountGetUserGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountGetUserGet(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {LoginRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountLoginPost(
      body?: LoginRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountLoginPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {RegisterRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountRegisterPost(
      body?: RegisterRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountRegisterPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {CreateAccountWithSoloContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountRegisterWithContractPost(
      body?: CreateAccountWithSoloContractCommand,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountRegisterWithContractPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountResetPasswordPost(
      body?: ResetPasswordRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountResetPasswordPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {UpdatePasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAccountUpdatePasswordPost(
      body?: UpdatePasswordRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await AccountApiAxiosParamCreator(
        configuration
      ).apiAccountUpdatePasswordPost(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {EditAccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountEditAccountPost(body?: EditAccountRequest, options?: any): AxiosPromise<void> {
      return AccountApiFp(configuration)
        .apiAccountEditAccountPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ForgotPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountForgotPasswordPost(body?: ForgotPasswordRequest, options?: any): AxiosPromise<void> {
      return AccountApiFp(configuration)
        .apiAccountForgotPasswordPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountGetUserGet(options?: any): AxiosPromise<UserDto> {
      return AccountApiFp(configuration)
        .apiAccountGetUserGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {LoginRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountLoginPost(body?: LoginRequest, options?: any): AxiosPromise<TokenResponse> {
      return AccountApiFp(configuration)
        .apiAccountLoginPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RegisterRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountRegisterPost(body?: RegisterRequest, options?: any): AxiosPromise<TokenResponse> {
      return AccountApiFp(configuration)
        .apiAccountRegisterPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateAccountWithSoloContractCommand} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountRegisterWithContractPost(
      body?: CreateAccountWithSoloContractCommand,
      options?: any
    ): AxiosPromise<TokenResponse> {
      return AccountApiFp(configuration)
        .apiAccountRegisterWithContractPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountResetPasswordPost(body?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
      return AccountApiFp(configuration)
        .apiAccountResetPasswordPost(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdatePasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAccountUpdatePasswordPost(body?: UpdatePasswordRequest, options?: any): AxiosPromise<void> {
      return AccountApiFp(configuration)
        .apiAccountUpdatePasswordPost(body, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   *
   * @param {EditAccountRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountEditAccountPost(body?: EditAccountRequest, options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountEditAccountPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {ForgotPasswordRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountForgotPasswordPost(body?: ForgotPasswordRequest, options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountForgotPasswordPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountGetUserGet(options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountGetUserGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {LoginRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountLoginPost(body?: LoginRequest, options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountLoginPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {RegisterRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountRegisterPost(body?: RegisterRequest, options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountRegisterPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {CreateAccountWithSoloContractCommand} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountRegisterWithContractPost(
    body?: CreateAccountWithSoloContractCommand,
    options?: any
  ) {
    return AccountApiFp(this.configuration)
      .apiAccountRegisterWithContractPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {ResetPasswordRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountResetPasswordPost(body?: ResetPasswordRequest, options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountResetPasswordPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
  /**
   *
   * @param {UpdatePasswordRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public apiAccountUpdatePasswordPost(body?: UpdatePasswordRequest, options?: any) {
    return AccountApiFp(this.configuration)
      .apiAccountUpdatePasswordPost(body, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
