import { Backdrop, Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import ContractActions from './ContractActions'
import ContractProfileDetails from './ContractProfileDetails'
import SectionHeader from './SectionHeader'
import GroupProfile from './GroupProfile'
import ContractMetrics from './ContractMetrics'
import TrustIndicators from './TrustIndicators'
import MobileContractMetrics from './MobileContractMetrics'
import { useEffect, useState } from 'react'
import contractViewConfig from './ContractDashboardDimensions'
import { ContractSummary, ContractType } from '../../../data/api/models'
import HiddenReport from './HiddenReport'
import { generatePdf } from './PdfUtils'
import theme from '../../../theme'
import SingleUserDashboard from './SingleUserDashboard'
import HiddenSingleUserReport from './SingleUserDashboard/HiddenSingleUserReport'
import LinearProgressWithLabel from '../../common/utils/LinearProgressWithLabel'

export type ContractViewType = 'dashboard' | 'report'

interface ContractDashboardProps {
  data: ContractSummary | undefined
  unregistered?: boolean
}

const ContractDashboard: React.FC<ContractDashboardProps> = ({ data, unregistered }) => {
  return (
    <>
      {data?.contractDto?.contractType === ContractType.NUMBER_1 ? (
        <>
          <SingleUserDashboard contract={data} unregistered={unregistered} />
          <HiddenSingleUserReport {...data} />
        </>
      ) : (
        <>
          <Dashboard data={data} />
          <HiddenReport
            data={data}
            name={data?.contractDto?.contractDetails?.contractName ?? ''}
            goods={data?.contractDto?.contractDetails?.goodsAndServicesCategory ?? ''}
            location={`${data?.contractDto?.contractDetails?.city ?? 'Melbourne'}, ${
              data?.contractDto?.contractDetails?.country ?? 'Australia'
            }`}
            dynamicValue={data?.contractCalculations?.dynamicTrust ?? 0}
            coreValue={data?.contractCalculations?.coreTrust ?? 0}
            combinedValue={data?.contractCalculations?.trust ?? 0}
            trustBase={data?.contractCalculations?.trustBase ?? ''}
            frictionValue={data?.contractCalculations?.friction ?? 0}
            relationalIndexValue={data?.contractCalculations?.relationalIndex ?? 0}
            harmonyValue={data?.contractCalculations?.relationalHarmony ?? 0}
          />
        </>
      )}
    </>
  )
}

export interface DashboardProps {
  isReportView?: boolean
  data: ContractSummary | undefined
}

export type ReportGenerationStatus = 'None' | 'Requested' | 'Generating'

const Dashboard = ({ data, isReportView = false }: DashboardProps) => {
  const [reportStatus, setReportStatus] = useState<ReportGenerationStatus>('None')
  const [progress, updateProgress] = useState<number>(0)
  useEffect(() => {
    if (reportStatus === 'Requested') {
      setReportStatus('Generating')
      setTimeout(async () => {
        await generatePdf('landscape', (progress: number) => updateProgress(progress))
        setReportStatus('None')
        setTimeout(async () => updateProgress(0), 100)
      }, 0)
    }
  }, [reportStatus])
  const viewConfig = isReportView ? contractViewConfig.report : contractViewConfig.dashboard
  let isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('lg')) && !isReportView

  return (
    <Box
      sx={viewConfig.dimensions}
      height={viewConfig.dimensions === undefined ? 1 : undefined}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
    >
      <Backdrop
        open={reportStatus !== 'None'}
        sx={{
          color: `${theme.palette.primary.main}`,
          zIndex: 9999,
        }}
      >
        <Box
          width={340}
          maxWidth={'80%'}
          bgcolor={theme.palette.common.white}
          px={4}
          py={4}
          borderRadius={1}
        >
          <Typography color={theme.palette.common.black} marginBottom={3}>
            Report generation may take up to 1 minute
          </Typography>
          <LinearProgressWithLabel value={progress} color="primary" />
        </Box>
      </Backdrop>
      {!isReportView && (
        <ContractActions
          isMobile={isMobileView}
          isReportView={isReportView}
          enableDownload={reportStatus === 'None'}
          onDownloadClicked={() => setReportStatus('Requested')}
        />
      )}
      <Grid
        container
        spacing={isMobileView ? 0.5 : 1}
        flexGrow={isReportView ? 0 : 1}
        p={isMobileView ? 1 : 2}
      >
        {!isMobileView ? (
          <>
            <Grid
              item
              xs={viewConfig.buyerOrgGrid.xs}
              md={viewConfig.buyerOrgGrid.md}
              lg={viewConfig.buyerOrgGrid.lg}
              xl={viewConfig.buyerOrgGrid.xl}
            >
              <SectionHeader
                groupName={data?.contractDto.organisationDetails.buyerOrganisationName}
                type={'buyer'}
                pl={'24px'}
              />
            </Grid>
            <Grid
              item
              xs={viewConfig.supplierOrgGrid.xs}
              md={viewConfig.supplierOrgGrid.md}
              lg={viewConfig.supplierOrgGrid.lg}
              xl={viewConfig.supplierOrgGrid.xl}
            >
              <SectionHeader
                groupName={data?.contractDto.organisationDetails.supplierOrganisationName}
                type={'supplier'}
                pl={'24px'}
              />
            </Grid>
            <Grid
              item
              xs={viewConfig.contractDetailGrid.xs}
              md={viewConfig.contractDetailGrid.md}
              lg={viewConfig.contractDetailGrid.lg}
              xl={viewConfig.contractDetailGrid.xl}
            >
              <Box display="flex" justifyContent="space-between" pl={4}>
                <Box>
                  <SectionHeader
                    groupName={data?.contractDto.contractDetails.contractName}
                    subHeader={data?.contractDto.contractDetails.goodsAndServicesCategory}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <TrustIndicators
                    coreTrust={data?.contractCalculations.coreTrust}
                    dynamicTrust={data?.contractCalculations.dynamicTrust}
                    trustBaseLabel={data?.contractCalculations.trustBase}
                    trustLabel={`${data?.contractCalculations.trust}%`}
                  />
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <MobileContractMetrics
              metrics={[
                { label: 'Friction', value: data?.contractCalculations.friction },
                { label: 'Harmony', value: data?.contractCalculations.relationalHarmony },
                { label: 'Relational Index', value: data?.contractCalculations.relationalIndex },
                { label: 'Core Trust', value: data?.contractCalculations.coreTrust },
                { label: 'Dynamic Trust', value: data?.contractCalculations.dynamicTrust },
              ]}
              coreTrust={data?.contractCalculations.coreTrust}
              dynamicTrust={data?.contractCalculations.dynamicTrust}
              trustBaseLabel={data?.contractCalculations.trustBase}
              trustLabel={`${data?.contractCalculations.trust}%`}
              contractName={data?.contractDto.contractDetails.contractName}
              goodsAndServicesCategory={data?.contractDto.contractDetails.goodsAndServicesCategory}
            />
          </Grid>
        )}
        <Grid
          item
          xs={viewConfig.buyerPersonnelGrid.xs}
          md={viewConfig.buyerPersonnelGrid.md}
          lg={viewConfig.buyerPersonnelGrid.lg}
          xl={viewConfig.buyerPersonnelGrid.xl}
        >
          <GroupProfile
            isReportView={isReportView}
            groupProfileType="Buyer"
            personnel={data?.contractDto.personnelList
              .filter((p: any) => p.role === 1)
              .map((p: any) => ({
                name: `${p.firstName} ${p.lastName}`,
                email: p.email,
                role: p.contractRole,
              }))}
            metrics={data?.contractCalculations.contractNorms.map((n: any) => ({
              metric: n.metric,
              percentage: n.buyerValue,
            }))}
            isMobile={isMobileView}
            groupName={data?.contractDto.organisationDetails.buyerOrganisationName}
          />
        </Grid>
        <Grid
          item
          md={viewConfig.supplierPersonnelGrid.md}
          lg={viewConfig.supplierPersonnelGrid.lg}
          xl={viewConfig.supplierPersonnelGrid.xl}
          xs={viewConfig.supplierPersonnelGrid.xs}
        >
          <GroupProfile
            isReportView={isReportView}
            groupProfileType="Supplier"
            personnel={data?.contractDto.personnelList
              .filter((p: any) => p.role === 2)
              .map((p: any) => ({
                name: `${p.firstName} ${p.lastName}`,
                email: p.email,
                role: p.contractRole,
              }))}
            metrics={data?.contractCalculations.contractNorms.map((n: any) => ({
              metric: n.metric,
              percentage: n.supplierValue,
            }))}
            isMobile={isMobileView}
            groupName={data?.contractDto.organisationDetails.supplierOrganisationName}
          />
        </Grid>
        <Grid
          item
          xs={viewConfig.profilesDetailGrid.xs}
          lg={viewConfig.profilesDetailGrid.lg}
          xl={viewConfig.profilesDetailGrid.xl}
        >
          <Box width={1} height={1} display="flex" flexDirection="column">
            <Box flexGrow={1} mb={0.5}>
              <ContractProfileDetails
                isReportView={isReportView}
                metrics={data?.contractCalculations.contractNorms.map((x: any) => ({
                  metric: x.metric,
                  buyer: x.buyerValue,
                  supplier: x.supplierValue,
                  combined: x.combinedValue,
                }))}
              />
            </Box>
            {!isMobileView && (
              <Box>
                <ContractMetrics
                  isReportView={isReportView}
                  metrics={[
                    { label: 'Friction', value: data?.contractCalculations.friction },
                    { label: 'Harmony', value: data?.contractCalculations.relationalHarmony },
                    {
                      label: 'Relational Index',
                      value: data?.contractCalculations.relationalIndex,
                    },
                    { label: 'Core Trust', value: data?.contractCalculations.coreTrust },
                    { label: 'Dynamic Trust', value: data?.contractCalculations.dynamicTrust },
                  ]}
                  reportingPeriod={
                    new Date(data?.contractDto.contractPerformanceDetails.reportingPeriod)
                  }
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export { Dashboard }

export default ContractDashboard
