import { Box, Typography } from '@mui/material'
import LogoWithTitle from '../../common/logos/LogoWithTitle'
import BackgroundImage from '../../../assets/aboutBackground.jpg'
import theme from '../../../theme'
import Copyright from './Copyright'

export type AboutProps = {
  name: string
  goods: string
  location: string
}

const About = (props: AboutProps) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'auto 78%',
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <LogoWithTitle width={250} />
      <Content {...props} />
      <Copyright pageNumber={2} />
    </Box>
  )
}

const Content = ({ name, goods, location }: AboutProps) => {
  return (
    <Box mt={22}>
      <Typography fontSize={40} fontWeight={900}>
        ABOUT THIS REPORT
      </Typography>
      <Typography mt={3} fontSize={23}>
        This report profiles the relationship qualities within the agreement.
      </Typography>
      <Typography mt={5} fontSize={23}>
        <b>Contract name: {name}</b>
      </Typography>
      <Typography fontSize={23}>{goods}</Typography>
      <Typography fontSize={23}>{location}</Typography>
      <Typography mt={5} fontSize={18}>
        The Relational Impact Profile, an infographic derived from the contract, its performance and
        the assessments
        <br />
        undertaken by key personnel with influential roles.
      </Typography>
      <Typography mt={5} fontSize={18}>
        Inspired by the relational contract model, the Relational Impact Profile’s six norms
        describe the quality of the
        <br />
        relationship, allowing you to gauge the quality of trust, and highlight areas of opportunity
        for improvement.
      </Typography>

      <Typography mt={10} fontSize={16}>
        TERM OF USE
      </Typography>
      <Typography color={theme.palette.grey[500]} fontSize={16}>
        All content, concepts, visual assets and intellectual property within this report remains
        the ownership of Contract Harmonics.
        <br />
        The information within this report is provided on and “as is” basis, without warranty.
        Contract Harmonics shall not have any liability to any
        <br />
        person or entity for any damages whatsoever that result from the use of, this report. The
        limitations of this liability applies to all claims
        <br />
        or causes whatsoever.
      </Typography>
    </Box>
  )
}

export default About
