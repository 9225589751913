import { Box, Divider, Grid, Typography } from '@mui/material'
import CircularIndicator from '../../common/outputs/CircularIndicator'
import Copyright from './Copyright'
import Logo from '../../common/logos/Logo'

const MetricsSecondPage = (props: HarmonyProps & RelationalIndexProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          opacity: '45%',
        }}
      >
        <Logo width={250} />
      </Box>
      <Grid container mt={12}>
        <Harmony {...props} />
        <Grid item xs={12} mt={4}>
          <Divider />
        </Grid>
        <RelationalIndex {...props} />
      </Grid>
      <Copyright pageNumber={12} />
    </Box>
  )
}

export type HarmonyProps = {
  harmonyValue: number
}

const Harmony = ({ harmonyValue }: HarmonyProps) => {
  return (
    <>
      <Grid item xs={2}>
        <Box display="flex" justifyContent="left" mb={1}>
          <CircularIndicator
            value={harmonyValue}
            label={harmonyValue + '%'}
            color="secondary.main"
            scale={2.2}
            labelStyle={{
              fontSize: '35px',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Typography fontSize={45} fontWeight={900}>
          HARMONY
        </Typography>
        <Typography fontSize={24} mt={2}>
          The amount of relational harmony between the parties managing the commercial relationship.
          The higher the more aligned values and management approach.
        </Typography>
        <Grid container spacing={5} mt={'0.01px'}>
          <Grid item xs={3}>
            <Level
              heading={'Low (0-49%)'}
              description={
                'Significant misalignment of values within team. Toxic environment. Fix or fail.'
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Level
              heading={'Average (50-75%)'}
              description={
                'Differences normalised. Compromises relationship over time. Invest to improve.'
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Level
              heading={'High (76-89%)'}
              description={
                'Many successful relationships in range. Consider opportunities for improvement.'
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Level
              heading={'Elite (90%+)'}
              description={
                'Relationship values are aligned, with parties to the agreement focused on common goals. Monitor and maintain.'
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export type RelationalIndexProps = {
  relationalIndexValue: number
}

const RelationalIndex = ({ relationalIndexValue }: RelationalIndexProps) => {
  return (
    <>
      <Grid item xs={2} mt={6}>
        <Box display="flex" justifyContent="left" mb={1}>
          <CircularIndicator
            value={relationalIndexValue}
            label={relationalIndexValue + '%'}
            color="secondary.main"
            scale={2.2}
            labelStyle={{
              fontSize: '35px',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={10} mt={6}>
        <Typography fontSize={45} fontWeight={900}>
          RELATIONAL INDEX
        </Typography>
        <Typography fontSize={24} mt={2}>
          This indicates where the commercial relationship lies on the Transactional - Relational
          spectrum.
        </Typography>
        <Grid container spacing={5} mt={'0.01px'}>
          <Grid item xs={3}>
            <Level heading={'Less than 50%'} description={'More Transactional than Relational'} />
          </Grid>
          <Grid item xs={3}>
            <Level
              heading={'Greater than 50%'}
              description={'More Relational than Transactional'}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontSize={20}>
              The more extreme the index value, the more intense are the Transactional or Relational
              qualities present in the commercial relationship.
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

export type LevelProps = {
  heading: string
  description: string
}
const Level = ({ heading, description }: LevelProps) => {
  return (
    <>
      <Typography fontSize={30} fontWeight={900}>
        {heading}
      </Typography>
      <Typography mt={1} fontSize={20}>
        {description}
      </Typography>
    </>
  )
}

export default MetricsSecondPage
