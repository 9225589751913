import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import { PropsWithChildren } from 'react'

interface ButtonProps
  extends Pick<
    MuiButtonProps,
    'disabled' | 'type' | 'onClick' | 'fullWidth' | 'variant' | 'startIcon' | 'sx'
  > {}

const Button: React.FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  children,
  fullWidth = true,
  disabled,
  variant = 'contained',
  sx = undefined,
  ...rest
}) => (
  <MuiButton
    {...rest}
    fullWidth={fullWidth}
    color="primary"
    variant={variant}
    disabled={disabled}
    disableElevation
    sx={sx ?? { borderRadius: 25, paddingX: 8, textTransform: 'none' }}
  >
    {children}
  </MuiButton>
)

export default Button
