type ViewConfig = {
  dimensions?: {
    width: number
    height: number
  }
  buyerOrgGrid: SectionConfig
  supplierOrgGrid: SectionConfig
  contractDetailGrid: SectionConfig
  buyerPersonnelGrid: SectionConfig
  supplierPersonnelGrid: SectionConfig
  profilesDetailGrid: SectionConfig
}

type SectionConfig = {
  xs: number
  md: number
  lg: number
  xl: number
  headerMarginX: number
}

export type ContractViewConfig = {
  dashboard: ViewConfig
  report: ViewConfig
}

const contractViewConfig: ContractViewConfig = {
  dashboard: {
    buyerOrgGrid: {
      xs: 6,
      md: 6,
      lg: 4,
      xl: 3,
      headerMarginX: 1,
    },
    supplierOrgGrid: {
      xs: 6,
      md: 6,
      lg: 4,
      xl: 3,
      headerMarginX: 1,
    },
    contractDetailGrid: {
      xs: 12,
      md: 12,
      lg: 4,
      xl: 6,
      headerMarginX: 0,
    },
    buyerPersonnelGrid: {
      xs: 12,
      md: 6,
      lg: 4,
      xl: 3,
      headerMarginX: 0,
    },
    supplierPersonnelGrid: {
      xs: 12,
      md: 6,
      lg: 4,
      xl: 3,
      headerMarginX: 0,
    },
    profilesDetailGrid: {
      xs: 12,
      md: 12,
      lg: 4,
      xl: 6,
      headerMarginX: 0,
    },
  },
  report: {
    dimensions: {
      width: 1635,
      height: 1150,
    },
    buyerOrgGrid: {
      xs: 3,
      md: 3,
      lg: 3,
      xl: 3,
      headerMarginX: 1,
    },
    supplierOrgGrid: {
      xs: 3,
      md: 3,
      lg: 3,
      xl: 3,
      headerMarginX: 1,
    },
    contractDetailGrid: {
      xs: 6,
      md: 6,
      lg: 6,
      xl: 6,
      headerMarginX: 0,
    },
    buyerPersonnelGrid: {
      xs: 3,
      md: 3,
      lg: 3,
      xl: 3,
      headerMarginX: 0,
    },
    supplierPersonnelGrid: {
      xs: 3,
      md: 3,
      lg: 3,
      xl: 3,
      headerMarginX: 0,
    },
    profilesDetailGrid: {
      xs: 6,
      md: 6,
      lg: 6,
      xl: 6,
      headerMarginX: 0,
    },
  },
}

export default contractViewConfig
