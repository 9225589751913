import React from 'react'
import { ContractRole } from '../data/models'
import { Box } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import LargeButton from '../../../common/inputs/LargeButton'
import HoverMessage from '../../../common/text/HoverMessage'
import FormHeader from '../FormHeader'
import { ReactComponent as BuyerIcon } from '../../../../assets/Icon-Buyer.svg'
import { ReactComponent as SupplierIcon } from '../../../../assets/Icon-Supplier.svg'
import Button from '../../../common/inputs/Button'

interface PrimaryRoleFormProps {
  role: ContractRole | undefined
  onSelectRole: (role: ContractRole) => void
}

const PrimaryRoleForm: React.FunctionComponent<PrimaryRoleFormProps> = ({ onSelectRole, role }) => {
  const [selectedRole, setSelectedRole] = React.useState<ContractRole | undefined>(role)

  const isSelected = (buttonRole: ContractRole) => buttonRole === selectedRole

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12}>
        <FormHeader
          title="Contract Details"
          mobileTitle="Add Contract"
          mobileSubTitle="Primary contact role"
          details="Select your role in this commercial agreement"
        />
      </Grid2>
      <Grid2 md={6} xs={12}>
        <HoverMessage message="Organisation or entity identified in a contract as the party to provide commodities or contractual services pursuant to a contract to the Buyer.">
          <LargeButton
            color={isSelected(ContractRole.Supplier) ? 'primary' : 'white'}
            onClick={() => setSelectedRole(ContractRole.Supplier)}
            label="Supplier"
            fullWidth
            icon={<SupplierIcon />}
          />
        </HoverMessage>
      </Grid2>
      <Grid2 md={6} xs={12}>
        <HoverMessage message="Organisation or entity identified in a contract as the party to receive commodities or contractual services pursuant to a contract from the Supplier.">
          <LargeButton
            color={isSelected(ContractRole.Buyer) ? 'primary' : 'white'}
            onClick={() => setSelectedRole(ContractRole.Buyer)}
            label="Buyer"
            fullWidth
            icon={<BuyerIcon />}
          />
        </HoverMessage>
      </Grid2>
      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            disabled={!selectedRole}
            onClick={() => (selectedRole ? onSelectRole(selectedRole) : undefined)}
            fullWidth={false}
            sx={{
              borderRadius: 25,
              paddingX: 8,
              textTransform: 'none',
              '&:disabled': {
                backgroundColor: '#8DA09A',
                color: 'white',
              },
            }}
          >
            Add Contract Details
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default PrimaryRoleForm
