import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import ContractDashboard from '../components/contract/ContractDashboard'
import { ReactComponent as BackgroundGraphics } from '../assets/ReportDashboard-Graphics.svg'
import theme from '../theme'
import { useContext, useEffect } from 'react'
import { contractApi } from '../data/myApis'
import useApi from '../hooks/useApi'
import LoadingContainer from '../components/common/containers/LoadingContainer'
import { AnonymousSoloAssessmentContext } from '../contexts/AnonymousSoloAssessmentContext'
import { useNavigate } from 'react-router-dom'

const AnonymousContractDashboardRoute: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { answers, contractDetails, correlationId } = useContext(AnonymousSoloAssessmentContext)
  const { callApi, data, state } = useApi(contractApi.apiContractGetSoloContractSummaryPost)

  useEffect(() => {
    if (!contractDetails) {
      navigate('/anonymous/contract/2/add')
      return
    }

    callApi({ contractDetails, assessmentAnswers: answers, correlationId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FullscreenContainer
      bgColor={theme.palette.background.gradient}
      appBarBgColor={theme.palette.common.white}
      showHeader
      bgGraphics={<BackgroundGraphics />}
    >
      <LoadingContainer
        isLoading={state === 'loading' || state === 'idle'}
        isErrored={state === 'errored' || !data?.contractCalculations || !data.contractDto}
      >
        <ContractDashboard data={data} unregistered />
      </LoadingContainer>
    </FullscreenContainer>
  )
}

export default AnonymousContractDashboardRoute
