import { Box, Typography } from '@mui/material'

type TextAvatarProps = {
  text: string
  type: 'buyer' | 'supplier'
}

const TextAvatar = ({ text, type }: TextAvatarProps) => {
  return (
    <Box
      display={'flex'}
      width={'60px'}
      height={'60px'}
      padding={'16px'}
      justifyContent={'center'}
      alignItems={'center'}
      flexShrink={0}
      mb={1}
      style={{
        backgroundColor: `${type === 'buyer' ? '#3781AD' : '#D94F4A'}`,
        borderRadius: '8px',
        color: 'white',
        userSelect: 'none',
      }}
    >
      <Typography
        fontSize={'21px'}
        fontStyle={'normal'}
        fontWeight={400}
        lineHeight={'26.25px'}
        letterSpacing={'0.1px'}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default TextAvatar
