import { Box, Typography, useTheme } from '@mui/material'
import MetricIcons, { MetricIconsProps } from '../MetricIcons'

interface RelationalProfileProps {
  metricIconsProps: MetricIconsProps
}

const RelationalProfile: React.FC<RelationalProfileProps> = ({ metricIconsProps }) => {
  const theme = useTheme()

  return (
    <Box bgcolor={theme.palette.common.white} borderRadius={2} px={2} py={2}>
      <Typography mb={1} variant="h2">
        <b>Relational Profile</b>
      </Typography>
      <MetricIcons {...metricIconsProps} />
    </Box>
  )
}

export default RelationalProfile
